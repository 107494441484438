<a routerLink="/" class="logo">
    <figure class="logo__figure">
        <img class="logo__icon" src="/assets/image/icon.png" />
        <figcaption class="logo__text">MisterGreen.io</figcaption>
    </figure>
</a>

<mat-toolbar [attr.data-testid]="'toolbar'">
    <a mat-button routerLink="/user">
        <mat-icon> supervisor_account</mat-icon>
        Users
    </a>
    <button mat-button [matMenuTriggerFor]="operations">
        <mat-icon> arrow_drop_down</mat-icon>
        Operations
    </button>
    <button mat-button [matMenuTriggerFor]="deals">
        <mat-icon> arrow_drop_down</mat-icon>
        Deals
    </button>
    <button mat-button [matMenuTriggerFor]="templates">
        <mat-icon> arrow_drop_down</mat-icon>
        Templates
    </button>
    <button mat-button [matMenuTriggerFor]="settings">
        <mat-icon> arrow_drop_down</mat-icon>
        Settings
    </button>

    <span class="spacer"></span>

    <a
        mat-button
        class="icon"
        matTooltip="Idea's / bug report"
        href="https://form.asana.com?hash=74234dc00a2cb2b11f6e170f0aa06b38e8aabf834b1947c981654097b6c5cfde&id=1112489210496257"
        target="_blank"
    >
        <mat-icon> lightbulb_outline</mat-icon>
    </a>

    <a mat-button class="icon" matTooltip="Admin settings" routerLink="/settings">
        <mat-icon> settings</mat-icon>
    </a>

    <mat-select [formControl]="tenantControl" class="tenants icon" [attr.data-testid]="'tenant_control'">
        <mat-select-trigger>{{ tenantControl.value.toString() | slice: -2 | uppercase }}</mat-select-trigger>
        <mat-option *ngFor="let tenant of tenants$ | async" [attr.data-testid-tenantid]="tenant.id" [value]="tenant.id">
            {{ tenant.name }}
        </mat-option>
    </mat-select>

    <button mat-button [matMenuTriggerFor]="profile" *ngIf="user$ | async as user" class="profile icon">
        <img
            ngxGravatar
            [email]="user.emailAddress"
            [round]="true"
            [size]="32"
            [borderColor]="'inherit'"
            [borderWidth]="1"
        />
    </button>
</mat-toolbar>

<mat-menu #operations="matMenu">
    <a mat-menu-item routerLink="/car">
        <mat-icon> commute </mat-icon>
        Cars
    </a>
    <a mat-menu-item routerLink="/contracts">
        <mat-icon> receipt</mat-icon>
        Contracts
    </a>
</mat-menu>

<mat-menu #deals="matMenu">
    <a mat-menu-item routerLink="/offer">
        <mat-icon> shopping_basket</mat-icon>
        Offers
    </a>
    <a mat-menu-item routerLink="/accepted-offers">
        <mat-icon> shopping_cart</mat-icon>
        Accepted offers
    </a>
</mat-menu>

<mat-menu #templates="matMenu">
    <a mat-menu-item routerLink="/email-template">
        <mat-icon>email</mat-icon>
        E-mail templates
    </a>
    <a mat-menu-item routerLink="/legal">
        <mat-icon>work</mat-icon>
        Legal documents
    </a>
</mat-menu>

<mat-menu #settings="matMenu">
    <a mat-menu-item routerLink="/configuration">
        <mat-icon>local_car_wash</mat-icon>
        Configuration
    </a>
    <a mat-menu-item routerLink="/pricing">
        <mat-icon>attach_money</mat-icon>
        Pricing
    </a>
    <a mat-menu-item routerLink="/workflow">
        <mat-icon>double_arrow</mat-icon>
        Workflows
    </a>
</mat-menu>

<mat-menu #profile="matMenu">
    <ng-container *ngIf="user$ | async as user">
        <figure class="profile">
            <img
                class="profile__avatar"
                ngxGravatar
                [email]="user.emailAddress"
                [round]="true"
                [size]="92"
                [borderColor]="'inherit'"
                [borderWidth]="6"
            />
            <figcaption class="profile__text">
                <h6 class="profile__username">
                    {{ user?.profile?.firstName }} {{ user?.profile?.lastNamePrefix }} {{ user?.profile?.lastName }}
                </h6>
                <small class="profile__emailAddress">{{ user.emailAddress }}</small>
            </figcaption>
        </figure>

        <a mat-menu-item [routerLink]="'/user/' + user.id">
            <mat-icon>account_circle</mat-icon>
            <span>My Profile</span>
        </a>

        <a mat-menu-item href="https://en.gravatar.com/support/activating-your-account/" target="_blank">
            <mat-icon>photo_camera</mat-icon>
            <span>Change avatar image</span>
        </a>

        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onLogout($event)" class="navigation__item">
            <mat-icon mat-list-icon>exit_to_app</mat-icon>
            Logout
        </button>
    </ng-container>
</mat-menu>

<button class="menuToggle" mat-icon-button (click)="menuOpen = !menuOpen"><mat-icon> menu </mat-icon></button>

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MessageComponent } from './components/message/message.component'
import { MatIconModule } from '@angular/material/icon'
import { WarningComponent } from './components/warning/warning.component'
import { ErrorComponent } from './components/error/error.component'
import { SuccessComponent } from './components/success/success.component'
import { InfoComponent } from './components/info/info.component'

@NgModule({
    declarations: [MessageComponent, InfoComponent, WarningComponent, ErrorComponent, SuccessComponent],
    imports: [CommonModule, MatIconModule],
    exports: [MessageComponent, InfoComponent, WarningComponent, ErrorComponent, SuccessComponent],
})
export class MessageModule {}

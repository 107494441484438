import { Input, Directive } from '@angular/core'
import { FormFactoryField } from '../../interfaces/form'
import { BehaviorSubject } from 'rxjs'

@Directive()
export class FormFieldFactoryComponentAbstract {
    formFactoryField$: BehaviorSubject<FormFactoryField> = new BehaviorSubject(undefined)

    @Input()
    get formFactoryField(): FormFactoryField {
        return this.formFactoryField$.getValue()
    }

    set formFactoryField(value: FormFactoryField) {
        this.formFactoryField$.next(value)
    }
}

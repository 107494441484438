import * as Rollbar from 'rollbar'
import { environment } from 'environments/environment'
import { Inject, Injectable, InjectionToken, ErrorHandler } from '@angular/core'

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

    handleError(err: any): void {
        // Set access token if it was undefined at the initialization of Rollbar
        if (!this.rollbar.options.accessToken) {
            this.rollbar.configure({
                ...this.rollbar.options,
                accessToken: environment?.rollbarAccessToken,
            })
        }

        console.error(err.originalError || err)
        this.rollbar.error(err.originalError || err)
    }
}

export function rollbarFactory() {
    return new Rollbar({
        accessToken: environment?.rollbarAccessToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        captureIp: 'anonymize' as 'anonymize',
        hostSafeList: ['mistergreen.io'],
        payload: {
            environment: 'production',
        },
    })
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar')

import { Environment } from './environment-model'
import { envVars } from './environment.env-vars'
import { useProduction } from './useProduction'

export const environment: Environment = {
    production: useProduction,
    api: {
        url: 'https://api.mistergreen.dev',
        path: {
            graphql: '/graphql',
        },
    },
    webapp: {
        url: 'https://nl.mistergreen.dev',
    },
    ...envVars,
}

import { NgModule } from '@angular/core'
import { GraphqlModule } from './graphql/graphql.module'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { FormFactoryModule } from '../ui/form-factory/form-factory.module'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { AuthenticationModule } from './authentication/authentication.module'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { LayoutModule } from './layout/containers/layout/layout.module'

@NgModule({
    imports: [
        GraphqlModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormFactoryModule,
        MatMomentDateModule,
        AuthenticationModule,
    ],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'nl-NL' }],
    exports: [LayoutModule],
})
export class FrameworkModule {}

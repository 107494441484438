import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldDatepickerComponent } from './form-factory-field-datepicker.component'
import { FORM_FACTORY_FIELD } from '../../core/component/form-field-factory/form-field-factory.tokens'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'

@NgModule({
    declarations: [FormFactoryFieldDatepickerComponent],
    imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatDatepickerModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD,
            useValue: {
                name: 'datepicker',
                component: FormFactoryFieldDatepickerComponent,
            },
            multi: true,
        },
    ],
})
export class FormFactoryFieldDatepickerModule {}

import { Injectable } from '@angular/core'
import { LocalStorageService } from '@mistergreen/shared/angular-injectables/local-storage/local-storage.service'
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, pluck, skip } from 'rxjs/operators'
import { GetTenantsGQL, Tenant } from '../../../../generated/graphql'

const tenantLocalStorageKey = 'mg_tenant'

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    tenant$: BehaviorSubject<string>

    constructor(private localStorageService: LocalStorageService, private getTenantsGQL: GetTenantsGQL) {
        this.tenant$ = new BehaviorSubject<string>(
            this.localStorageService.getItem(tenantLocalStorageKey) || 'mistergreen_nl'
        )

        this.tenant$
            .pipe(filter(Boolean), distinctUntilChanged(), skip(1), debounceTime(100))
            .subscribe(() => (window.location = window.location))

        // update tenant of local screen when local storage changes
        this.localStorageService
            .getItemObservable(tenantLocalStorageKey)
            .pipe(
                filter<string>(Boolean),
                filter(tentant => tentant !== this.tenant)
            )
            .subscribe(tenant => (this.tenant = tenant))
    }

    get tenant() {
        return this.tenant$.getValue()
    }

    set tenant(tenant) {
        this.tenant$.next(tenant)
        this.localStorageService.setItem(tenantLocalStorageKey, tenant)
    }

    getTenants(): Observable<Tenant[]> {
        return this.getTenantsGQL.fetch().pipe(pluck('data', 'getTenants'))
    }
}

<mat-form-field *ngIf="formFactoryField$ | async as formFactoryFieldRef">
    <mat-label *ngIf="formFactoryFieldRef.label">{{ formFactoryFieldRef.label }}</mat-label>
    <input matInput [formControl]="formFactoryFieldRef.formControl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option
            *ngFor="let option of formFactoryFieldRef.data || []"
            [value]="option.hasOwnProperty('value') ? option.value : option"
        >
            {{ option?.label || (option.hasOwnProperty('value') ? option.value : option) }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

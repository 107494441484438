import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private itemObservables: Map<string, BehaviorSubject<string | null>> = new Map();

  private storage: Storage;

  constructor() {
    this.storage = <Storage>(<any>window).localStorage;
    (<any>window).addEventListener('storage', this.onStorageEvent.bind(this));
  }

  clear() {
    this.itemObservables.forEach((value, key) => {
      this.removeItem(key);
    });
    this.storage.clear();
  }

  /**
   * push global events to local BehaviourSubject
   * @param event
   */
  onStorageEvent(event) {
    const { key, newValue } = event;

    if (this.itemObservables.has(key)) {
      this.itemObservables.get(key).next(newValue);
    }
  }

  /**
   *
   * @param {string} key
   * @param {string} value
   */
  setItem(key: string, value: string | null) {
    if (value === null) {
      this.storage.removeItem(key);
    } else {
      this.storage.setItem(key, value);
    }

    // notify observable of change
    if (this.itemObservables.has(key)) {
      this.itemObservables.get(key).next(value);
    }
  }

  /**
   *
   * @param {string} key
   */
  removeItem(key: string) {
    this.storage.removeItem(key);

    // notify observable of change
    if (this.itemObservables.has(key)) {
      this.itemObservables.get(key).next(null);
    }
  }

  /**
   *
   * @param {string} key
   * @returns {string}
   */
  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  /**
   *
   * @param {string} key
   * @returns {Observable<string>}
   */
  getItemObservable(key: string): Observable<string | null> {
    if (!this.itemObservables.has(key)) {
      const itemObservable = new BehaviorSubject(this.getItem(key));
      this.itemObservables.set(key, itemObservable);
    }

    return this.itemObservables.get(key);
  }
}

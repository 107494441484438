import { Component } from '@angular/core'
import { MessageComponent } from '../message/message.component'

@Component({
    selector: 'mga-error',
    templateUrl: '../message/message.component.html',
    styleUrls: ['../message/message.component.scss', './error.component.scss'],
})
export class ErrorComponent extends MessageComponent {
    icon = 'error'
}

import { Directive } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const dummyFn = _ => {};

export abstract class AbstractValueAccessor implements ControlValueAccessor {
  onChange: Function = dummyFn;
  onTouched: Function = dummyFn;

  // tslint:disable-next-line:variable-name
  __value: any;

  get _value() {
    return this.__value;
  }

  set _value(value) {
    this.__value = value;
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}

@UntilDestroy()
@Directive()
export class FormControlValueAccessor extends AbstractValueAccessor implements ControlValueAccessor {
  componentFormControl: FormControl;

  constructor() {
    super();
    this.componentFormControl = new FormControl(this.__value);

    merge(this.componentFormControl.valueChanges, this.componentFormControl.statusChanges)
      .pipe(untilDestroyed(this), debounceTime(10))
      .subscribe(() => {
        this.onChange(this._value);
      });
  }

  // tslint:disable-next-line:variable-name
  __value: any;

  get _value() {
    return this.componentFormControl ? this.componentFormControl.value : this.__value;
  }

  set _value(value) {
    this.__value = value;
    if (this.componentFormControl) {
      this.componentFormControl.setValue(value, { emitEvent: false });
    }
  }

  writeValue(value: any) {
    this.componentFormControl.setValue(value, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean) {
    if (this.componentFormControl) {
      this.componentFormControl[isDisabled ? 'disable' : 'enable']();
    }
  }
}

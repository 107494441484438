import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { gql } from '@apollo/client/core'
import { LocalStorageService } from '@mistergreen/shared/angular-injectables/local-storage/local-storage.service'
import { BehaviorSubject } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { SplashScreenService } from '../../splash-screen/splash-screen.service'
import { Role } from 'generated/graphql'

const currentUserQuery = gql`
    query {
        getCurrentUser {
            id
            emailAddress
            profile {
                firstName
                lastNamePrefix
                lastName
            }
            roles
        }
    }
`

export type User = { id: string; emailAddress: string; profile: UserProfile; roles: Role[] }
export type UserProfile = { firstName: string; lastNamePrefix: string; lastName: string }

@Injectable({
    providedIn: 'root',
})
export class AuthenticatedUserService {
    currentUser: BehaviorSubject<null | User> = new BehaviorSubject(null)

    private token: string

    constructor(
        private apollo: Apollo,
        private localStorageService: LocalStorageService,
        private splashScreenService: SplashScreenService
    ) {}

    get isLoggedIn(): boolean {
        return this.currentUser.getValue() !== null
    }

    async init() {
        // get logedInUser information when login token is known/changes
        const token = this.localStorageService.getItem('token')

        if (token) {
            await this.setToken(token)
        }

        this.localStorageService.getItemObservable('token').subscribe(this.setToken.bind(this))
    }

    async setToken(token) {
        if (this.token !== token) {
            this.token = token

            let currentUser: null | User = null

            if (token !== null) {
                try {
                    // remove cached entries
                    await this.apollo.getClient().resetStore()

                    // get current User
                    const {
                        data: { getCurrentUser: user },
                    }: any = await this.apollo.query({ query: currentUserQuery, variables: {} }).toPromise()

                    currentUser = <User>user
                } catch (e) {
                    console.log(e)
                }
            }

            this.currentUser.next(currentUser || null)
        }
    }

    logout(logoutHandler: Function = this.authenticate) {
        this.splashScreenService.show()
        this.localStorageService.clear()
        alert('you have been logged out')
        logoutHandler()
    }

    authenticate() {
        window.location.href = `${environment.api.url}/v1/auth/google-admin/login`
    }
}

<mat-form-field *ngIf="formFactoryField$ | async as formFactoryFieldRef">
    <mat-label *ngIf="formFactoryFieldRef.label">{{ formFactoryFieldRef.label }}</mat-label>
    <mat-select
        matInput
        [attr.data-testid]="formFactoryFieldRef.testid"
        [formControl]="formFactoryFieldRef.formControl"
    >
        <mat-option
            *ngFor="let option of formFactoryFieldRef.data || []"
            [value]="option.hasOwnProperty('value') ? option.value : option"
        >
            {{ option?.label || (option.hasOwnProperty('value') ? option.value : option) }}
        </mat-option>
    </mat-select>
</mat-form-field>

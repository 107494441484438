import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { filter, mergeMap, pluck } from 'rxjs/operators'
import { UserAvatarGQL, UserAvatarQuery } from '../../../../../generated/graphql'
import { ApolloQueryResult } from '@apollo/client'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    AuthenticatedUserService,
    User,
} from '../../../../framework/authentication/services/authenticated-user.service'

@UntilDestroy()
@Component({
    selector: 'mga-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
    private userID$: BehaviorSubject<string> = new BehaviorSubject(null)
    emailAddress$: BehaviorSubject<string> = new BehaviorSubject<string>(null)

    @Input()
    size: number = 32

    @Input()
    useBlankFallback: boolean = true

    authenticatedUserEmailAddress: string

    constructor(private userAvatarGQL: UserAvatarGQL, private authenticatedUserService: AuthenticatedUserService) {}

    get userID() {
        return this.userID$.getValue()
    }

    @Input()
    set emailAddress(value: string) {
        this.emailAddress$.next(value)
    }

    get emailAddress() {
        return this.emailAddress$.getValue()
    }

    @Input()
    set userID(value: string) {
        this.userID$.next(value)
    }

    ngOnInit(): void {
        this.authenticatedUserService.currentUser
            .pipe(untilDestroyed(this), filter<User>(Boolean), pluck<User, string>('emailAddress'))
            .subscribe(emailAddress => (this.authenticatedUserEmailAddress = emailAddress))

        this.userID$
            .pipe(
                untilDestroyed(this),
                filter<string>(Boolean),
                mergeMap(userID => {
                    return this.userAvatarGQL
                        .fetch({ id: userID })
                        .pipe(pluck<ApolloQueryResult<UserAvatarQuery>, string>('data', 'User', 'emailAddress'))
                })
            )
            .subscribe(emailAddress => this.emailAddress$.next(emailAddress))
    }
}

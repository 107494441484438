import { Component, HostBinding, Input, OnInit } from '@angular/core'

@Component({
    selector: 'mga-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
    @HostBinding('class.hasIcon')
    @Input()
    icon: string

    @HostBinding('class.isMessage')
    readonly isMessage: boolean = true
}

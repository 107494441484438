import { Component } from '@angular/core'
import { MessageComponent } from '../message/message.component'

@Component({
    selector: 'mga-info',
    templateUrl: '../message/message.component.html',
    styleUrls: ['../message/message.component.scss', './info.component.scss'],
})
export class InfoComponent extends MessageComponent {
    icon = 'info'
}

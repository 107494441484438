<mat-form-field *ngIf="formFactoryField$ | async as formFactoryFieldRef">
    <input
        matInput
        [matDatepicker]="picker"
        [placeholder]="formFactoryFieldRef.label"
        [formControl]="formFactoryFieldRef.formControl"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

import { gql } from 'apollo-angular'
import { Injectable } from '@angular/core'
import * as Apollo from 'apollo-angular'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    Base64: any
    Date: any
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: any
    /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
    EmailAddress: any
    /** A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number. */
    IBAN: any
    JSON: any
}

export type Accessory = {
    __typename?: 'Accessory'
    id: Scalars['ID']
    name: Scalars['String']
    priceIncVAT: Scalars['Float']
    priceExVAT: Scalars['Float']
}

export type AccessoryProposition = {
    __typename?: 'AccessoryProposition'
    id: Scalars['ID']
    investment: Scalars['Float']
    oldPricePerMonthExVAT: Scalars['Float']
    newPricePerMonthExVAT: Scalars['Float']
    priceIncreaseExVAT: Scalars['Float']
    oldPricePerMonthIncVAT: Scalars['Float']
    newPricePerMonthIncVAT: Scalars['Float']
    priceIncreaseIncVAT: Scalars['Float']
}

export type Address = {
    __typename?: 'Address'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    street?: Maybe<Scalars['String']>
    houseNumber?: Maybe<Scalars['Int']>
    houseNumberSuffix?: Maybe<Scalars['String']>
    zipcode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
}

export type AddressInput = {
    street?: Maybe<Scalars['String']>
    houseNumber?: Maybe<Scalars['String']>
    houseNumberSuffix?: Maybe<Scalars['String']>
    zipcode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
}

export type AfterDeliveryEditionOption = EditionOptionInterface & {
    __typename?: 'AfterDeliveryEditionOption'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    labelId?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
    option?: Maybe<ConfigurationOption>
    edition?: Maybe<ConfigurationEdition>
    price?: Maybe<Scalars['Float']>
}

export type AllUserProfilesResult = PaginatedResult & {
    __typename?: 'AllUserProfilesResult'
    userProfiles?: Maybe<Array<Maybe<UserProfile>>>
    totalResults?: Maybe<Scalars['Int']>
}

export type AutodiskDeleteResult = {
    __typename?: 'AutodiskDeleteResult'
    success?: Maybe<Scalars['Boolean']>
}

export type AutodiskEdition = {
    __typename?: 'AutodiskEdition'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    autoId?: Maybe<Scalars['Int']>
    configurationEditionIds?: Maybe<Array<Maybe<Scalars['String']>>>
    defaultOptionIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    model?: Maybe<AutodiskModel>
}

export type AutodiskEditionInput = {
    autoId: Scalars['Int']
    modelId: Scalars['ID']
    configurationEditionIds?: Maybe<Array<Maybe<Scalars['String']>>>
    defaultOptionIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    entity: LessorEntity
}

export type AutodiskModel = {
    __typename?: 'AutodiskModel'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name?: Maybe<Scalars['String']>
    mainProfileId?: Maybe<Scalars['Int']>
    mantelNr?: Maybe<Scalars['Int']>
    configurationModelIds?: Maybe<Array<Maybe<Scalars['String']>>>
    editions?: Maybe<Array<Maybe<AutodiskEdition>>>
}

export type AutodiskModelInput = {
    name: Scalars['String']
    mainProfileId: Scalars['Int']
    mantelNr?: Maybe<Scalars['Int']>
    configurationModelIds?: Maybe<Array<Maybe<Scalars['String']>>>
    entity: LessorEntity
}

export type Bank = {
    __typename?: 'Bank'
    id: Scalars['ID']
    name: Scalars['String']
}

export type BillingAccount = {
    __typename?: 'BillingAccount'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    accountHolder?: Maybe<Scalars['String']>
    iban?: Maybe<Scalars['IBAN']>
    emailAddress?: Maybe<Scalars['EmailAddress']>
}

export type BooleanDealOption = {
    __typename?: 'BooleanDealOption'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    key: Scalars['String']
    labelId: Scalars['String']
    value: Scalars['Boolean']
}

export type BranchAddress = {
    __typename?: 'BranchAddress'
    id: Scalars['ID']
    city?: Maybe<Scalars['String']>
    street?: Maybe<Scalars['String']>
    houseNr?: Maybe<Scalars['String']>
    houseNrSuffix?: Maybe<Scalars['String']>
    zipCode?: Maybe<Scalars['String']>
    country?: Maybe<Scalars['String']>
}

export type Bucket = {
    __typename?: 'Bucket'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    type: BucketType
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    content: Array<Document>
}

export enum BucketType {
    SignedDealContracts = 'SIGNED_DEAL_CONTRACTS',
}

export type Car = {
    __typename?: 'Car'
    id: Scalars['ID']
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    VIN?: Maybe<Scalars['String']>
    entity?: Maybe<Lessor>
    licensePlate?: Maybe<Scalars['String']>
    firstRegistrationDate?: Maybe<Scalars['Date']>
    currentMileage: Scalars['Float']
    currentMileageDate?: Maybe<Scalars['Date']>
    orderedDate?: Maybe<Scalars['Date']>
    fiscalValueIncVAT?: Maybe<Scalars['Float']>
    brandName: Scalars['String']
    modelName: Scalars['String']
    editionName: Scalars['String']
    bookValueExVAT?: Maybe<Scalars['Float']>
    marketValueExVAT?: Maybe<Scalars['Float']>
    reportingCode?: Maybe<Scalars['String']>
    currentTire?: Maybe<Tire>
    tireSizeFront?: Maybe<Scalars['String']>
    tireSizeRear?: Maybe<Scalars['String']>
    lastTireChangeDate?: Maybe<Scalars['Date']>
    lastTireChangeBy?: Maybe<Scalars['String']>
    lastTireChangePlace?: Maybe<Scalars['String']>
    nextTireChangeDate?: Maybe<Scalars['Date']>
    ministryOfTransportDate?: Maybe<Scalars['Date']>
    yearOfManufacture?: Maybe<Scalars['Int']>
    options: Array<TeslaOption>
    images?: Maybe<CarImages>
    contracts?: Maybe<Array<Maybe<Contract>>>
    optionsOverwriteAllowedByImporter: Scalars['Boolean']
}

export type CarCollection = PaginatedResult & {
    __typename?: 'CarCollection'
    cars: Array<Car>
    totalResults: Scalars['Int']
}

export type CarFilter = {
    AND?: Maybe<Array<Maybe<CarFilter>>>
    OR?: Maybe<Array<Maybe<CarFilter>>>
    VIN?: Maybe<Scalars['String']>
    licensePlate?: Maybe<Scalars['String']>
    entity?: Maybe<LessorEntity>
    publishedIn?: Maybe<ShopLocale>
}

export type CarHasContractAttachedError = ExpectedError & {
    __typename?: 'CarHasContractAttachedError'
    errorCode: ErrorCode
    carId: Scalars['String']
    contractId: Scalars['String']
}

export type CarImages = {
    __typename?: 'CarImages'
    mainImageUrl?: Maybe<Scalars['String']>
    interiorImageUrl?: Maybe<Scalars['String']>
    exteriorImageUrl?: Maybe<Scalars['String']>
    urlsAdditionalImages: Array<Scalars['String']>
}

export type CarInput = {
    VIN?: Maybe<Scalars['String']>
    entity?: Maybe<LessorEntity>
    licensePlate?: Maybe<Scalars['String']>
    firstRegistrationDate?: Maybe<Scalars['Date']>
    currentMileage?: Maybe<Scalars['Float']>
    currentMileageDate?: Maybe<Scalars['Date']>
    fiscalValueIncVAT?: Maybe<Scalars['Float']>
    brandName?: Maybe<Scalars['String']>
    modelName?: Maybe<Scalars['String']>
    editionName?: Maybe<Scalars['String']>
    bookValueExVAT?: Maybe<Scalars['Float']>
    marketValueExVAT?: Maybe<Scalars['Float']>
    optionCodes?: Maybe<Array<Scalars['String']>>
}

export type CarResult = Car | EntityNotFoundError

export type CarShopChannel = RemarketingChannel & {
    __typename?: 'CarShopChannel'
    id: Scalars['ID']
    locale: ShopLocale
}

export type CarShopPublication = RemarketingPublication & {
    __typename?: 'CarShopPublication'
    id: Scalars['ID']
    createdAt: Scalars['Date']
    updatedAt: Scalars['Date']
    carId: Scalars['String']
    channel: RemarketingChannel
    isContractTakeover: Scalars['Boolean']
    pricingConfigurationId?: Maybe<Scalars['String']>
    shopCarId: Scalars['String']
    licensePlate?: Maybe<Scalars['String']>
    availability?: Maybe<ShopCarAvailability>
    availabilityDate?: Maybe<Scalars['Date']>
    locale: ShopLocale
    bannerLabel?: Maybe<Scalars['String']>
    offerTypes: Array<ShopOfferType>
}

export type CarShopPublicationInput = {
    locale: ShopLocale
    offers: Array<ShopOfferType>
    availabilityDate: Scalars['Date']
    availability: ShopCarAvailability
    bannerLabel?: Maybe<Scalars['String']>
    pricingConfigurationId?: Maybe<Scalars['String']>
    isContractTakeover?: Maybe<Scalars['Boolean']>
}

export type CarShopPublicationResult = CarShopPublication | EntityNotFoundError | RimsOptionNeededError

export type CarSuccessfullyRemoved = {
    __typename?: 'CarSuccessfullyRemoved'
    carId: Scalars['String']
}

export type Check = {
    __typename?: 'Check'
    id: Scalars['String']
    status: Scalars['String']
    result: Scalars['String']
    reports?: Maybe<Array<Maybe<IdReportStatus>>>
    checkPartsNotClear?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Company = {
    __typename?: 'Company'
    id: Scalars['ID']
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    mainBranch?: Maybe<CompanyBranch>
}

export type CompanyBranch = {
    __typename?: 'CompanyBranch'
    id: Scalars['ID']
    branchNr?: Maybe<Scalars['String']>
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    foundationDate?: Maybe<Scalars['String']>
    registrationDate?: Maybe<Scalars['String']>
    company?: Maybe<Company>
    visitingAddress?: Maybe<BranchAddress>
    postalAddress?: Maybe<BranchAddress>
    isMainBranch?: Maybe<Scalars['Boolean']>
}

export type CompanyInput = {
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    street?: Maybe<Scalars['String']>
    houseNumber?: Maybe<Scalars['Int']>
    houseNumberSuffix?: Maybe<Scalars['String']>
    zipcode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
}

export type CompanySearchAddress = {
    __typename?: 'CompanySearchAddress'
    id: Scalars['ID']
    city?: Maybe<Scalars['String']>
    street?: Maybe<Scalars['String']>
    houseNr?: Maybe<Scalars['String']>
    houseNrSuffix?: Maybe<Scalars['String']>
    zipCode?: Maybe<Scalars['String']>
    country?: Maybe<Scalars['String']>
}

export type CompanySearchResult = {
    __typename?: 'CompanySearchResult'
    id: Scalars['ID']
    branchNr?: Maybe<Scalars['String']>
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    address?: Maybe<CompanySearchAddress>
    isMainBranch?: Maybe<Scalars['Boolean']>
}

export type ConfigurationBrand = {
    __typename?: 'ConfigurationBrand'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name?: Maybe<Scalars['String']>
    labelId?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    models?: Maybe<Array<Maybe<ConfigurationModel>>>
}

export type ConfigurationBrandInput = {
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
}

export type ConfigurationBrandResult = ConfigurationBrand | EntityNotFoundError

export type ConfigurationCalculation = {
    __typename?: 'ConfigurationCalculation'
    id: Scalars['ID']
    createdAt: Scalars['Date']
    updatedAt: Scalars['Date']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    components?: Maybe<Array<Maybe<PricingConfigurationComponent>>>
}

export type ConfigurationCalculationCollection = PaginatedResult & {
    __typename?: 'ConfigurationCalculationCollection'
    configurationCalculations?: Maybe<Array<Maybe<ConfigurationCalculation>>>
    totalResults?: Maybe<Scalars['Int']>
}

export type ConfigurationCalculationResult = ConfigurationCalculation | EntityNotFoundError

export type ConfigurationEdition = {
    __typename?: 'ConfigurationEdition'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name?: Maybe<Scalars['String']>
    labelId?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    model?: Maybe<ConfigurationModel>
    options?: Maybe<Array<Maybe<EditionOptionInterface>>>
    displayOrder?: Maybe<Scalars['Int']>
    residualValueTable?: Maybe<ResidualValueTable>
    durations?: Maybe<Array<Maybe<Duration>>>
    mileages?: Maybe<Array<Maybe<Mileage>>>
    /** undefined Only available with roles ADMIN */
    documentationFee?: Maybe<Scalars['Int']>
    /** undefined Only available with roles ADMIN */
    interestRate?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    maintenanceCostPerKm?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    replacementTransportationCost?: Maybe<Scalars['Int']>
    /** undefined Only available with roles ADMIN */
    proDriveCost?: Maybe<Scalars['Int']>
    /** undefined Only available with roles ADMIN */
    VAT?: Maybe<Scalars['Int']>
    /** undefined Only available with roles ADMIN */
    margin?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    lessKm?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    moreKm?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    privateLeaseLessKm?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    privateLeaseMoreKm?: Maybe<Scalars['Float']>
    autodiskEdition?: Maybe<AutodiskEdition>
    /** undefined Only available with roles ADMIN */
    calculation?: Maybe<ConfigurationCalculation>
    /** undefined Only available with roles ADMIN */
    usedCarCalculation?: Maybe<ConfigurationCalculation>
    showInConfigurator?: Maybe<Scalars['Boolean']>
    editionLabel?: Maybe<Scalars['String']>
}

export type ConfigurationEditionInput = {
    name?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    modelId?: Maybe<Scalars['String']>
    residualValueTableId?: Maybe<Scalars['String']>
    displayOrder?: Maybe<Scalars['Int']>
    documentationFee?: Maybe<Scalars['Int']>
    interestRate?: Maybe<Scalars['Float']>
    maintenanceCostPerKm?: Maybe<Scalars['Float']>
    replacementTransportationCost?: Maybe<Scalars['Int']>
    proDriveCost?: Maybe<Scalars['Int']>
    VAT?: Maybe<Scalars['Int']>
    margin?: Maybe<Scalars['Float']>
    lessKm?: Maybe<Scalars['Float']>
    moreKm?: Maybe<Scalars['Float']>
    privateLeaseLessKm?: Maybe<Scalars['Float']>
    privateLeaseMoreKm?: Maybe<Scalars['Float']>
    configurationCalculationId?: Maybe<Scalars['String']>
    usedCarConfigurationCalculationId?: Maybe<Scalars['String']>
    showInConfigurator?: Maybe<Scalars['Boolean']>
    editionLabel?: Maybe<Scalars['String']>
}

export type ConfigurationEditionOptionInput = {
    optionId?: Maybe<Scalars['String']>
    editionId?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
    price?: Maybe<Scalars['Float']>
}

export type ConfigurationEditionOptionResult =
    | EditionOption
    | AfterDeliveryEditionOption
    | LeaseEditionOption
    | WheelEditionOption
    | EntityNotFoundError

export enum ConfigurationEditionOptionType {
    AfterDeliveryEditionOption = 'AfterDeliveryEditionOption',
    LeaseEditionOption = 'LeaseEditionOption',
    EditionOption = 'EditionOption',
    WheelEditionOption = 'WheelEditionOption',
}

export type ConfigurationEditionResult = ConfigurationEdition | EntityNotFoundError

export type ConfigurationModel = {
    __typename?: 'ConfigurationModel'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name?: Maybe<Scalars['String']>
    labelId?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    editions?: Maybe<Array<Maybe<ConfigurationEdition>>>
    brand?: Maybe<ConfigurationBrand>
    autodiskModel?: Maybe<AutodiskModel>
}

export type ConfigurationModelInput = {
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    brandId?: Maybe<Scalars['String']>
}

export type ConfigurationModelResult = ConfigurationModel | EntityNotFoundError

export type ConfigurationOption = {
    __typename?: 'ConfigurationOption'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    type?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    manufacturerCode?: Maybe<Scalars['String']>
    displayOrder: Scalars['Int']
}

export type ConfigurationOptionInput = {
    name?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
    manufacturerCode?: Maybe<Scalars['String']>
    displayOrder?: Maybe<Scalars['Int']>
}

export type ConfigurationOptionResult = ConfigurationOption | EntityNotFoundError

export type ConfigurationPricing = {
    __typename?: 'ConfigurationPricing'
    fiscalValue: Scalars['Int']
    leasingPriceExVAT: Scalars['Float']
    leasingPriceIncVAT: Scalars['Float']
}

export enum ConfigurationType {
    Paint = 'paint',
    Interior = 'interior',
    Interior2 = 'interior2',
    Autopilot = 'autopilot',
    Seating = 'seating',
    Spoiler = 'spoiler',
    Carboninterior = 'carboninterior',
    Accessories = 'accessories',
    Roof = 'roof',
    Rims = 'rims',
    Rearfacingseats = 'rearfacingseats',
    Towhitch = 'towhitch',
    EigenRisicoVerlaging = 'eigenRisicoVerlaging',
    SteeringWheel = 'steeringWheel',
}

export type Contract = {
    __typename?: 'Contract'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    leasingType?: Maybe<LeasingType>
    /** @deprecated Not necessary according to operations. */
    contractType?: Maybe<Scalars['String']>
    lessor?: Maybe<Lessor>
    lessee?: Maybe<ContractLessee>
    driver?: Maybe<ContractDriver>
    car?: Maybe<Car>
    mileage?: Maybe<Scalars['Int']>
    duration?: Maybe<Scalars['Int']>
    startDate?: Maybe<Scalars['Date']>
    endDate?: Maybe<Scalars['Date']>
    pricePerMonthExVAT?: Maybe<Scalars['Float']>
    pricePerMonthIncVAT?: Maybe<Scalars['Float']>
    moreKmPrice?: Maybe<Scalars['Float']>
    lessKmPrice?: Maybe<Scalars['Float']>
    winterTires?: Maybe<Scalars['Boolean']>
    contractNumber?: Maybe<Scalars['Int']>
    /** undefined Only available with roles ADMIN */
    remarks?: Maybe<Scalars['String']>
    status?: Maybe<ContractStatus>
    /** undefined Only available with roles ADMIN */
    leasewiseStatus?: Maybe<Scalars['String']>
    deductible?: Maybe<Scalars['Int']>
    windowBreakDeductible?: Maybe<Scalars['Int']>
    fsd?: Maybe<Scalars['Boolean']>
    fsdGrowthModel?: Maybe<Scalars['Boolean']>
    fsdGrowthFee?: Maybe<Scalars['Float']>
    billedUntil?: Maybe<Scalars['Date']>
    propositions: Array<Proposition>
    downpayment?: Maybe<Scalars['Float']>
    mileageAtContractStart: Scalars['Float']
    accountManager?: Maybe<UserProfile>
    paymentSummary?: Maybe<PaymentSummary>
    attachments: Array<ContractAttachment>
    premiumConnectivity?: Maybe<Scalars['Boolean']>
}

export type ContractAttachment = {
    __typename?: 'ContractAttachment'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name: Scalars['String']
    type?: Maybe<ContractAttachmentType>
    url: Scalars['String']
}

export enum ContractAttachmentType {
    SignedContract = 'SIGNED_CONTRACT',
    ChamberOfCommerceDocument = 'CHAMBER_OF_COMMERCE_DOCUMENT',
    CreditCheck = 'CREDIT_CHECK',
    GeneralTermsAndConditions = 'GENERAL_TERMS_AND_CONDITIONS',
    SignedContractWithGeneralTermsAndConditions = 'SIGNED_CONTRACT_WITH_GENERAL_TERMS_AND_CONDITIONS',
    Other = 'OTHER',
}

export type ContractCarFilter = {
    id?: Maybe<Scalars['ID']>
    VIN?: Maybe<Scalars['String']>
    licensePlateNumber?: Maybe<Scalars['String']>
}

export type ContractCollection = PaginatedResult & {
    __typename?: 'ContractCollection'
    contracts?: Maybe<Array<Maybe<Contract>>>
    totalResults?: Maybe<Scalars['Int']>
}

export type ContractCompany = {
    __typename?: 'ContractCompany'
    id: Scalars['ID']
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    name: Scalars['String']
    emailAddress?: Maybe<Scalars['String']>
    phonenumber?: Maybe<Scalars['String']>
    postalAddress?: Maybe<Address>
    visitingAddress?: Maybe<Address>
}

export type ContractDriver = Driver & {
    __typename?: 'ContractDriver'
    id: Scalars['ID']
    firstName?: Maybe<Scalars['String']>
    fullName?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['String']>
    workPhonenumber?: Maybe<Scalars['String']>
    mobilePhonenumber?: Maybe<Scalars['String']>
    address?: Maybe<Address>
}

export type ContractDriverFilter = {
    name_contains?: Maybe<Scalars['String']>
    email_contains?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['String']>
}

export type ContractFilter = {
    AND?: Maybe<ContractFilter>
    OR?: Maybe<ContractFilter>
    car?: Maybe<ContractCarFilter>
    lessee?: Maybe<ContractLesseeFilter>
    driver?: Maybe<ContractDriverFilter>
    status?: Maybe<ContractStatus>
    entity?: Maybe<LessorEntity>
    hasAttachments?: Maybe<Scalars['Boolean']>
}

export type ContractInput = {
    entity?: Maybe<LessorEntity>
    mileage?: Maybe<Scalars['Int']>
    duration?: Maybe<Scalars['Int']>
    startDate?: Maybe<Scalars['Date']>
    endDate?: Maybe<Scalars['Date']>
    pricePerMonthExVAT?: Maybe<Scalars['Float']>
    contractType?: Maybe<Scalars['String']>
    leasingType?: Maybe<LeasingType>
    moreKm?: Maybe<Scalars['Float']>
    lessKm?: Maybe<Scalars['Float']>
    VIN?: Maybe<Scalars['String']>
    lessee?: Maybe<LesseeInput>
    driver?: Maybe<DriverInput>
    phonenumber?: Maybe<Scalars['String']>
    contractNumber?: Maybe<Scalars['Int']>
    winterTires?: Maybe<Scalars['Boolean']>
    tires?: Maybe<Scalars['Boolean']>
    remarks?: Maybe<Scalars['String']>
    leasewiseStatus?: Maybe<Scalars['String']>
    status?: Maybe<ContractStatus>
    yearOfManufacture?: Maybe<Scalars['Date']>
    fsd?: Maybe<Scalars['Boolean']>
    fsdGrowthModel?: Maybe<Scalars['Boolean']>
    fsdGrowthFee?: Maybe<Scalars['Float']>
    billedUntil?: Maybe<Scalars['Date']>
    deductible?: Maybe<Scalars['Float']>
    windowBreakDeductible?: Maybe<Scalars['Float']>
    mileageAtContractStart?: Maybe<Scalars['Float']>
    downpayment?: Maybe<Scalars['Float']>
    carId?: Maybe<Scalars['String']>
}

export type ContractLessee = ContractCompany | ContractPerson

export type ContractLesseeFilter = {
    name_contains?: Maybe<Scalars['String']>
}

export type ContractPerson = {
    __typename?: 'ContractPerson'
    id: Scalars['ID']
    emailAddress?: Maybe<Scalars['String']>
    phonenumber?: Maybe<Scalars['String']>
    address?: Maybe<Address>
    name?: Maybe<Scalars['String']>
}

export type ContractRecalculation = {
    __typename?: 'ContractRecalculation'
    id: Scalars['ID']
    oldPricePerMonthExVAT: Scalars['Float']
    newPricePerMonthExVAT: Scalars['Float']
    priceIncreaseExVAT: Scalars['Float']
    oneTimeFeeExVAT: Scalars['Float']
    oldPricePerMonthIncVAT: Scalars['Float']
    newPricePerMonthIncVAT: Scalars['Float']
    priceIncreaseIncVAT: Scalars['Float']
    oneTimeFeeIncVAT: Scalars['Float']
}

export enum ContractStatus {
    Ordered = 'ORDERED',
    Ongoing = 'ONGOING',
    Tobesigned = 'TOBESIGNED',
    Ended = 'ENDED',
}

export type CopyResidualValueInput = {
    name?: Maybe<Scalars['String']>
    residualValueTableId?: Maybe<Scalars['String']>
}

export enum CurrencyCode {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xag = 'XAG',
    Xau = 'XAU',
    Xba = 'XBA',
    Xbb = 'XBB',
    Xbc = 'XBC',
    Xbd = 'XBD',
    Xcd = 'XCD',
    Xdr = 'XDR',
    Xof = 'XOF',
    Xpd = 'XPD',
    Xpf = 'XPF',
    Xpt = 'XPT',
    Xsu = 'XSU',
    Xts = 'XTS',
    Xua = 'XUA',
    Xxx = 'XXX',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

export type CurrentUserPayload = {
    __typename?: 'CurrentUserPayload'
    id: Scalars['ID']
}

export type Deal = {
    __typename?: 'Deal'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    offerAcceptedDate?: Maybe<Scalars['Date']>
    options: Array<DealOption>
    lessor: Lessor
    lessee?: Maybe<Lessee>
    requirements: DealRequirements
    signingPersons: Array<SigningPerson>
    legalDocumentNames: Array<LegalDocumentName>
    driver?: Maybe<DealDriver>
    userId?: Maybe<Scalars['String']>
    creatorId?: Maybe<Scalars['String']>
    remarks?: Maybe<Scalars['String']>
    internalOwnerId?: Maybe<Scalars['String']>
    contact?: Maybe<DealContact>
    billingAccount?: Maybe<BillingAccount>
    pricing: Pricing
    referralCode?: Maybe<Scalars['String']>
    referralProgram?: Maybe<ReferralProgram>
    manufacturerReferralCode?: Maybe<Scalars['String']>
    manufacturerAccountEmailAddress?: Maybe<Scalars['String']>
    assetOwnerComment?: Maybe<Scalars['String']>
    status: Status
    dealType: DealType
    /** undefined Only available with roles ADMIN */
    carId?: Maybe<Scalars['String']>
    brandName?: Maybe<Scalars['String']>
    modelName?: Maybe<Scalars['String']>
    editionName?: Maybe<Scalars['String']>
    locale?: Maybe<DealLocale>
    calculationComponents?: Maybe<Scalars['String']>
    licencePlate?: Maybe<Scalars['String']>
    optionsDescription?: Maybe<Scalars['String']>
    edition?: Maybe<ConfigurationEdition>
}

export type DealBillingInput = {
    accountHolder: Scalars['String']
    iban?: Maybe<Scalars['IBAN']>
    emailAddress?: Maybe<Scalars['EmailAddress']>
}

export type DealCollection = PaginatedResult & {
    __typename?: 'DealCollection'
    deals?: Maybe<Array<Maybe<Deal>>>
    totalResults?: Maybe<Scalars['Int']>
}

export type DealContact = {
    __typename?: 'DealContact'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['EmailAddress']>
    phonenumber?: Maybe<Scalars['String']>
}

export type DealContactInput = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['EmailAddress']>
    phonenumber?: Maybe<Scalars['String']>
}

export type DealContractPdf = {
    __typename?: 'DealContractPdf'
    dealId: Scalars['ID']
    url: Scalars['String']
}

export type DealDriver = Driver & {
    __typename?: 'DealDriver'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    userId: Scalars['String']
    emailAddress?: Maybe<Scalars['String']>
    phonenumber?: Maybe<Scalars['String']>
    address?: Maybe<Address>
    licenseVerified?: Maybe<Scalars['Boolean']>
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
}

export type DealFilter = {
    AND?: Maybe<Array<Maybe<DealFilter>>>
    OR?: Maybe<Array<Maybe<DealFilter>>>
    offerAcceptedDate?: Maybe<Scalars['Date']>
    internalOwnerId?: Maybe<Scalars['String']>
    ownerId?: Maybe<Scalars['String']>
    status?: Maybe<Status>
    kcStatus?: Maybe<InternalStatus>
    kvkStatus?: Maybe<InternalStatus>
    company?: Maybe<LesseeFilter>
    contactEmailAddress_contains?: Maybe<Scalars['String']>
    contactFullname_contains?: Maybe<Scalars['String']>
    driver?: Maybe<DriverFilter>
}

export type DealInput = {
    editionId?: Maybe<Scalars['ID']>
    shopCarId?: Maybe<Scalars['ID']>
    carId?: Maybe<Scalars['ID']>
    pricingConfigurationId?: Maybe<Scalars['ID']>
    referralCode?: Maybe<Scalars['String']>
    lessorAdministrationCode?: Maybe<Scalars['String']>
    pricingConfigurationComponents?: Maybe<Array<Maybe<PricingConfigurationComponentInput>>>
    locale?: Maybe<DealLocale>
    deductible?: Maybe<Scalars['String']>
}

export enum DealLocale {
    NlNl = 'nl_NL',
    NlBe = 'nl_BE',
    FrBe = 'fr_BE',
    DeDe = 'de_DE',
    DaDk = 'da_DK',
    FrFr = 'fr_FR',
}

export type DealOption = BooleanDealOption | SelectableDealOption

export type DealRequirements = {
    __typename?: 'DealRequirements'
    id: Scalars['ID']
    isCompanyRequired?: Maybe<Scalars['Boolean']>
    isDriverRequired?: Maybe<Scalars['Boolean']>
}

export enum DealType {
    ConfigurableCar = 'CONFIGURABLE_CAR',
    ConfiguredCar = 'CONFIGURED_CAR',
}

export enum DeleteDocumentResult {
    Deleted = 'DELETED',
    DocumentIsReadOnly = 'DOCUMENT_IS_READ_ONLY',
    AccessNotGranted = 'ACCESS_NOT_GRANTED',
}

export type Document = {
    __typename?: 'Document'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    category: DocumentCategory
    mimeType: Scalars['String']
    readonly: Scalars['Boolean']
    metadata?: Maybe<DocumentMetadata>
    bucketType: BucketType
    bucketId: Scalars['String']
    retrievalUrl: Scalars['String']
}

export enum DocumentCategory {
    SignedDealContract = 'SIGNED_DEAL_CONTRACT',
}

export type DocumentCollection = PaginatedResult & {
    __typename?: 'DocumentCollection'
    documents: Array<Document>
    totalResults?: Maybe<Scalars['Int']>
}

export type DocumentFilter = {
    AND?: Maybe<Array<DocumentFilter>>
    OR?: Maybe<Array<DocumentFilter>>
    bucketType?: Maybe<BucketType>
}

export type DocumentInput = {
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    content: Scalars['Base64']
    documentUploadToken: Scalars['String']
}

export type DocumentMetadata = PdfMetadata | ImageMetadata | MiscellaneousMetadata

export type DocumentSignature = {
    __typename?: 'DocumentSignature'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    document: Document
    verified: Scalars['Boolean']
    verificationTimestamp?: Maybe<Scalars['Date']>
    verifiedByUserId?: Maybe<Scalars['String']>
}

export type Driver = {
    firstName?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['String']>
    address?: Maybe<Address>
}

export type DriverFilter = {
    AND?: Maybe<Array<Maybe<DriverFilter>>>
    OR?: Maybe<Array<Maybe<DriverFilter>>>
    emailAddress_contains?: Maybe<Scalars['String']>
    fullName_contains?: Maybe<Scalars['String']>
    licenseVerified?: Maybe<Scalars['Boolean']>
    userId?: Maybe<Scalars['String']>
}

export type DriverInput = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    initials?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['EmailAddress']>
    workPhonenumber?: Maybe<Scalars['String']>
    mobilePhonenumber?: Maybe<Scalars['String']>
    address?: Maybe<AddressInput>
}

export type Duration = {
    __typename?: 'Duration'
    id: Scalars['ID']
    value: Scalars['Int']
    unit: Scalars['String']
}

export type EditionInfo = {
    __typename?: 'EditionInfo'
    model?: Maybe<Scalars['String']>
    edition?: Maybe<Scalars['String']>
    price?: Maybe<Scalars['Int']>
}

export type EditionOption = EditionOptionInterface & {
    __typename?: 'EditionOption'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    labelId?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
    option?: Maybe<ConfigurationOption>
    edition?: Maybe<ConfigurationEdition>
}

export type EditionOptionInterface = {
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    labelId?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
    option?: Maybe<ConfigurationOption>
    edition?: Maybe<ConfigurationEdition>
}

export type EditionValidation = {
    __typename?: 'EditionValidation'
    valid: Scalars['Boolean']
    errors?: Maybe<Array<Maybe<EditionValidationResult>>>
}

export type EditionValidationResult = {
    __typename?: 'EditionValidationResult'
    message?: Maybe<Scalars['String']>
    code?: Maybe<Scalars['String']>
    faultyOptions?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type EmailTemplate = {
    __typename?: 'EmailTemplate'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name: Scalars['String']
    emailTemplateLocales: Array<EmailTemplateLocale>
}

export type EmailTemplateCollection = PaginatedResult & {
    __typename?: 'EmailTemplateCollection'
    emailTemplates?: Maybe<Array<Maybe<EmailTemplate>>>
    totalResults?: Maybe<Scalars['Int']>
}

export type EmailTemplateFilter = {
    name_contains?: Maybe<Scalars['String']>
}

export type EmailTemplateInput = {
    name: Scalars['String']
    emailTemplateLocales: Array<EmailTemplateLocaleInput>
}

export type EmailTemplateLocale = {
    __typename?: 'EmailTemplateLocale'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    locale: WebsiteLocale
    subject: Scalars['String']
    previewText: Scalars['String']
    htmlContent: Scalars['String']
    rawTemplateContent: Scalars['JSON']
}

export type EmailTemplateLocaleInput = {
    id?: Maybe<Scalars['ID']>
    locale: WebsiteLocale
    subject: Scalars['String']
    previewText: Scalars['String']
    htmlContent: Scalars['String']
    rawTemplateContent: Scalars['JSON']
}

export type EndContractProposition = {
    __typename?: 'EndContractProposition'
    id: Scalars['ID']
    oneTimeFeeExVAT: Scalars['Float']
    oneTimeFeeIncVAT: Scalars['Float']
}

export type Entity = {
    __typename?: 'Entity'
    id: Scalars['ID']
    type: EntityType
    name: Scalars['String']
    properties: Array<EntityProperty>
}

export type EntityNotFoundError = ExpectedError & {
    __typename?: 'EntityNotFoundError'
    errorCode: ErrorCode
}

export type EntityProperty = {
    __typename?: 'EntityProperty'
    id: Scalars['ID']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
}

export type EntityRequirementInput = {
    property: Scalars['String']
    filter: Array<WorkflowEntityRequirementFilter>
}

export enum EntityType {
    User = 'USER',
    Company = 'COMPANY',
    Deal = 'DEAL',
    AuthenticationToken = 'AUTHENTICATION_TOKEN',
    ContractProposition = 'CONTRACT_PROPOSITION',
}

export enum ErrorCode {
    EntityNotFound = 'ENTITY_NOT_FOUND',
    InvalidInputData = 'INVALID_INPUT_DATA',
    RemovalNotAllowed = 'REMOVAL_NOT_ALLOWED',
}

export type Event = {
    __typename?: 'Event'
    id: Scalars['ID']
    timestamp: Scalars['Date']
    type: EventType
    data?: Maybe<Array<EventData>>
    userId?: Maybe<Scalars['String']>
    user?: Maybe<User>
    entityType: EventEntityType
    entityId: Scalars['String']
    entity?: Maybe<EventEntity>
}

export type EventCollection = PaginatedResult & {
    __typename?: 'EventCollection'
    events?: Maybe<Array<Maybe<Event>>>
    totalResults?: Maybe<Scalars['Int']>
}

export type EventData = {
    __typename?: 'EventData'
    property: Scalars['String']
    from?: Maybe<Scalars['String']>
    to?: Maybe<Scalars['String']>
}

export type EventEntity = Contract | Car | EntityNotFoundError

export enum EventEntityType {
    Car = 'CAR',
    Contract = 'CONTRACT',
}

export type EventFilter = {
    AND?: Maybe<Array<Maybe<EventFilter>>>
    OR?: Maybe<Array<Maybe<EventFilter>>>
    entityType?: Maybe<EventEntityType>
    entityId?: Maybe<Scalars['String']>
}

export enum EventType {
    Create = 'CREATE',
    Delete = 'DELETE',
    Update = 'UPDATE',
}

export type ExpectedError = {
    errorCode: ErrorCode
}

export type FsdProposition = {
    __typename?: 'FSDProposition'
    id: Scalars['ID']
    oldPricePerMonthExVAT: Scalars['Float']
    newPricePerMonthExVAT: Scalars['Float']
    priceIncreaseExVAT: Scalars['Float']
    oldPricePerMonthIncVAT: Scalars['Float']
    newPricePerMonthIncVAT: Scalars['Float']
    priceIncreaseIncVAT: Scalars['Float']
    type: FsdPropositionType
}

export enum FsdPropositionType {
    Fixed = 'FIXED',
    Variable = 'VARIABLE',
}

export enum IdCheckResult {
    Clear = 'CLEAR',
    Consider = 'CONSIDER',
    AwaitingResult = 'AWAITING_RESULT',
    NoCheckStarted = 'NO_CHECK_STARTED',
}

export type IdCheckStatus = {
    __typename?: 'IdCheckStatus'
    result: IdCheckResult
    check?: Maybe<Array<Maybe<Check>>>
}

export type IdReportStatus = {
    __typename?: 'IdReportStatus'
    name: Scalars['String']
    status: Scalars['String']
    id: Scalars['String']
    result?: Maybe<Scalars['String']>
    breakdown?: Maybe<Scalars['JSON']>
}

export type IdealSignature = {
    __typename?: 'IdealSignature'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    accountName?: Maybe<Scalars['String']>
    iban?: Maybe<Scalars['IBAN']>
    paymentId?: Maybe<Scalars['String']>
    status?: Maybe<PaymentStatus>
}

export type ImageMetadata = {
    __typename?: 'ImageMetadata'
    sizeInBytes?: Maybe<Scalars['Int']>
    width?: Maybe<Scalars['Int']>
    height?: Maybe<Scalars['Int']>
}

export enum InternalStatus {
    NotStarted = 'NOT_STARTED',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
}

export type InvalidInputDataError = ExpectedError & {
    __typename?: 'InvalidInputDataError'
    errorCode: ErrorCode
    missingInputProperties: Array<Scalars['String']>
}

export type JsonWebToken = {
    __typename?: 'JsonWebToken'
    userId?: Maybe<Scalars['ID']>
    token?: Maybe<Scalars['String']>
}

export type LeaseEditionOption = EditionOptionInterface & {
    __typename?: 'LeaseEditionOption'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    labelId?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
    option?: Maybe<ConfigurationOption>
    edition?: Maybe<ConfigurationEdition>
    price?: Maybe<Scalars['Float']>
}

export type LeasewiseOfferStatus = {
    __typename?: 'LeasewiseOfferStatus'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    dealId?: Maybe<Scalars['ID']>
    internetNr?: Maybe<Scalars['Int']>
    status?: Maybe<LeasewiseOfferStatusName>
    error?: Maybe<Scalars['String']>
}

export enum LeasewiseOfferStatusName {
    Pending = 'pending',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

export enum LeasingType {
    Fulloperational = 'FULLOPERATIONAL',
    Private = 'PRIVATE',
    Ready_2Share = 'READY_2_SHARE',
}

export type LegalDocument = {
    __typename?: 'LegalDocument'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
    content?: Maybe<LegalDocumentContent>
    versions?: Maybe<Array<Maybe<LegalDocumentContent>>>
}

export type LegalDocumentAgreement = {
    __typename?: 'LegalDocumentAgreement'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    hash?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['String']>
    variables?: Maybe<Array<Maybe<LegalDocumentAgreementVariable>>>
    title?: Maybe<Scalars['String']>
    body?: Maybe<Scalars['String']>
    agreement?: Maybe<Scalars['String']>
    summary?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
}

export type LegalDocumentAgreementTemplate = {
    __typename?: 'LegalDocumentAgreementTemplate'
    hash?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['String']>
    variables?: Maybe<Array<Maybe<LegalDocumentAgreementVariable>>>
    title?: Maybe<Scalars['String']>
    body?: Maybe<Scalars['String']>
    agreement?: Maybe<Scalars['String']>
    summary?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
}

export type LegalDocumentAgreementVariable = {
    __typename?: 'LegalDocumentAgreementVariable'
    type?: Maybe<LegalDocumentContentVariable>
    value?: Maybe<Scalars['String']>
}

export type LegalDocumentAgreementVariableInput = {
    type?: Maybe<LegalDocumentContentVariable>
    value?: Maybe<Scalars['String']>
}

export type LegalDocumentContent = {
    __typename?: 'LegalDocumentContent'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    publishedAt?: Maybe<Scalars['Date']>
    archivedAt?: Maybe<Scalars['Date']>
    title?: Maybe<Scalars['String']>
    body?: Maybe<Scalars['String']>
    agreement?: Maybe<Scalars['String']>
    summary?: Maybe<Scalars['String']>
    hash?: Maybe<Scalars['String']>
    variables?: Maybe<Array<Maybe<LegalDocumentContentVariable>>>
    document?: Maybe<LegalDocument>
}

export enum LegalDocumentContentVariable {
    CompanyName = 'companyName',
    CompanyChamberOfCommerce = 'companyChamberOfCommerce',
    FullName = 'fullName',
}

export enum LegalDocumentName {
    PrivateLeaseContractShort = 'private_lease_contract_short',
    PrivacyPolicy = 'privacy_policy',
    TermsAndConditions = 'terms_and_conditions',
    CostOverview = 'cost_overview',
    FolCostOverview = 'fol_cost_overview',
    PrivateLeaseCostOverview = 'private_lease_cost_overview',
    Proviso = 'proviso',
    PrivateLeaseKeurmerk = 'private_lease_keurmerk',
    PrivateLeaseContract = 'private_lease_contract',
    PrivateLeaseTermsAndConditions = 'private_lease_terms_and_conditions',
    PrivateLeasePolicyConditions = 'private_lease_policy_conditions',
    PrivateLeaseIntakeProtocol = 'private_lease_intake_protocol',
    PrivateLeaseContractAddendum = 'private_lease_contract_addendum',
    ServiceMaintenance = 'service_maintenance',
}

export type Lessee = LesseeCompany | LesseePerson

export type LesseeCompany = {
    __typename?: 'LesseeCompany'
    id: Scalars['ID']
    createdAt: Scalars['Date']
    updatedAt: Scalars['Date']
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    name: Scalars['String']
    postalAddress?: Maybe<Address>
    visitingAddress?: Maybe<Address>
    kcStatus: InternalStatus
    kcComment?: Maybe<Scalars['String']>
    kvkStatus: InternalStatus
    kvkComment?: Maybe<Scalars['String']>
}

export type LesseeFilter = {
    name_contains?: Maybe<Scalars['String']>
    chamberOfCommerceNr_contains?: Maybe<Scalars['String']>
}

export type LesseeInput = {
    name?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['EmailAddress']>
    phonenumber?: Maybe<Scalars['String']>
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    visitingAddress?: Maybe<AddressInput>
    postalAddress?: Maybe<AddressInput>
}

export type LesseePerson = {
    __typename?: 'LesseePerson'
    id: Scalars['ID']
    createdAt: Scalars['Date']
    updatedAt: Scalars['Date']
    userId: Scalars['String']
    emailAddress: Scalars['EmailAddress']
    phonenumber?: Maybe<Scalars['String']>
    address?: Maybe<Address>
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    kcStatus: InternalStatus
    kcComment?: Maybe<Scalars['String']>
}

export type Lessor = {
    __typename?: 'Lessor'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
    chamberOfCommerceNr?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    postalAddress?: Maybe<Address>
    visitingAddress?: Maybe<Address>
}

export enum LessorEntity {
    Mgl1 = 'MGL1',
    Mgl2 = 'MGL2',
    Gmbh = 'GMBH',
    Bvba = 'BVBA',
    Dk = 'DK',
}

export type LoginToken = {
    __typename?: 'LoginToken'
    id?: Maybe<Scalars['ID']>
    createAt?: Maybe<Scalars['Date']>
    token: Scalars['String']
}

export type LoginUrlPayload = {
    __typename?: 'LoginUrlPayload'
    id: Scalars['ID']
    token: Scalars['String']
    url: Scalars['String']
}

export type LookupAddress = {
    __typename?: 'LookupAddress'
    id: Scalars['ID']
    street: Scalars['String']
    city: Scalars['String']
}

export type LookupAddressInput = {
    zipcode: Scalars['String']
    houseNumber?: Maybe<Scalars['String']>
    houseNumberSuffix?: Maybe<Scalars['String']>
}

export type Mileage = {
    __typename?: 'Mileage'
    id: Scalars['ID']
    value?: Maybe<Scalars['Int']>
    unit?: Maybe<Scalars['String']>
}

export type MiscellaneousMetadata = {
    __typename?: 'MiscellaneousMetadata'
    sizeInBytes?: Maybe<Scalars['Int']>
}

export type Mutation = {
    __typename?: 'Mutation'
    /** undefined Only available with roles ADMIN */
    createLoginUrl?: Maybe<LoginUrlPayload>
    exchangeForJsonWebToken?: Maybe<JsonWebToken>
    refreshLoginUrl?: Maybe<Scalars['Boolean']>
    requestLoginEmail?: Maybe<Scalars['Boolean']>
    createGuestToken?: Maybe<JsonWebToken>
    /** undefined Only available with roles ADMIN */
    createJsonWebToken?: Maybe<JsonWebToken>
    signupUser?: Maybe<JsonWebToken>
    /** undefined Only available with roles ADMIN */
    createUser?: Maybe<User>
    /** undefined Only available with roles ADMIN */
    addUserRole?: Maybe<User>
    /** undefined Only available with roles SUPERADMIN */
    setUserRoles?: Maybe<User>
    /** undefined Only available with roles ADMIN */
    setUserTenants?: Maybe<User>
    /** undefined Only available with roles ADMIN */
    removeUserRole?: Maybe<User>
    /** undefined Only available with roles ADMIN */
    deleteUser?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    upsertCar: UpsertCarResult
    /** undefined Only available with roles ADMIN */
    removeCar: RemoveCarResult
    /** undefined Only available with roles ADMIN */
    importCarFromLeasewise: CarResult
    /** undefined Only available with roles ADMIN */
    publishToCarShop: CarShopPublicationResult
    /** undefined Only available with roles ADMIN */
    unpublishRemarketingPublication: UnpublishRemarketingPublicationResult
    updateCompanyCreditscore?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    createBrand?: Maybe<ConfigurationBrand>
    /** undefined Only available with roles ADMIN */
    updateBrand?: Maybe<ConfigurationBrand>
    /** undefined Only available with roles ADMIN */
    removeBrand?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    createModel?: Maybe<ConfigurationModel>
    /** undefined Only available with roles ADMIN */
    updateModel?: Maybe<ConfigurationModel>
    /** undefined Only available with roles ADMIN */
    removeModel?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    createEdition?: Maybe<ConfigurationEdition>
    /** undefined Only available with roles ADMIN */
    updateEdition?: Maybe<ConfigurationEdition>
    /** undefined Only available with roles ADMIN */
    removeEdition?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    addOptionToEdition?: Maybe<EditionOption>
    /** undefined Only available with roles ADMIN */
    updateEditionOption?: Maybe<EditionOption>
    /** undefined Only available with roles ADMIN */
    copyEditionOptionsForEdition?: Maybe<Array<Maybe<EditionOption>>>
    /** undefined Only available with roles ADMIN */
    removeEditionOption?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    createOption?: Maybe<Option>
    /** undefined Only available with roles ADMIN */
    updateOption?: Maybe<Option>
    /** undefined Only available with roles ADMIN */
    removeOption?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    createResidualValueTable?: Maybe<ResidualValueTableResult>
    /** undefined Only available with roles ADMIN */
    copyResidualValueTable?: Maybe<ResidualValueTableResult>
    /** undefined Only available with roles ADMIN */
    updateResidualValueTable?: Maybe<ResidualValueTableResult>
    /** undefined Only available with roles ADMIN */
    removeResidualValueTable?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    upsertPricingConfigurationCalculation?: Maybe<ConfigurationCalculation>
    /** undefined Only available with roles ADMIN */
    duplicatePricingConfigurationCalculation?: Maybe<ConfigurationCalculation>
    /** undefined Only available with roles ADMIN */
    removeConfigurationCalculation?: Maybe<RemoveConfigurationCalculationResult>
    upsertContract?: Maybe<UpsertContractResult>
    importContractFromLeasewise?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    generateEndContractProposition?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    generateUpgradeProposition?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    generateContractRecalculationProposition?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    generateFSDProposition?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    generatePremiumConnectivityProposition?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    generateAccessoryProposition?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    uploadContractAttachment: Array<ContractAttachment>
    /** undefined Only available with roles ADMIN */
    deleteContractAttachment?: Maybe<Scalars['Boolean']>
    createDeal: Deal
    /** undefined Only available when authenticated. */
    connectDeal: Deal
    updateSelectableDealOption: Deal
    updateBooleanDealOption: Deal
    /** undefined Only available with roles ADMIN */
    updateDealMargin: Deal
    /** undefined Only available when authenticated. */
    updateDealMileage: Deal
    /** undefined Only available with roles ADMIN */
    updateDealDuration: Deal
    /** undefined Only available with roles ADMIN */
    updateDealPrice: Deal
    /** undefined Only available with roles ADMIN */
    updateDealLessKm: Deal
    /** undefined Only available with roles ADMIN */
    updateDealMoreKm: Deal
    /** undefined Only available with roles ADMIN */
    updateDealPrivateLeaseLessKm: Deal
    /** undefined Only available with roles ADMIN */
    updateDealPrivateLeaseMoreKm: Deal
    /** undefined Only available when authenticated. */
    makeDealOffer: Deal
    /** undefined Only available with roles ADMIN */
    duplicateDeal?: Maybe<Deal>
    /** undefined Only available when authenticated. */
    acceptDealOffer: Deal
    /** undefined Only available with roles ADMIN */
    finalizeDeal: Deal
    /** undefined Only available when authenticated. */
    cancelDeal: Deal
    /** undefined Only available when authenticated. */
    addSigningpersonToDeal: Deal
    /** undefined Only available when authenticated. */
    removeSigningpersonFromDeal: Deal
    /** undefined Only available when authenticated. */
    signViaIdeal: Scalars['String']
    /** undefined Only available when authenticated. */
    createDealDocumentUploadToken: Scalars['String']
    /** undefined Only available with roles ADMIN */
    verifyDealDocument: Deal
    /** undefined Only available when authenticated. */
    updateDealDriver: Deal
    updateDealReferralCode: Deal
    /** undefined Only available when authenticated. */
    updateDealManufacturerReferralCode: Deal
    /** undefined Only available when authenticated. */
    updateDealManufacturerAccountEmailAddress: Deal
    /** undefined Only available when authenticated. */
    updateDealLessee: Deal
    /** undefined Only available with roles ADMIN */
    updateDealInternalOwner: Deal
    /** undefined Only available when authenticated. */
    updateDealContact: Deal
    /** undefined Only available when authenticated. */
    updateDealBilling: Deal
    /** undefined Only available with roles ADMIN */
    updateDealKcStatus: Deal
    /** undefined Only available with roles ADMIN */
    updateDealKvkStatus: Deal
    /** undefined Only available with roles ADMIN */
    updateDealOwner: Deal
    /** undefined Only available with roles ADMIN */
    updateDealRemarks: Deal
    /** undefined Only available with roles ADMIN */
    updateDealOptionsDescription: Deal
    /** undefined Only available with roles ADMIN */
    updateDealLocale: Deal
    /** undefined Only available when authenticated. */
    uploadDocument?: Maybe<Document>
    /** undefined Only available with roles ADMIN */
    renameDocument?: Maybe<Document>
    /** undefined Only available with roles ADMIN */
    deleteDocument?: Maybe<DeleteDocumentResult>
    /** undefined Only available with roles ADMIN */
    upsertEmailTemplate?: Maybe<EmailTemplate>
    /** undefined Only available with roles ADMIN */
    removeEmailTemplate?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    sendTestEmail?: Maybe<Scalars['Boolean']>
    /** undefined Only available when authenticated. */
    executeLicenseCheck?: Maybe<Result>
    /** undefined Only available with roles ADMIN */
    editCheckResult?: Maybe<Result>
    /** undefined Only available with roles ADMIN */
    dealToLeasewise?: Maybe<LeasewiseOfferStatus>
    /** undefined Only available with roles ADMIN */
    createAutodiskModel?: Maybe<AutodiskModel>
    /** undefined Only available with roles ADMIN */
    updateAutodiskModel?: Maybe<AutodiskModel>
    /** undefined Only available with roles ADMIN */
    deleteAutodiskModel?: Maybe<AutodiskDeleteResult>
    /** undefined Only available with roles ADMIN */
    createAutodiskEdition?: Maybe<AutodiskEdition>
    /** undefined Only available with roles ADMIN */
    updateAutodiskEdition?: Maybe<AutodiskEdition>
    /** undefined Only available with roles ADMIN */
    deleteAutodiskEdition?: Maybe<AutodiskDeleteResult>
    /** undefined Only available when authenticated. */
    signLegalDocumentAgreement?: Maybe<LegalDocumentAgreement>
    /** undefined Only available with roles ADMIN */
    createLegalDocumentContent?: Maybe<LegalDocumentContent>
    /** undefined Only available with roles ADMIN */
    updateLegalDocumentContent?: Maybe<LegalDocumentContent>
    /** undefined Only available with roles ADMIN */
    deleteLegalDocumentContent?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    publishLegalDocumentContent?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    archiveLegalDocumentContent?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    createLegalDocument?: Maybe<LegalDocument>
    /** undefined Only available with roles ADMIN */
    updateLegalDocument?: Maybe<LegalDocument>
    /** undefined Only available with roles ADMIN */
    archiveLegalDocument?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles TENANT_ADMIN */
    updateTenantSettings?: Maybe<TenantSettings>
    /** undefined Only available with roles ADMIN */
    createUserProfile?: Maybe<UserProfile>
    /** undefined Only available with roles ADMIN */
    updateUserProfile?: Maybe<UserProfile>
    /** undefined Only available when authenticated. */
    updateMyUserProfile?: Maybe<UserProfile>
    /** undefined Only available with roles ADMIN */
    deleteUserProfile?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    upsertWorkflow?: Maybe<Workflow>
    /** undefined Only available with roles ADMIN */
    removeWorkflow?: Maybe<Scalars['Boolean']>
}

export type MutationCreateLoginUrlArgs = {
    userId: Scalars['ID']
    path?: Maybe<Scalars['String']>
}

export type MutationExchangeForJsonWebTokenArgs = {
    loginTokenId: Scalars['ID']
    loginToken?: Maybe<Scalars['String']>
}

export type MutationRefreshLoginUrlArgs = {
    email: Scalars['String']
    path: Scalars['String']
}

export type MutationRequestLoginEmailArgs = {
    email: Scalars['String']
    path?: Maybe<Scalars['String']>
}

export type MutationCreateJsonWebTokenArgs = {
    userId: Scalars['ID']
}

export type MutationSignupUserArgs = {
    emailAddress: Scalars['String']
}

export type MutationCreateUserArgs = {
    emailAddress: Scalars['String']
}

export type MutationAddUserRoleArgs = {
    id: Scalars['ID']
    role?: Maybe<Role>
}

export type MutationSetUserRolesArgs = {
    id: Scalars['ID']
    role?: Maybe<Array<Maybe<Role>>>
}

export type MutationSetUserTenantsArgs = {
    userId: Scalars['ID']
    tenants: Array<Scalars['String']>
}

export type MutationRemoveUserRoleArgs = {
    id: Scalars['ID']
    role?: Maybe<Role>
}

export type MutationDeleteUserArgs = {
    id: Scalars['ID']
}

export type MutationUpsertCarArgs = {
    id?: Maybe<Scalars['ID']>
    input: CarInput
}

export type MutationRemoveCarArgs = {
    id: Scalars['ID']
}

export type MutationImportCarFromLeasewiseArgs = {
    VIN: Scalars['String']
}

export type MutationPublishToCarShopArgs = {
    carId: Scalars['ID']
    input: CarShopPublicationInput
}

export type MutationUnpublishRemarketingPublicationArgs = {
    id: Scalars['ID']
}

export type MutationCreateBrandArgs = {
    input: ConfigurationBrandInput
}

export type MutationUpdateBrandArgs = {
    id: Scalars['ID']
    input?: Maybe<ConfigurationBrandInput>
}

export type MutationRemoveBrandArgs = {
    id: Scalars['ID']
}

export type MutationCreateModelArgs = {
    input: ConfigurationModelInput
}

export type MutationUpdateModelArgs = {
    id: Scalars['ID']
    input: ConfigurationModelInput
}

export type MutationRemoveModelArgs = {
    id: Scalars['ID']
}

export type MutationCreateEditionArgs = {
    input: ConfigurationEditionInput
}

export type MutationUpdateEditionArgs = {
    id: Scalars['ID']
    input: ConfigurationEditionInput
}

export type MutationRemoveEditionArgs = {
    id: Scalars['ID']
}

export type MutationAddOptionToEditionArgs = {
    input: NewConfigurationEditionOptionInput
}

export type MutationUpdateEditionOptionArgs = {
    id: Scalars['ID']
    input: ConfigurationEditionOptionInput
}

export type MutationCopyEditionOptionsForEditionArgs = {
    fromEditionId: Scalars['String']
    toEditionId: Scalars['String']
}

export type MutationRemoveEditionOptionArgs = {
    id: Scalars['ID']
}

export type MutationCreateOptionArgs = {
    input: ConfigurationOptionInput
}

export type MutationUpdateOptionArgs = {
    id: Scalars['ID']
    input: ConfigurationOptionInput
}

export type MutationRemoveOptionArgs = {
    id: Scalars['ID']
}

export type MutationCreateResidualValueTableArgs = {
    input: ResidualValueTableInput
}

export type MutationCopyResidualValueTableArgs = {
    input: CopyResidualValueInput
}

export type MutationUpdateResidualValueTableArgs = {
    id: Scalars['ID']
    input: ResidualValueTableInput
}

export type MutationRemoveResidualValueTableArgs = {
    id: Scalars['ID']
}

export type MutationUpsertPricingConfigurationCalculationArgs = {
    id?: Maybe<Scalars['ID']>
    input: PricingConfigurationCalculationInput
}

export type MutationDuplicatePricingConfigurationCalculationArgs = {
    id: Scalars['ID']
    name: Scalars['String']
    tenant?: Maybe<Scalars['String']>
}

export type MutationRemoveConfigurationCalculationArgs = {
    id: Scalars['ID']
}

export type MutationUpsertContractArgs = {
    id: Scalars['ID']
    input: ContractInput
}

export type MutationImportContractFromLeasewiseArgs = {
    VIN: Scalars['String']
}

export type MutationGenerateEndContractPropositionArgs = {
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    propositionDate: Scalars['Date']
    language: Scalars['String']
}

export type MutationGenerateUpgradePropositionArgs = {
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    propositionDate: Scalars['Date']
}

export type MutationGenerateContractRecalculationPropositionArgs = {
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    newTotalMileage: Scalars['Int']
}

export type MutationGenerateFsdPropositionArgs = {
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    type: FsdPropositionType
    amount: Scalars['Float']
}

export type MutationGeneratePremiumConnectivityPropositionArgs = {
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
}

export type MutationGenerateAccessoryPropositionArgs = {
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    accessory: Scalars['String']
}

export type MutationUploadContractAttachmentArgs = {
    contractId: Scalars['ID']
    type: ContractAttachmentType
    encodedFile: Scalars['Base64']
    fileName: Scalars['String']
}

export type MutationDeleteContractAttachmentArgs = {
    attachmentId: Scalars['ID']
}

export type MutationCreateDealArgs = {
    input: DealInput
}

export type MutationConnectDealArgs = {
    id: Scalars['ID']
}

export type MutationUpdateSelectableDealOptionArgs = {
    id: Scalars['ID']
    dealOptionId: Scalars['ID']
    valueId: Scalars['ID']
    showAllEditions?: Maybe<Scalars['Boolean']>
}

export type MutationUpdateBooleanDealOptionArgs = {
    id: Scalars['ID']
    dealOptionId: Scalars['ID']
    value: Scalars['Boolean']
}

export type MutationUpdateDealMarginArgs = {
    id: Scalars['ID']
    margin: Scalars['Float']
}

export type MutationUpdateDealMileageArgs = {
    id: Scalars['ID']
    mileage: Scalars['Int']
}

export type MutationUpdateDealDurationArgs = {
    id: Scalars['ID']
    duration: Scalars['Int']
}

export type MutationUpdateDealPriceArgs = {
    id: Scalars['ID']
    priceExVAT: Scalars['Float']
}

export type MutationUpdateDealLessKmArgs = {
    id: Scalars['ID']
    lessKmPriceExVAT?: Maybe<Scalars['Float']>
}

export type MutationUpdateDealMoreKmArgs = {
    id: Scalars['ID']
    moreKmPriceExVAT?: Maybe<Scalars['Float']>
}

export type MutationUpdateDealPrivateLeaseLessKmArgs = {
    id: Scalars['ID']
    privateLeaseLessKmIncVAT?: Maybe<Scalars['Float']>
}

export type MutationUpdateDealPrivateLeaseMoreKmArgs = {
    id: Scalars['ID']
    privateLeaseMoreKmIncVAT?: Maybe<Scalars['Float']>
}

export type MutationMakeDealOfferArgs = {
    id: Scalars['ID']
}

export type MutationDuplicateDealArgs = {
    id: Scalars['ID']
}

export type MutationAcceptDealOfferArgs = {
    id: Scalars['ID']
}

export type MutationFinalizeDealArgs = {
    id: Scalars['ID']
    comment?: Maybe<Scalars['String']>
}

export type MutationCancelDealArgs = {
    id: Scalars['ID']
    comment?: Maybe<Scalars['String']>
}

export type MutationAddSigningpersonToDealArgs = {
    id: Scalars['ID']
    email: Scalars['EmailAddress']
}

export type MutationRemoveSigningpersonFromDealArgs = {
    id: Scalars['ID']
    email: Scalars['EmailAddress']
}

export type MutationSignViaIdealArgs = {
    id: Scalars['ID']
    bankId: Scalars['String']
    callbackPath: Scalars['String']
}

export type MutationCreateDealDocumentUploadTokenArgs = {
    id: Scalars['ID']
}

export type MutationVerifyDealDocumentArgs = {
    documentSignatureId: Scalars['ID']
    isVerified: Scalars['Boolean']
}

export type MutationUpdateDealDriverArgs = {
    id: Scalars['ID']
    email?: Maybe<Scalars['String']>
}

export type MutationUpdateDealReferralCodeArgs = {
    id: Scalars['ID']
    code: Scalars['String']
}

export type MutationUpdateDealManufacturerReferralCodeArgs = {
    id: Scalars['ID']
    code: Scalars['String']
}

export type MutationUpdateDealManufacturerAccountEmailAddressArgs = {
    id: Scalars['ID']
    email: Scalars['EmailAddress']
}

export type MutationUpdateDealLesseeArgs = {
    id: Scalars['ID']
    input?: Maybe<CompanyInput>
}

export type MutationUpdateDealInternalOwnerArgs = {
    id: Scalars['ID']
    userId?: Maybe<Scalars['String']>
}

export type MutationUpdateDealContactArgs = {
    id: Scalars['ID']
    input?: Maybe<DealContactInput>
}

export type MutationUpdateDealBillingArgs = {
    id: Scalars['ID']
    input?: Maybe<DealBillingInput>
}

export type MutationUpdateDealKcStatusArgs = {
    id: Scalars['ID']
    status: InternalStatus
    message?: Maybe<Scalars['String']>
}

export type MutationUpdateDealKvkStatusArgs = {
    id: Scalars['ID']
    status: InternalStatus
    message?: Maybe<Scalars['String']>
}

export type MutationUpdateDealOwnerArgs = {
    id: Scalars['ID']
    userId: Scalars['String']
}

export type MutationUpdateDealRemarksArgs = {
    id: Scalars['ID']
    remarks: Scalars['String']
}

export type MutationUpdateDealOptionsDescriptionArgs = {
    id: Scalars['ID']
    optionsDescription: Scalars['String']
}

export type MutationUpdateDealLocaleArgs = {
    id: Scalars['ID']
    locale: DealLocale
}

export type MutationUploadDocumentArgs = {
    input: DocumentInput
}

export type MutationRenameDocumentArgs = {
    id: Scalars['ID']
    input: RenameDocumentInput
}

export type MutationDeleteDocumentArgs = {
    id: Scalars['ID']
}

export type MutationUpsertEmailTemplateArgs = {
    id?: Maybe<Scalars['ID']>
    input: EmailTemplateInput
}

export type MutationRemoveEmailTemplateArgs = {
    id: Scalars['ID']
}

export type MutationSendTestEmailArgs = {
    id: Scalars['ID']
    recipientEmailAddress: Scalars['String']
}

export type MutationEditCheckResultArgs = {
    userId: Scalars['ID']
}

export type MutationDealToLeasewiseArgs = {
    dealId: Scalars['ID']
}

export type MutationCreateAutodiskModelArgs = {
    input?: Maybe<AutodiskModelInput>
}

export type MutationUpdateAutodiskModelArgs = {
    id: Scalars['ID']
    input?: Maybe<AutodiskModelInput>
}

export type MutationDeleteAutodiskModelArgs = {
    id: Scalars['ID']
}

export type MutationCreateAutodiskEditionArgs = {
    input?: Maybe<AutodiskEditionInput>
}

export type MutationUpdateAutodiskEditionArgs = {
    id: Scalars['ID']
    input?: Maybe<AutodiskEditionInput>
}

export type MutationDeleteAutodiskEditionArgs = {
    id: Scalars['ID']
}

export type MutationSignLegalDocumentAgreementArgs = {
    documentId?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
    variables?: Maybe<Array<Maybe<LegalDocumentAgreementVariableInput>>>
    contentId?: Maybe<Scalars['String']>
    hash: Scalars['String']
}

export type MutationCreateLegalDocumentContentArgs = {
    legalDocumentId: Scalars['ID']
    title: Scalars['String']
    body: Scalars['String']
    agreement: Scalars['String']
    summary: Scalars['String']
}

export type MutationUpdateLegalDocumentContentArgs = {
    id: Scalars['ID']
    title: Scalars['String']
    body: Scalars['String']
    agreement: Scalars['String']
    summary: Scalars['String']
}

export type MutationDeleteLegalDocumentContentArgs = {
    id: Scalars['ID']
}

export type MutationPublishLegalDocumentContentArgs = {
    id: Scalars['ID']
}

export type MutationArchiveLegalDocumentContentArgs = {
    id: Scalars['ID']
}

export type MutationCreateLegalDocumentArgs = {
    name: Scalars['String']
    slug: Scalars['String']
    locale: Scalars['String']
}

export type MutationUpdateLegalDocumentArgs = {
    id: Scalars['ID']
    name: Scalars['String']
    slug: Scalars['String']
    locale: Scalars['String']
}

export type MutationArchiveLegalDocumentArgs = {
    id: Scalars['ID']
}

export type MutationUpdateTenantSettingsArgs = {
    id: Scalars['ID']
    input: TenantSettingsInput
}

export type MutationCreateUserProfileArgs = {
    firstName?: Maybe<Scalars['String']>
    legalFirstNames?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    telephoneNumber?: Maybe<Scalars['String']>
    address?: Maybe<UserAddressInput>
    userId?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['String']>
}

export type MutationUpdateUserProfileArgs = {
    id: Scalars['ID']
    firstName?: Maybe<Scalars['String']>
    legalFirstNames?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    telephoneNumber?: Maybe<Scalars['String']>
    address?: Maybe<UserAddressInput>
    userId?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['String']>
}

export type MutationUpdateMyUserProfileArgs = {
    firstName?: Maybe<Scalars['String']>
    legalFirstNames?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    telephoneNumber?: Maybe<Scalars['String']>
    address?: Maybe<UserAddressInput>
    emailAddress?: Maybe<Scalars['String']>
}

export type MutationDeleteUserProfileArgs = {
    id?: Maybe<Scalars['ID']>
    userId?: Maybe<Scalars['String']>
}

export type MutationUpsertWorkflowArgs = {
    id?: Maybe<Scalars['ID']>
    input: WorkflowInput
}

export type MutationRemoveWorkflowArgs = {
    id: Scalars['ID']
}

export type NewConfigurationEditionOptionInput = {
    optionId: Scalars['String']
    editionId: Scalars['String']
    fiscalValue?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
    type?: Maybe<ConfigurationEditionOptionType>
    price?: Maybe<Scalars['Float']>
}

export type OnfidoSdkToken = {
    __typename?: 'OnfidoSDKToken'
    token: Scalars['String']
}

export type Option = {
    __typename?: 'Option'
    id: Scalars['ID']
    type?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    manufacturerCode?: Maybe<Scalars['String']>
    displayOrder: Scalars['Int']
}

export type OptionPrice = {
    __typename?: 'OptionPrice'
    option?: Maybe<Scalars['String']>
    price?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
}

export type OrderBy = {
    field?: Maybe<Scalars['String']>
    asc?: Maybe<Scalars['Boolean']>
}

export type PaginatedResult = {
    totalResults?: Maybe<Scalars['Int']>
}

export type Pagination = {
    offset?: Maybe<Scalars['Int']>
    limit?: Maybe<Scalars['Int']>
}

export enum PaymentStatus {
    NotStarted = 'NOT_STARTED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
}

export type PaymentSummary = {
    __typename?: 'PaymentSummary'
    id: Scalars['ID']
    createdAt: Scalars['Date']
    updatedAt: Scalars['Date']
    expiredAmount?: Maybe<Scalars['Float']>
    paymentScore?: Maybe<Scalars['Float']>
    invoicesPaidTooLate?: Maybe<Scalars['Int']>
}

export type PdfMetadata = {
    __typename?: 'PdfMetadata'
    sizeInBytes?: Maybe<Scalars['Int']>
    pages?: Maybe<Scalars['Int']>
    pdfVersion?: Maybe<Scalars['String']>
}

export type PremiumConnectivityProposition = {
    __typename?: 'PremiumConnectivityProposition'
    id: Scalars['ID']
    oldPricePerMonthExVAT: Scalars['Float']
    newPricePerMonthExVAT: Scalars['Float']
    priceIncreaseExVAT: Scalars['Float']
    oldPricePerMonthIncVAT: Scalars['Float']
    newPricePerMonthIncVAT: Scalars['Float']
    priceIncreaseIncVAT: Scalars['Float']
}

export type Pricing = {
    __typename?: 'Pricing'
    id: Scalars['ID']
    pricePerMonthExVAT?: Maybe<Scalars['Float']>
    pricePerMonthIncVAT?: Maybe<Scalars['Float']>
    incVAT?: Maybe<Scalars['Boolean']>
    fiscalValue?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    margin?: Maybe<Scalars['Float']>
    /** undefined Only available with roles ADMIN */
    components?: Maybe<Array<Maybe<PricingCalculationComponent>>>
    lessKmPriceExVAT: Scalars['Float']
    moreKmPriceExVAT: Scalars['Float']
    privateLeaseLessKmPriceIncVAT: Scalars['Float']
    privateLeaseMoreKmPriceIncVAT: Scalars['Float']
}

export type PricingCalculationComponent =
    | PricingCalculationComponentMargin
    | PricingCalculationComponentInterest
    | PricingCalculationComponentInsurance
    | PricingCalculationComponentDepreciation
    | PricingCalculationComponentMaintenance
    | PricingCalculationComponentReplacementTransport
    | PricingCalculationComponentCustomMonthlyLeasePriceAddOn
    | PricingCalculationComponentCustomResidualValue
    | PricingCalculationComponentTire
    | PricingCalculationComponentAfterDelivery
    | PricingCalculationComponentLeaseEditionOption
    | PricingCalculationComponentDownpayment
    | PricingCalculationReferralDiscount

export type PricingCalculationComponentAfterDelivery = {
    __typename?: 'PricingCalculationComponentAfterDelivery'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentAfterDelivery>
}

export type PricingCalculationComponentCustomMonthlyLeasePriceAddOn = {
    __typename?: 'PricingCalculationComponentCustomMonthlyLeasePriceAddOn'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentCustomMonthlyLeasePriceAddOn>
}

export type PricingCalculationComponentCustomResidualValue = {
    __typename?: 'PricingCalculationComponentCustomResidualValue'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentCustomResidualValue>
}

export type PricingCalculationComponentDepreciation = {
    __typename?: 'PricingCalculationComponentDepreciation'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentDepreciationPercentage>
}

export type PricingCalculationComponentDownpayment = {
    __typename?: 'PricingCalculationComponentDownpayment'
    discountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentDownpayment>
}

export type PricingCalculationComponentInsurance = {
    __typename?: 'PricingCalculationComponentInsurance'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentInsurance>
}

export type PricingCalculationComponentInterest = {
    __typename?: 'PricingCalculationComponentInterest'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentInterest>
}

export type PricingCalculationComponentLeaseEditionOption = {
    __typename?: 'PricingCalculationComponentLeaseEditionOption'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentLeaseEditionOption>
}

export type PricingCalculationComponentMaintenance = {
    __typename?: 'PricingCalculationComponentMaintenance'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentMaintenance>
}

export type PricingCalculationComponentMargin = {
    __typename?: 'PricingCalculationComponentMargin'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentMargin>
}

export type PricingCalculationComponentReplacementTransport = {
    __typename?: 'PricingCalculationComponentReplacementTransport'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentReplacementTransport>
}

export type PricingCalculationComponentTire = {
    __typename?: 'PricingCalculationComponentTire'
    amountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentTire>
}

export enum PricingCalculationComponentType {
    Margin = 'MARGIN',
    ExactMargin = 'EXACT_MARGIN',
    InterestRate = 'INTEREST_RATE',
    Insurance = 'INSURANCE',
    Maintenance = 'MAINTENANCE',
    ReplacementTransportation = 'REPLACEMENT_TRANSPORTATION',
    CustomMonthlyAddOn = 'CUSTOM_MONTHLY_ADD_ON',
    CustomResidualValue = 'CUSTOM_RESIDUAL_VALUE',
    ResidualValueTable = 'RESIDUAL_VALUE_TABLE',
    AfterDeliveryOption = 'AFTER_DELIVERY_OPTION',
    LeaseEditionOption = 'LEASE_EDITION_OPTION',
    TireOption = 'TIRE_OPTION',
    Downpayment = 'DOWNPAYMENT',
    ReferralDiscount = 'REFERRAL_DISCOUNT',
}

export type PricingCalculationReferralDiscount = {
    __typename?: 'PricingCalculationReferralDiscount'
    discountExVAT: Scalars['Float']
    component?: Maybe<PricingConfigurationComponentReferralDiscount>
}

export type PricingCondition = {
    __typename?: 'PricingCondition'
    AND?: Maybe<Array<Maybe<PricingCondition>>>
    OR?: Maybe<Array<Maybe<PricingCondition>>>
    condition?: Maybe<PricingConditionType>
}

export type PricingConditionBlock = {
    __typename?: 'PricingConditionBlock'
    conditions?: Maybe<PricingCondition>
    components: Array<PricingConfigurationComponent>
}

export type PricingConditionBlockInput = {
    conditions?: Maybe<PricingConditionInput>
    components: Array<PricingConfigurationComponentInput>
}

export type PricingConditionCarOption = {
    __typename?: 'PricingConditionCarOption'
    property: PricingConditionProperty
    operator: PricingConditionOperator
    optionId: Scalars['String']
}

export type PricingConditionFiscalValue = {
    __typename?: 'PricingConditionFiscalValue'
    property: PricingConditionProperty
    operator: PricingConditionOperator
    fiscalValue: Scalars['Float']
}

export type PricingConditionInput = {
    AND?: Maybe<Array<Maybe<PricingConditionInput>>>
    OR?: Maybe<Array<Maybe<PricingConditionInput>>>
    leasingTypeCondition?: Maybe<PricingConditionInputLeasingType>
    fiscalValueCondition?: Maybe<PricingConditionInputFiscalValue>
    carOptionsCondition?: Maybe<PricingConditionInputCarOptions>
}

export type PricingConditionInputCarOptions = {
    property: PricingConditionProperty
    operator: PricingConditionOperator
    optionId: Scalars['String']
}

export type PricingConditionInputFiscalValue = {
    property: PricingConditionProperty
    operator: PricingConditionOperator
    fiscalValue: Scalars['Float']
}

export type PricingConditionInputLeasingType = {
    property: PricingConditionProperty
    operator: PricingConditionOperator
    leasingType: LeasingType
}

export type PricingConditionLeasingType = {
    __typename?: 'PricingConditionLeasingType'
    property: PricingConditionProperty
    operator: PricingConditionOperator
    leasingType: LeasingType
}

export enum PricingConditionOperator {
    IsEqualTo = 'IS_EQUAL_TO',
    IsLessThan = 'IS_LESS_THAN',
    IsGreaterThan = 'IS_GREATER_THAN',
    IsNotEqualTo = 'IS_NOT_EQUAL_TO',
    Includes = 'INCLUDES',
    DoesNotInclude = 'DOES_NOT_INCLUDE',
}

export enum PricingConditionProperty {
    LeasingType = 'LEASING_TYPE',
    FiscalValue = 'FISCAL_VALUE',
    CarOptions = 'CAR_OPTIONS',
}

export type PricingConditionType = PricingConditionLeasingType | PricingConditionFiscalValue | PricingConditionCarOption

export type PricingConfigurationCalculationFilter = {
    AND?: Maybe<Array<Maybe<PricingConfigurationCalculationFilter>>>
    OR?: Maybe<Array<Maybe<PricingConfigurationCalculationFilter>>>
}

export type PricingConfigurationCalculationInput = {
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    components: Array<PricingConfigurationComponentInput>
}

export type PricingConfigurationComponent =
    | PricingConfigurationComponentMargin
    | PricingConfigurationComponentInterest
    | PricingConfigurationComponentInsurance
    | PricingConfigurationComponentDepreciation
    | PricingConfigurationComponentMaintenance
    | PricingConfigurationComponentReplacementTransport
    | PricingConfigurationComponentCustomMonthlyLeasePriceAddOn
    | PricingConfigurationComponentCustomResidualValue
    | PricingConfigurationComponentConditional

export type PricingConfigurationComponentAfterDelivery = {
    __typename?: 'PricingConfigurationComponentAfterDelivery'
    type: PricingCalculationComponentType
}

export type PricingConfigurationComponentConditional = {
    __typename?: 'PricingConfigurationComponentConditional'
    type?: Maybe<PricingConfigurationComponentType>
    conditionBlocks: Array<PricingConditionBlock>
    componentsDefault: Array<PricingConfigurationComponent>
}

export type PricingConfigurationComponentConditionalInput = {
    conditionBlocks?: Maybe<Array<Maybe<PricingConditionBlockInput>>>
    componentsDefault: Array<PricingConfigurationComponentInput>
}

export type PricingConfigurationComponentCustomMonthlyLeasePriceAddOn = {
    __typename?: 'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn'
    type?: Maybe<PricingConfigurationComponentType>
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    monthlyAddOnAmountExVAT: Scalars['Float']
}

export type PricingConfigurationComponentCustomMonthlyLeasePriceAddOnInput = {
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    monthlyAddOnAmountExVAT: Scalars['Float']
}

export type PricingConfigurationComponentCustomResidualValue = {
    __typename?: 'PricingConfigurationComponentCustomResidualValue'
    type?: Maybe<PricingConfigurationComponentType>
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    fiscalValue: Scalars['Float']
    residualValuePercentage: Scalars['Float']
    partOfInvestment: Scalars['Boolean']
}

export type PricingConfigurationComponentCustomResidualValueInput = {
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    partOfInvestment: Scalars['Boolean']
    fiscalValue: Scalars['Float']
    residualValuePercentage: Scalars['Float']
}

export type PricingConfigurationComponentDepreciation = {
    __typename?: 'PricingConfigurationComponentDepreciation'
    type?: Maybe<PricingConfigurationComponentType>
    residualValueTable?: Maybe<ResidualValueTable>
}

export type PricingConfigurationComponentDepreciationPercentage = {
    __typename?: 'PricingConfigurationComponentDepreciationPercentage'
    type?: Maybe<PricingConfigurationComponentType>
    residualValuePercentage: Scalars['Float']
    currentValueExVAT: Scalars['Float']
    residualValue: Scalars['Float']
    residualValueTableId: Scalars['String']
}

export type PricingConfigurationComponentDownpayment = {
    __typename?: 'PricingConfigurationComponentDownpayment'
    downpaymentAmount: Scalars['Float']
    type: PricingCalculationComponentType
}

export type PricingConfigurationComponentHull = {
    __typename?: 'PricingConfigurationComponentHull'
    startingAtFiscalValue?: Maybe<Scalars['Float']>
    endingAtFiscalValue?: Maybe<Scalars['Float']>
    amountExVATPerStep: Scalars['Float']
    perStepInFiscalValue: Scalars['Float']
}

export type PricingConfigurationComponentHullInput = {
    startingAtFiscalValue: Scalars['Float']
    endingAtFiscalValue: Scalars['Float']
    amountExVATPerStep: Scalars['Float']
    perStepInFiscalValue: Scalars['Float']
}

export type PricingConfigurationComponentInput = {
    type: PricingConfigurationComponentType
    margin?: Maybe<PricingConfigurationComponentMarginInput>
    interestRate?: Maybe<PricingConfigurationComponentInterestInput>
    insuranceCalculation?: Maybe<PricingConfigurationComponentInsuranceInput>
    maintenanceCalculation?: Maybe<PricingConfigurationComponentMaintenanceCalculationInput>
    replacementTransportCalculation?: Maybe<PricingConfigurationComponentReplacementTransportInput>
    customMonthlyAddOnCalculation?: Maybe<PricingConfigurationComponentCustomMonthlyLeasePriceAddOnInput>
    customResidualValueCalculation?: Maybe<PricingConfigurationComponentCustomResidualValueInput>
    residualValueTable?: Maybe<PricingConfigurationComponentResidualValueTableInput>
    conditional?: Maybe<PricingConfigurationComponentConditionalInput>
}

export type PricingConfigurationComponentInsurance = {
    __typename?: 'PricingConfigurationComponentInsurance'
    type?: Maybe<PricingConfigurationComponentType>
    liability: Scalars['Float']
    hull: PricingConfigurationComponentHull
    insurancePremiumTaxPercentage: Scalars['Float']
    insuranceMarkupPercentage: Scalars['Float']
    deductible?: Maybe<PricingConfigurationComponentInsuranceDeductible>
}

export type PricingConfigurationComponentInsuranceDeductible = {
    __typename?: 'PricingConfigurationComponentInsuranceDeductible'
    higher?: Maybe<Scalars['Float']>
    regular?: Maybe<Scalars['Float']>
    lower?: Maybe<Scalars['Float']>
}

export type PricingConfigurationComponentInsuranceInput = {
    liability: Scalars['Float']
    hull: PricingConfigurationComponentHullInput
    insurancePremiumTaxPercentage: Scalars['Float']
    insuranceMarkupPercentage: Scalars['Float']
    deductible: PricingConfigurationComponentInsuranceInputDeductible
}

export type PricingConfigurationComponentInsuranceInputDeductible = {
    higher: Scalars['Float']
    regular: Scalars['Float']
    lower: Scalars['Float']
}

export type PricingConfigurationComponentInterest = {
    __typename?: 'PricingConfigurationComponentInterest'
    type?: Maybe<PricingConfigurationComponentType>
    investmentExVAT: Scalars['Float']
    percentage: Scalars['Float']
}

export type PricingConfigurationComponentInterestInput = {
    percentage: Scalars['Float']
}

export type PricingConfigurationComponentLeaseEditionOption = {
    __typename?: 'PricingConfigurationComponentLeaseEditionOption'
    type: PricingCalculationComponentType
}

export type PricingConfigurationComponentMaintenance = {
    __typename?: 'PricingConfigurationComponentMaintenance'
    type?: Maybe<PricingConfigurationComponentType>
    amountExVATPerDistanceUnit: Scalars['Float']
}

export type PricingConfigurationComponentMaintenanceCalculationInput = {
    amountExVATPerDistanceUnit: Scalars['Float']
}

export type PricingConfigurationComponentMargin = {
    __typename?: 'PricingConfigurationComponentMargin'
    type?: Maybe<PricingConfigurationComponentType>
    percentage: Scalars['Float']
}

export type PricingConfigurationComponentMarginInput = {
    percentage: Scalars['Float']
}

export type PricingConfigurationComponentReferralDiscount = {
    __typename?: 'PricingConfigurationComponentReferralDiscount'
    type: PricingCalculationComponentType
    referralProgram: Scalars['String']
    referralCode: Scalars['String']
}

export type PricingConfigurationComponentReplacementTransport = {
    __typename?: 'PricingConfigurationComponentReplacementTransport'
    type?: Maybe<PricingConfigurationComponentType>
    amountExVATUntilCutOff: Scalars['Float']
    cutOffMileage: Scalars['Int']
    perMileageStepAboveCutOff: Scalars['Int']
    amountExVATPerStepAboveCutOff: Scalars['Float']
}

export type PricingConfigurationComponentReplacementTransportInput = {
    amountExVATUntilCutOff: Scalars['Float']
    cutOffMileage: Scalars['Int']
    perMileageStepAboveCutOff: Scalars['Int']
    amountExVATPerStepAboveCutOff: Scalars['Float']
}

export type PricingConfigurationComponentResidualValueTableInput = {
    residualValueTableId: Scalars['String']
}

export type PricingConfigurationComponentTire = {
    __typename?: 'PricingConfigurationComponentTire'
    type: PricingCalculationComponentType
    tirePricePerDistanceUnitExVAT: Scalars['Float']
}

export enum PricingConfigurationComponentType {
    Margin = 'MARGIN',
    InterestRate = 'INTEREST_RATE',
    Insurance = 'INSURANCE',
    Maintenance = 'MAINTENANCE',
    ReplacementTransportation = 'REPLACEMENT_TRANSPORTATION',
    CustomMonthlyAddOn = 'CUSTOM_MONTHLY_ADD_ON',
    CustomResidualValue = 'CUSTOM_RESIDUAL_VALUE',
    ResidualValueTable = 'RESIDUAL_VALUE_TABLE',
    Conditional = 'CONDITIONAL',
}

export type PricingPreview = {
    __typename?: 'PricingPreview'
    leasingPriceExVAT: Scalars['Float']
    leasingPriceIncVAT: Scalars['Float']
    fiscalValue: Scalars['Float']
    components?: Maybe<Array<Maybe<PricingCalculationComponent>>>
}

export type PricingPreviewInput = {
    editionId: Scalars['ID']
    mileage: Scalars['Int']
    duration: Scalars['Int']
    leasingType: LeasingType
    components: Array<PricingConfigurationComponentInput>
}

export type Proposition = {
    __typename?: 'Proposition'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    type: PropositionType
    state: PropositionState
    pdfUrl?: Maybe<Scalars['String']>
}

export enum PropositionPaymentMethod {
    Invoice = 'INVOICE',
    LeaseIncrease = 'LEASE_INCREASE',
}

export enum PropositionState {
    Created = 'created',
    Draft = 'draft',
    Pending = 'pending',
    Completed = 'completed',
    Expired = 'expired',
    Declined = 'declined',
}

export enum PropositionType {
    EndContractProposition = 'EndContractProposition',
    UpgradeProposition = 'UpgradeProposition',
    ContractRecalculation = 'ContractRecalculation',
    FsdProposition = 'FSDProposition',
    AccessoryProposition = 'AccessoryProposition',
    PremiumConnectivityProposition = 'PremiumConnectivityProposition',
}

export type PublicationSuccessfullyUnpublished = {
    __typename?: 'PublicationSuccessfullyUnpublished'
    publicationId: Scalars['String']
}

export type Query = {
    __typename?: 'Query'
    LookupAddress?: Maybe<LookupAddress>
    /** undefined Only available with roles ADMIN */
    isRootToken?: Maybe<Scalars['Boolean']>
    /** undefined Only available when authenticated. */
    getCurrentUser?: Maybe<User>
    /** undefined Only available when authenticated. */
    myUser?: Maybe<User>
    /** undefined Only available with roles ADMIN */
    allUsers?: Maybe<Array<Maybe<User>>>
    /** undefined Only available with roles ADMIN */
    User?: Maybe<User>
    validateSignupUser?: Maybe<Scalars['Boolean']>
    /** undefined Only available with roles ADMIN */
    getTenants?: Maybe<Array<Maybe<Tenant>>>
    /** undefined Only available with roles ADMIN */
    getUsersByRole?: Maybe<Array<Maybe<User>>>
    /** undefined Only available with roles ADMIN */
    Car: CarResult
    /** undefined Only available with roles ADMIN */
    CarCollection: CarCollection
    /** undefined Only available with roles ADMIN */
    RemarketingPublicationCollection: RemarketingPublicationCollection
    /** undefined Only available with roles ADMIN */
    RemarketingPricingPreview: RemarketingPricingPreview
    /** undefined Only available with roles ADMIN */
    allTeslaOptions: Array<TeslaOption>
    /** undefined Only available with roles ADMIN */
    allRemarketingChannels: Array<RemarketingChannel>
    /** undefined Only available when authenticated. */
    searchCompany?: Maybe<Array<Maybe<CompanySearchResult>>>
    /** undefined Only available when authenticated. */
    Company?: Maybe<Company>
    /** undefined Only available when authenticated. */
    CompanyBranch?: Maybe<CompanyBranch>
    Brand?: Maybe<ConfigurationBrandResult>
    Brands?: Maybe<Array<Maybe<ConfigurationBrandResult>>>
    ConfigurationModel?: Maybe<ConfigurationModelResult>
    ConfigurationModels?: Maybe<Array<Maybe<ConfigurationModelResult>>>
    ConfigurationEdition?: Maybe<ConfigurationEditionResult>
    ConfigurationEditions?: Maybe<Array<Maybe<ConfigurationEditionResult>>>
    ValidateEdition?: Maybe<EditionValidation>
    /** undefined Only available with roles ADMIN */
    EditionOption?: Maybe<ConfigurationEditionOptionResult>
    /** undefined Only available with roles ADMIN */
    EditionOptionsByEditionId?: Maybe<Array<Maybe<EditionOption>>>
    Option?: Maybe<ConfigurationOptionResult>
    Options?: Maybe<Array<Maybe<ConfigurationOptionResult>>>
    /** undefined Only available with roles ADMIN */
    ResidualValueTable?: Maybe<ResidualValueTableResult>
    /** undefined Only available with roles ADMIN */
    allResidualValueTables?: Maybe<Array<Maybe<ResidualValueTableResult>>>
    Accessories?: Maybe<Array<Maybe<Accessory>>>
    /** undefined Only available with roles ADMIN */
    PricingConfigurationCalculation?: Maybe<ConfigurationCalculationResult>
    /** undefined Only available with roles ADMIN */
    PricingConfigurationCalculationCollection?: Maybe<ConfigurationCalculationCollection>
    /** undefined Only available with roles ADMIN */
    PricingPreview?: Maybe<PricingPreview>
    /** undefined Only available when authenticated. */
    Contract?: Maybe<Contract>
    /** undefined Only available with roles ADMIN */
    ContractCollection?: Maybe<ContractCollection>
    /** undefined Only available when authenticated. */
    myContracts?: Maybe<Array<Maybe<Contract>>>
    /** undefined Only available with roles ADMIN */
    ContractRecalculation?: Maybe<ContractRecalculation>
    /** undefined Only available with roles ADMIN */
    EndContractProposition?: Maybe<EndContractProposition>
    /** undefined Only available with roles ADMIN */
    UpgradeProposition?: Maybe<UpgradeProposition>
    /** undefined Only available with roles ADMIN */
    AccessoryProposition?: Maybe<AccessoryProposition>
    findReportingcode: ReportingCodeResult
    Deal: Deal
    DealByShopCarId: Deal
    DealByModelId: Deal
    /** undefined Only available when authenticated. */
    DealCollection?: Maybe<DealCollection>
    BanksAvailableForIdeal: Array<Bank>
    DealContractPdf: DealContractPdf
    Signature?: Maybe<Signature>
    generateCoverPage: Scalars['String']
    /** undefined Only available with roles ADMIN */
    Document?: Maybe<Document>
    /** undefined Only available with roles ADMIN */
    DocumentCollection?: Maybe<DocumentCollection>
    /** undefined Only available with roles ADMIN */
    EmailTemplateCollection?: Maybe<EmailTemplateCollection>
    /** undefined Only available with roles ADMIN */
    EmailTemplate?: Maybe<EmailTemplate>
    EventCollection?: Maybe<EventCollection>
    /** undefined Only available when authenticated. */
    getOnfidoSDKToken?: Maybe<OnfidoSdkToken>
    /** undefined Only available when authenticated. */
    getIdCheckStatus?: Maybe<IdCheckStatus>
    /** undefined Only available with roles ADMIN */
    getIdCheckStatusByUserId?: Maybe<IdCheckStatus>
    /** undefined Only available with roles ADMIN */
    getOnfidoReport?: Maybe<Scalars['String']>
    /** undefined Only available with roles ADMIN */
    LeasewiseOfferStatus?: Maybe<LeasewiseOfferStatus>
    /** undefined Only available with roles ADMIN */
    AutodiskModel?: Maybe<AutodiskModel>
    /** undefined Only available with roles ADMIN */
    AutodiskEdition?: Maybe<AutodiskEdition>
    /** undefined Only available with roles ADMIN */
    AutodiskEditionByConfigurationEditionId?: Maybe<AutodiskEdition>
    /** undefined Only available with roles ADMIN */
    AutodiskModelByConfigurationModelId?: Maybe<AutodiskModel>
    /** undefined Only available when authenticated. */
    MyLegalDocumentAgreements: Array<Maybe<LegalDocumentAgreement>>
    /** undefined Only available with roles ADMIN */
    LegalDocumentAgreements: Array<Maybe<LegalDocumentAgreement>>
    /** undefined Only available with roles ADMIN */
    LegalDocumentAgreement: LegalDocumentAgreement
    /** undefined Only available when authenticated. */
    createLegalDocumentAgreementTemplate?: Maybe<LegalDocumentAgreementTemplate>
    /** undefined Only available with roles ADMIN */
    LegalDocumentContent?: Maybe<LegalDocumentContent>
    getLegalDocumentContent?: Maybe<LegalDocumentContent>
    /** undefined Only available with roles ADMIN */
    getAllLegalDocumentContent?: Maybe<Array<Maybe<LegalDocumentContent>>>
    LegalDocument?: Maybe<LegalDocument>
    getAllLegalDocuments: Array<Maybe<LegalDocument>>
    TenantSettings?: Maybe<TenantSettings>
    UserProfile?: Maybe<UserProfile>
    /** undefined Only available when authenticated. */
    myUserProfile?: Maybe<UserProfile>
    /** undefined Only available with roles ADMIN */
    allUserProfiles?: Maybe<AllUserProfilesResult>
    FirstName: Scalars['String']
    /** undefined Only available with roles ADMIN,ACCOUNTMANAGER */
    getAccountManagers?: Maybe<Array<Maybe<UserProfile>>>
    /** undefined Only available with roles ADMIN */
    WorkflowCollection?: Maybe<WorkflowCollection>
    /** undefined Only available with roles ADMIN */
    Workflow?: Maybe<Workflow>
    /** undefined Only available with roles ADMIN */
    allExposedEntities?: Maybe<Array<Maybe<Entity>>>
    /** undefined Only available with roles ADMIN */
    allReceiverEmailOptions?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryLookupAddressArgs = {
    input: LookupAddressInput
}

export type QueryIsRootTokenArgs = {
    token: Scalars['String']
}

export type QueryUserArgs = {
    emailAddress?: Maybe<Scalars['String']>
    id?: Maybe<Scalars['ID']>
}

export type QueryValidateSignupUserArgs = {
    emailAddress?: Maybe<Scalars['String']>
}

export type QueryGetUsersByRoleArgs = {
    role: Role
}

export type QueryCarArgs = {
    id: Scalars['ID']
}

export type QueryCarCollectionArgs = {
    filter?: Maybe<CarFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryRemarketingPublicationCollectionArgs = {
    filter?: Maybe<RemarketingPublicationFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryRemarketingPricingPreviewArgs = {
    carId: Scalars['ID']
    pricingConfigurationId?: Maybe<Scalars['String']>
}

export type QuerySearchCompanyArgs = {
    nameOrChamberOfCommerceNr: Scalars['String']
}

export type QueryCompanyArgs = {
    chamberOfCommerceNr: Scalars['String']
}

export type QueryCompanyBranchArgs = {
    branchNr: Scalars['String']
}

export type QueryBrandArgs = {
    id?: Maybe<Scalars['ID']>
    name?: Maybe<Scalars['String']>
}

export type QueryConfigurationModelArgs = {
    id?: Maybe<Scalars['ID']>
    name?: Maybe<Scalars['String']>
    brandName?: Maybe<Scalars['String']>
}

export type QueryConfigurationEditionArgs = {
    id?: Maybe<Scalars['ID']>
    name?: Maybe<Scalars['String']>
    modelName?: Maybe<Scalars['String']>
}

export type QueryConfigurationEditionsArgs = {
    modelId: Scalars['ID']
}

export type QueryValidateEditionArgs = {
    editionId: Scalars['ID']
    optionIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryEditionOptionArgs = {
    id: Scalars['ID']
}

export type QueryEditionOptionsByEditionIdArgs = {
    editionId: Scalars['ID']
}

export type QueryOptionArgs = {
    id: Scalars['ID']
}

export type QueryResidualValueTableArgs = {
    id: Scalars['ID']
}

export type QueryPricingConfigurationCalculationArgs = {
    id: Scalars['ID']
}

export type QueryPricingConfigurationCalculationCollectionArgs = {
    filter?: Maybe<PricingConfigurationCalculationFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryPricingPreviewArgs = {
    input?: Maybe<PricingPreviewInput>
}

export type QueryContractArgs = {
    id: Scalars['ID']
}

export type QueryContractCollectionArgs = {
    filter?: Maybe<ContractFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryContractRecalculationArgs = {
    id: Scalars['ID']
    newTotalMileage: Scalars['Int']
}

export type QueryEndContractPropositionArgs = {
    id: Scalars['ID']
    propositionDate: Scalars['Date']
}

export type QueryUpgradePropositionArgs = {
    id: Scalars['ID']
    propositionDate: Scalars['Date']
}

export type QueryAccessoryPropositionArgs = {
    id: Scalars['ID']
    accessory: Scalars['String']
}

export type QueryFindReportingcodeArgs = {
    licensePlateNumber: Scalars['String']
}

export type QueryDealArgs = {
    id: Scalars['ID']
    showAllEditions?: Maybe<Scalars['Boolean']>
}

export type QueryDealByShopCarIdArgs = {
    shopCarId: Scalars['ID']
}

export type QueryDealByModelIdArgs = {
    modelId: Scalars['String']
}

export type QueryDealCollectionArgs = {
    filter?: Maybe<DealFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryDealContractPdfArgs = {
    dealId: Scalars['ID']
}

export type QuerySignatureArgs = {
    dealId: Scalars['ID']
}

export type QueryGenerateCoverPageArgs = {
    dealId: Scalars['ID']
}

export type QueryDocumentArgs = {
    id: Scalars['ID']
}

export type QueryDocumentCollectionArgs = {
    filter?: Maybe<DocumentFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryEmailTemplateCollectionArgs = {
    filter?: Maybe<EmailTemplateFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryEmailTemplateArgs = {
    id: Scalars['ID']
}

export type QueryEventCollectionArgs = {
    filter?: Maybe<EventFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryGetIdCheckStatusByUserIdArgs = {
    userId?: Maybe<Scalars['ID']>
}

export type QueryGetOnfidoReportArgs = {
    checkId: Scalars['ID']
    reportId: Scalars['ID']
}

export type QueryLeasewiseOfferStatusArgs = {
    dealId: Scalars['ID']
}

export type QueryAutodiskModelArgs = {
    id: Scalars['ID']
}

export type QueryAutodiskEditionArgs = {
    id: Scalars['ID']
}

export type QueryAutodiskEditionByConfigurationEditionIdArgs = {
    configurationEditionId: Scalars['ID']
    entity: LessorEntity
}

export type QueryAutodiskModelByConfigurationModelIdArgs = {
    configurationModelId: Scalars['ID']
    entity: LessorEntity
}

export type QueryLegalDocumentAgreementsArgs = {
    userId?: Maybe<Scalars['String']>
}

export type QueryLegalDocumentAgreementArgs = {
    id?: Maybe<Scalars['ID']>
}

export type QueryCreateLegalDocumentAgreementTemplateArgs = {
    documentId?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
    variables?: Maybe<Array<Maybe<LegalDocumentAgreementVariableInput>>>
    contentId?: Maybe<Scalars['String']>
}

export type QueryLegalDocumentContentArgs = {
    id: Scalars['ID']
}

export type QueryGetLegalDocumentContentArgs = {
    legalDocumentId: Scalars['ID']
}

export type QueryGetAllLegalDocumentContentArgs = {
    legalDocumentId: Scalars['ID']
}

export type QueryLegalDocumentArgs = {
    id?: Maybe<Scalars['ID']>
    slug?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
}

export type QueryTenantSettingsArgs = {
    id: Scalars['ID']
}

export type QueryUserProfileArgs = {
    id?: Maybe<Scalars['ID']>
    userId?: Maybe<Scalars['ID']>
    emailAddress?: Maybe<Scalars['String']>
}

export type QueryAllUserProfilesArgs = {
    filter?: Maybe<UserProfileFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryFirstNameArgs = {
    referralCode: Scalars['String']
}

export type QueryWorkflowCollectionArgs = {
    filter?: Maybe<WorkflowFilterInput>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}

export type QueryWorkflowArgs = {
    id: Scalars['ID']
}

export type Ready2ShareValue = {
    __typename?: 'Ready2ShareValue'
    privateMileage: Scalars['Int']
    businessMileage: Scalars['Int']
    privatePrice: Scalars['Float']
    businessPrice: Scalars['Float']
}

export type ReferralProgram = {
    __typename?: 'ReferralProgram'
    id: Scalars['ID']
    name?: Maybe<Scalars['String']>
}

export type RemarketingChannel = {
    id: Scalars['ID']
}

export type RemarketingPricingPreview = {
    __typename?: 'RemarketingPricingPreview'
    pricingConfigurationId?: Maybe<Scalars['String']>
    purchasePriceExVAT: Scalars['Float']
    futureValues: Array<RemarketingValue>
    contractTakeoverValues?: Maybe<Array<RemarketingValue>>
    ready2ShareValues: Array<Ready2ShareValue>
}

export type RemarketingPublication = {
    id: Scalars['ID']
    createdAt: Scalars['Date']
    updatedAt: Scalars['Date']
    carId: Scalars['String']
    channel: RemarketingChannel
    isContractTakeover: Scalars['Boolean']
    pricingConfigurationId?: Maybe<Scalars['String']>
}

export type RemarketingPublicationCollection = PaginatedResult & {
    __typename?: 'RemarketingPublicationCollection'
    remarketingPublications: Array<RemarketingPublication>
    totalResults: Scalars['Int']
}

export type RemarketingPublicationFilter = {
    AND?: Maybe<Array<Maybe<RemarketingPublicationFilter>>>
    OR?: Maybe<Array<Maybe<RemarketingPublicationFilter>>>
    carId?: Maybe<Scalars['String']>
    locale?: Maybe<ShopLocale>
    offerType?: Maybe<ShopOfferType>
}

export type RemarketingValue = {
    __typename?: 'RemarketingValue'
    residualValue?: Maybe<Scalars['Float']>
    duration: Scalars['Int']
    mileage: Scalars['Int']
    leasePriceIncVAT: Scalars['Float']
    leasePriceExVAT: Scalars['Float']
}

export type RemoveCarResult = CarSuccessfullyRemoved | EntityNotFoundError | CarHasContractAttachedError

export enum RemoveConfigurationCalculationResult {
    Removed = 'REMOVED',
    AttachedToEdition = 'ATTACHED_TO_EDITION',
}

export type RenameDocumentInput = {
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
}

export type ReportingCodeResult = {
    __typename?: 'ReportingCodeResult'
    licensePlateNumber?: Maybe<Scalars['String']>
    reportingCode?: Maybe<Scalars['String']>
    brand?: Maybe<Scalars['String']>
    model?: Maybe<Scalars['String']>
    tires?: Maybe<Scalars['Boolean']>
    lastTireChangeBy?: Maybe<Scalars['String']>
    lastTireChangePlace?: Maybe<Scalars['String']>
    winterTires?: Maybe<Scalars['Boolean']>
    currentTire?: Maybe<Scalars['String']>
    lastTireChangeDate?: Maybe<Scalars['Date']>
    tireSizeFront?: Maybe<Scalars['String']>
    tireSizeRear?: Maybe<Scalars['String']>
    entity?: Maybe<Scalars['String']>
    ministryOfTransportDate?: Maybe<Scalars['Date']>
    endDate?: Maybe<Scalars['Date']>
}

export type ResidualValue = {
    __typename?: 'ResidualValue'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    ageInMonths: Scalars['Int']
    mileage: Scalars['Int']
    value: Scalars['Int']
}

export type ResidualValueInput = {
    mileage?: Maybe<Scalars['Int']>
    ageInMonths?: Maybe<Scalars['Int']>
    value?: Maybe<Scalars['Int']>
}

export type ResidualValueTable = {
    __typename?: 'ResidualValueTable'
    id: Scalars['ID']
    name?: Maybe<Scalars['String']>
    residualValues?: Maybe<Array<Maybe<ResidualValue>>>
    editions?: Maybe<Array<Maybe<ConfigurationEdition>>>
}

export type ResidualValueTableInput = {
    name?: Maybe<Scalars['String']>
    residualValues?: Maybe<Array<Maybe<ResidualValueInput>>>
}

export type ResidualValueTableResult = ResidualValueTable | EntityNotFoundError

export type Result = {
    __typename?: 'Result'
    id: Scalars['ID']
    result?: Maybe<Scalars['String']>
}

export type RimsOptionNeededError = ExpectedError & {
    __typename?: 'RimsOptionNeededError'
    errorCode: ErrorCode
}

export enum Role {
    User = 'USER',
    Admin = 'ADMIN',
    TenantAdmin = 'TENANT_ADMIN',
    Accountmanager = 'ACCOUNTMANAGER',
    Superadmin = 'SUPERADMIN',
}

export type SelectableDealOption = {
    __typename?: 'SelectableDealOption'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    key: Scalars['String']
    labelId: Scalars['String']
    value: Value
    selectableValues: Array<Value>
}

export enum ShopCarAvailability {
    Available = 'AVAILABLE',
    Reserved = 'RESERVED',
    NotAvailable = 'NOT_AVAILABLE',
}

export enum ShopLocale {
    NlNl = 'nl_NL',
    NlBe = 'nl_BE',
    FrBe = 'fr_BE',
    FrFr = 'fr_FR',
    DeDe = 'de_DE',
    EsEs = 'es_ES',
    PtPt = 'pt_PT',
    DaDk = 'da_DK',
}

export enum ShopOfferType {
    PrivateLease = 'PRIVATE_LEASE',
    Fol = 'FOL',
    Purchase = 'PURCHASE',
    Ready_2Share = 'READY_2_SHARE',
}

export type Signature = DocumentSignature | IdealSignature

export enum SignatureType {
    DocumentSignature = 'DOCUMENT_SIGNATURE',
    IdealSignature = 'IDEAL_SIGNATURE',
}

export enum SigningMethod {
    DocumentUpload = 'DOCUMENT_UPLOAD',
    Ideal = 'IDEAL',
}

export type SigningPerson = {
    __typename?: 'SigningPerson'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    userId: Scalars['ID']
    emailAddress?: Maybe<Scalars['EmailAddress']>
    signed: Scalars['Boolean']
    signingStatus: SigningStatus
    signature?: Maybe<Signature>
}

export enum SigningStatus {
    Signed = 'SIGNED',
    AwaitingSignature = 'AWAITING_SIGNATURE',
    AwaitingDocumentVerification = 'AWAITING_DOCUMENT_VERIFICATION',
    SignedDocumentRejected = 'SIGNED_DOCUMENT_REJECTED',
}

export type SlackMessage = {
    __typename?: 'SlackMessage'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    title: Scalars['String']
    content?: Maybe<Scalars['String']>
}

export type SlackMessageInput = {
    title: Scalars['String']
    content?: Maybe<Scalars['String']>
}

export enum Status {
    Configuration = 'CONFIGURATION',
    Offer = 'OFFER',
    ContractIncomplete = 'CONTRACT_INCOMPLETE',
    ContractComplete = 'CONTRACT_COMPLETE',
    ContractOrdered = 'CONTRACT_ORDERED',
    ContractCanceled = 'CONTRACT_CANCELED',
}

export type Tenant = {
    __typename?: 'Tenant'
    id: Scalars['ID']
    name: Scalars['String']
}

export type TenantSettings = {
    __typename?: 'TenantSettings'
    id: Scalars['String']
    tenant: Scalars['String']
    currencyCode: CurrencyCode
    enabledSigningMethods: Array<SigningMethod>
    downpaymentAmounts: Array<Scalars['Int']>
    downpaymentInterestPercentage: Scalars['Float']
    usedCarConfigurationCutOffMileage: Scalars['Int']
    dealLocales?: Maybe<Array<DealLocale>>
}

export type TenantSettingsInput = {
    currencyCode: CurrencyCode
    enabledSigningMethods: Array<SigningMethod>
    downpaymentAmounts: Array<Scalars['Int']>
    downpaymentInterestPercentage: Scalars['Float']
    usedCarConfigurationCutOffMileage: Scalars['Int']
    dealLocales?: Maybe<Array<DealLocale>>
}

export type TeslaOption = {
    __typename?: 'TeslaOption'
    id: Scalars['ID']
    createdAt: Scalars['Date']
    updatedAt: Scalars['Date']
    label: Scalars['String']
    manufacturerCode: Scalars['String']
    category?: Maybe<TeslaOptionCategory>
}

export enum TeslaOptionCategory {
    Autopilot = 'AUTOPILOT',
    Battery = 'BATTERY',
    Interior = 'INTERIOR',
    Paint = 'PAINT',
    Rims = 'RIMS',
    Seating = 'SEATING',
    Towhitch = 'TOWHITCH',
    Wintertires = 'WINTERTIRES',
    Other = 'OTHER',
    Premiumconnectivity = 'PREMIUMCONNECTIVITY',
    Steeringwheel = 'STEERINGWHEEL',
}

export enum Tire {
    Summer = 'SUMMER',
    Winter = 'WINTER',
}

export type UnpublishRemarketingPublicationResult = PublicationSuccessfullyUnpublished | EntityNotFoundError

export type UpgradeProposition = {
    __typename?: 'UpgradeProposition'
    id: Scalars['ID']
    oneTimeFeeExVAT: Scalars['Float']
    oneTimeFeeIncVAT: Scalars['Float']
}

export enum UploadTokenEntity {
    DealDocumentSignature = 'DEAL_DOCUMENT_SIGNATURE',
}

export type UpsertCarResult = Car | EntityNotFoundError | InvalidInputDataError

export type UpsertContractResult = Contract | EntityNotFoundError | InvalidInputDataError

export type User = {
    __typename?: 'User'
    id: Scalars['ID']
    email: Scalars['String']
    emailAddress: Scalars['String']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    roles?: Maybe<Array<Maybe<Role>>>
    tenants?: Maybe<Array<Maybe<Scalars['String']>>>
    profile?: Maybe<UserProfile>
    idCheckStatus?: Maybe<IdCheckStatus>
}

export type UserAddress = {
    __typename?: 'UserAddress'
    id: Scalars['ID']
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    street?: Maybe<Scalars['String']>
    houseNumber?: Maybe<Scalars['Int']>
    houseNumberSuffix?: Maybe<Scalars['String']>
    zipcode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    userProfile?: Maybe<UserProfile>
}

export type UserAddressInput = {
    street?: Maybe<Scalars['String']>
    houseNumber?: Maybe<Scalars['Int']>
    houseNumberSuffix?: Maybe<Scalars['String']>
    zipcode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    userProfile?: Maybe<UserProfileInput>
}

export type UserProfile = {
    __typename?: 'UserProfile'
    id: Scalars['ID']
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    address?: Maybe<UserAddress>
    firstName?: Maybe<Scalars['String']>
    legalFirstNames?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    telephoneNumber?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['String']>
    privacyPolicyId?: Maybe<Scalars['String']>
    privacyPolicyApprovedAt?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['String']>
    referralCode?: Maybe<Scalars['String']>
}

export type UserProfileFilter = {
    AND?: Maybe<Array<Maybe<UserProfileFilter>>>
    OR?: Maybe<Array<Maybe<UserProfileFilter>>>
    emailAddress?: Maybe<Scalars['String']>
    emailAddress_contains?: Maybe<Scalars['String']>
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    fullName?: Maybe<Scalars['String']>
    fullName_contains?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['String']>
    telephoneNumber?: Maybe<Scalars['String']>
    telephoneNumber_contains?: Maybe<Scalars['String']>
    role?: Maybe<Role>
}

export type UserProfileInput = {
    address?: Maybe<UserAddressInput>
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    telephoneNumber?: Maybe<Scalars['String']>
    emailAddress?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['String']>
}

export type Value = {
    __typename?: 'Value'
    id: Scalars['ID']
    label?: Maybe<Scalars['String']>
    labelId: Scalars['String']
    editionLabel?: Maybe<Scalars['String']>
}

export enum WebsiteLocale {
    En = 'en',
    EnGb = 'en_GB',
    Nl = 'nl',
    Fr = 'fr',
    NlNl = 'nl_NL',
    NlBe = 'nl_BE',
    FrBe = 'fr_BE',
    De = 'de',
    DeDe = 'de_DE',
    Pt = 'pt',
    PtPt = 'pt_PT',
    Es = 'es',
    EsEs = 'es_ES',
    Da = 'da',
    DaDk = 'da_DK',
}

export type WheelEditionOption = EditionOptionInterface & {
    __typename?: 'WheelEditionOption'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    labelId?: Maybe<Scalars['String']>
    fiscalValue?: Maybe<Scalars['Int']>
    default?: Maybe<Scalars['Boolean']>
    option?: Maybe<ConfigurationOption>
    edition?: Maybe<ConfigurationEdition>
    price?: Maybe<Scalars['Float']>
}

export type Workflow = {
    __typename?: 'Workflow'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    name?: Maybe<Scalars['String']>
    conditions: Array<WorkflowCondition>
    actions: Array<WorkflowAction>
}

export type WorkflowAction = WorkflowActionBranch | WorkflowActionEmail | WorkflowActionSlack

export type WorkflowActionBranch = {
    __typename?: 'WorkflowActionBranch'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    conditions: Array<WorkflowCondition>
    acceptActions: Array<WorkflowAction>
    rejectActions: Array<WorkflowAction>
}

export type WorkflowActionEmail = {
    __typename?: 'WorkflowActionEmail'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    emailTemplate: EmailTemplate
    senderEmail: Scalars['EmailAddress']
    senderName: Scalars['String']
    receiverEmail: Scalars['String']
}

export type WorkflowActionInput = {
    type: WorkflowActionType
    branchAction?: Maybe<WorkflowBranchActionInput>
    emailAction?: Maybe<WorkflowEmailActionInput>
    slackAction?: Maybe<WorkflowSlackActionInput>
}

export type WorkflowActionSlack = {
    __typename?: 'WorkflowActionSlack'
    id: Scalars['ID']
    createdAt?: Maybe<Scalars['Date']>
    updatedAt?: Maybe<Scalars['Date']>
    channel: Scalars['String']
    message: SlackMessage
}

export enum WorkflowActionType {
    Branch = 'BRANCH',
    Email = 'EMAIL',
    SlackNotification = 'SLACK_NOTIFICATION',
}

export type WorkflowBranchActionInput = {
    conditions: Array<WorkflowConditionInput>
    acceptActions: Array<WorkflowActionInput>
    rejectActions: Array<WorkflowActionInput>
}

export type WorkflowCollection = PaginatedResult & {
    __typename?: 'WorkflowCollection'
    workflows?: Maybe<Array<Maybe<Workflow>>>
    totalResults?: Maybe<Scalars['Int']>
}

export type WorkflowCondition = {
    __typename?: 'WorkflowCondition'
    entity?: Maybe<EntityType>
    requirements: Array<WorkflowRequirement>
}

export type WorkflowConditionInput = {
    entity?: Maybe<EntityType>
    requirements: Array<EntityRequirementInput>
}

export type WorkflowEmailActionInput = {
    emailTemplateId: Scalars['ID']
    senderEmail: Scalars['EmailAddress']
    senderName?: Maybe<Scalars['String']>
    receiverEmail?: Maybe<Scalars['String']>
}

export enum WorkflowEntityFilterType {
    AnyOf = 'ANY_OF',
    IsEqualTo = 'IS_EQUAL_TO',
}

export type WorkflowEntityRequirementFilter = {
    type: WorkflowEntityFilterType
    field?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type WorkflowEvent = {
    __typename?: 'WorkflowEvent'
    id: Scalars['ID']
    entities: Array<WorkflowEventEntity>
}

export type WorkflowEventEntity = {
    __typename?: 'WorkflowEventEntity'
    instanceId: Scalars['ID']
    type: EntityType
}

export type WorkflowEventTypeDefinition = {
    __typename?: 'WorkflowEventTypeDefinition'
    id: Scalars['ID']
    entity?: Maybe<Entity>
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
}

export type WorkflowFilter = {
    __typename?: 'WorkflowFilter'
    type: WorkflowEntityFilterType
    field?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type WorkflowFilterInput = {
    AND?: Maybe<Array<WorkflowFilterInput>>
    OR?: Maybe<Array<WorkflowFilterInput>>
}

export type WorkflowInput = {
    name: Scalars['String']
    conditions: Array<WorkflowConditionInput>
    actions: Array<WorkflowActionInput>
}

export type WorkflowRequirement = {
    __typename?: 'WorkflowRequirement'
    property: Scalars['String']
    filter: Array<WorkflowFilter>
}

export type WorkflowSlackActionInput = {
    channel: Scalars['String']
    message: SlackMessageInput
}

export type TenantCurrencyQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type TenantCurrencyQuery = { __typename?: 'Query' } & {
    TenantSettings?: Maybe<{ __typename?: 'TenantSettings' } & Pick<TenantSettings, 'id' | 'currencyCode'>>
}

export type GetTenantsQueryVariables = Exact<{ [key: string]: never }>

export type GetTenantsQuery = { __typename?: 'Query' } & {
    getTenants?: Maybe<Array<Maybe<{ __typename?: 'Tenant' } & Pick<Tenant, 'id' | 'name'>>>>
}

export type AllRemarketingChannelsQueryVariables = Exact<{ [key: string]: never }>

export type AllRemarketingChannelsQuery = { __typename?: 'Query' } & {
    allRemarketingChannels: Array<{ __typename?: 'CarShopChannel' } & Pick<CarShopChannel, 'locale' | 'id'>>
}

export type AllTeslaOptionsQueryVariables = Exact<{ [key: string]: never }>

export type AllTeslaOptionsQuery = { __typename?: 'Query' } & {
    allTeslaOptions: Array<
        { __typename?: 'TeslaOption' } & Pick<TeslaOption, 'id' | 'label' | 'manufacturerCode' | 'category'>
    >
}

export type CarCollectionQueryVariables = Exact<{
    filter?: Maybe<CarFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type CarCollectionQuery = { __typename?: 'Query' } & {
    CarCollection: { __typename?: 'CarCollection' } & Pick<CarCollection, 'totalResults'> & {
            cars: Array<
                { __typename?: 'Car' } & Pick<
                    Car,
                    'id' | 'VIN' | 'licensePlate' | 'brandName' | 'modelName' | 'editionName'
                >
            >
        }
}

export type CarRemarketingPublicationCollectionQueryVariables = Exact<{
    carId?: Maybe<Scalars['String']>
}>

export type CarRemarketingPublicationCollectionQuery = { __typename?: 'Query' } & {
    RemarketingPublicationCollection: { __typename?: 'RemarketingPublicationCollection' } & {
        remarketingPublications: Array<
            { __typename?: 'CarShopPublication' } & Pick<
                CarShopPublication,
                | 'locale'
                | 'offerTypes'
                | 'availability'
                | 'availabilityDate'
                | 'bannerLabel'
                | 'isContractTakeover'
                | 'id'
                | 'pricingConfigurationId'
            >
        >
    }
}

export type CarQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type CarQuery = { __typename?: 'Query' } & {
    Car:
        | ({ __typename?: 'Car' } & CarFragmentFragment)
        | ({ __typename?: 'EntityNotFoundError' } & Pick<EntityNotFoundError, 'errorCode'>)
}

export type CarFragmentFragment = { __typename?: 'Car' } & Pick<
    Car,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'VIN'
    | 'licensePlate'
    | 'firstRegistrationDate'
    | 'currentMileage'
    | 'currentMileageDate'
    | 'fiscalValueIncVAT'
    | 'brandName'
    | 'modelName'
    | 'editionName'
    | 'bookValueExVAT'
    | 'marketValueExVAT'
> & {
        entity?: Maybe<{ __typename?: 'Lessor' } & Pick<Lessor, 'id' | 'name'>>
        options: Array<
            { __typename?: 'TeslaOption' } & Pick<TeslaOption, 'id' | 'label' | 'manufacturerCode' | 'category'>
        >
        images?: Maybe<
            { __typename?: 'CarImages' } & Pick<
                CarImages,
                'mainImageUrl' | 'interiorImageUrl' | 'exteriorImageUrl' | 'urlsAdditionalImages'
            >
        >
        contracts?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'Contract' } & Pick<
                        Contract,
                        'id' | 'contractNumber' | 'leasingType' | 'startDate' | 'endDate'
                    > & {
                            lessee?: Maybe<
                                | ({ __typename?: 'ContractCompany' } & Pick<ContractCompany, 'id'> & {
                                          companyName: ContractCompany['name']
                                      })
                                | ({ __typename?: 'ContractPerson' } & Pick<ContractPerson, 'id' | 'name'>)
                            >
                            driver?: Maybe<{ __typename?: 'ContractDriver' } & Pick<ContractDriver, 'fullName'>>
                        }
                >
            >
        >
    }

export type PricingConfigurationCalculationCollectionSummaryQueryVariables = Exact<{ [key: string]: never }>

export type PricingConfigurationCalculationCollectionSummaryQuery = { __typename?: 'Query' } & {
    PricingConfigurationCalculationCollection?: Maybe<
        { __typename?: 'ConfigurationCalculationCollection' } & {
            configurationCalculations?: Maybe<
                Array<
                    Maybe<{ __typename?: 'ConfigurationCalculation' } & Pick<ConfigurationCalculation, 'id' | 'name'>>
                >
            >
        }
    >
}

export type PublishToCarShopMutationVariables = Exact<{
    carId: Scalars['ID']
    input: CarShopPublicationInput
}>

export type PublishToCarShopMutation = { __typename?: 'Mutation' } & {
    publishToCarShop:
        | ({ __typename?: 'CarShopPublication' } & Pick<CarShopPublication, 'id'>)
        | { __typename?: 'EntityNotFoundError' }
        | ({ __typename?: 'RimsOptionNeededError' } & Pick<RimsOptionNeededError, 'errorCode'>)
}

export type RemarketingPricingPreviewQueryVariables = Exact<{
    carId: Scalars['ID']
    pricingConfigurationId?: Maybe<Scalars['String']>
}>

export type RemarketingPricingPreviewQuery = { __typename?: 'Query' } & {
    RemarketingPricingPreview: { __typename?: 'RemarketingPricingPreview' } & Pick<
        RemarketingPricingPreview,
        'pricingConfigurationId' | 'purchasePriceExVAT'
    > & {
            futureValues: Array<
                { __typename?: 'RemarketingValue' } & Pick<
                    RemarketingValue,
                    'residualValue' | 'duration' | 'mileage' | 'leasePriceIncVAT' | 'leasePriceExVAT'
                >
            >
            contractTakeoverValues?: Maybe<
                Array<
                    { __typename?: 'RemarketingValue' } & Pick<
                        RemarketingValue,
                        'residualValue' | 'duration' | 'mileage' | 'leasePriceIncVAT' | 'leasePriceExVAT'
                    >
                >
            >
            ready2ShareValues: Array<
                { __typename?: 'Ready2ShareValue' } & Pick<
                    Ready2ShareValue,
                    'privateMileage' | 'businessMileage' | 'privatePrice' | 'businessPrice'
                >
            >
        }
}

export type UnpublishRemarketingPublicationMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type UnpublishRemarketingPublicationMutation = { __typename?: 'Mutation' } & {
    unpublishRemarketingPublication:
        | ({ __typename?: 'PublicationSuccessfullyUnpublished' } & Pick<
              PublicationSuccessfullyUnpublished,
              'publicationId'
          >)
        | { __typename?: 'EntityNotFoundError' }
}

export type UpsertCarMutationVariables = Exact<{
    id?: Maybe<Scalars['ID']>
    input: CarInput
}>

export type UpsertCarMutation = { __typename?: 'Mutation' } & {
    upsertCar:
        | ({ __typename?: 'Car' } & CarFragmentFragment)
        | ({ __typename?: 'EntityNotFoundError' } & Pick<EntityNotFoundError, 'errorCode'>)
        | ({ __typename?: 'InvalidInputDataError' } & Pick<InvalidInputDataError, 'errorCode'>)
}

export type ConfigurationEditionQueryVariables = Exact<{
    id?: Maybe<Scalars['ID']>
    name?: Maybe<Scalars['String']>
    modelName?: Maybe<Scalars['String']>
}>

export type ConfigurationEditionQuery = { __typename?: 'Query' } & {
    ConfigurationEdition?: Maybe<
        | ({ __typename?: 'ConfigurationEdition' } & Pick<
              ConfigurationEdition,
              | 'id'
              | 'name'
              | 'fiscalValue'
              | 'lessKm'
              | 'moreKm'
              | 'privateLeaseLessKm'
              | 'privateLeaseMoreKm'
              | 'showInConfigurator'
              | 'editionLabel'
          > & {
                  calculation?: Maybe<
                      { __typename?: 'ConfigurationCalculation' } & Pick<ConfigurationCalculation, 'id'>
                  >
                  usedCarCalculation?: Maybe<
                      { __typename?: 'ConfigurationCalculation' } & Pick<ConfigurationCalculation, 'id'>
                  >
                  options?: Maybe<
                      Array<
                          Maybe<
                              | ({ __typename?: 'AfterDeliveryEditionOption' } & Pick<
                                    AfterDeliveryEditionOption,
                                    'price' | 'id' | 'fiscalValue' | 'default'
                                > & {
                                        option?: Maybe<
                                            { __typename: 'ConfigurationOption' } & Pick<
                                                ConfigurationOption,
                                                'id' | 'type' | 'name' | 'displayOrder'
                                            >
                                        >
                                    })
                              | ({ __typename?: 'EditionOption' } & Pick<
                                    EditionOption,
                                    'id' | 'fiscalValue' | 'default'
                                > & {
                                        option?: Maybe<
                                            { __typename: 'ConfigurationOption' } & Pick<
                                                ConfigurationOption,
                                                'id' | 'type' | 'name' | 'displayOrder'
                                            >
                                        >
                                    })
                              | ({ __typename?: 'LeaseEditionOption' } & Pick<
                                    LeaseEditionOption,
                                    'price' | 'id' | 'fiscalValue' | 'default'
                                > & {
                                        option?: Maybe<
                                            { __typename: 'ConfigurationOption' } & Pick<
                                                ConfigurationOption,
                                                'id' | 'type' | 'name' | 'displayOrder'
                                            >
                                        >
                                    })
                              | ({ __typename?: 'WheelEditionOption' } & Pick<
                                    WheelEditionOption,
                                    'price' | 'id' | 'fiscalValue' | 'default'
                                > & {
                                        option?: Maybe<
                                            { __typename: 'ConfigurationOption' } & Pick<
                                                ConfigurationOption,
                                                'id' | 'type' | 'name' | 'displayOrder'
                                            >
                                        >
                                    })
                          >
                      >
                  >
              })
        | ({ __typename?: 'EntityNotFoundError' } & Pick<EntityNotFoundError, 'errorCode'>)
    >
}

export type ConfigurationModelsQueryVariables = Exact<{ [key: string]: never }>

export type ConfigurationModelsQuery = { __typename?: 'Query' } & {
    ConfigurationModels?: Maybe<
        Array<
            Maybe<
                | ({ __typename?: 'ConfigurationModel' } & Pick<ConfigurationModel, 'id' | 'name'> & {
                          editions?: Maybe<
                              Array<
                                  Maybe<
                                      { __typename?: 'ConfigurationEdition' } & Pick<
                                          ConfigurationEdition,
                                          'id' | 'name' | 'showInConfigurator' | 'editionLabel' | 'displayOrder'
                                      >
                                  >
                              >
                          >
                      })
                | ({ __typename?: 'EntityNotFoundError' } & Pick<EntityNotFoundError, 'errorCode'>)
            >
        >
    >
}

export type OptionsQueryVariables = Exact<{ [key: string]: never }>

export type OptionsQuery = { __typename?: 'Query' } & {
    Options?: Maybe<
        Array<
            Maybe<
                | ({ __typename?: 'ConfigurationOption' } & Pick<
                      ConfigurationOption,
                      'id' | 'name' | 'type' | 'manufacturerCode' | 'displayOrder'
                  >)
                | { __typename?: 'EntityNotFoundError' }
            >
        >
    >
}

export type ResidualValueTableQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type ResidualValueTableQuery = { __typename?: 'Query' } & {
    ResidualValueTable?: Maybe<
        | ({ __typename?: 'ResidualValueTable' } & Pick<ResidualValueTable, 'id' | 'name'> & {
                  residualValues?: Maybe<
                      Array<
                          Maybe<
                              { __typename?: 'ResidualValue' } & Pick<
                                  ResidualValue,
                                  'id' | 'ageInMonths' | 'mileage' | 'value'
                              >
                          >
                      >
                  >
              })
        | ({ __typename?: 'EntityNotFoundError' } & Pick<EntityNotFoundError, 'errorCode'>)
    >
}

export type AddOptionToEditionMutationVariables = Exact<{
    input: NewConfigurationEditionOptionInput
}>

export type AddOptionToEditionMutation = { __typename?: 'Mutation' } & {
    addOptionToEdition?: Maybe<{ __typename?: 'EditionOption' } & Pick<EditionOption, 'id' | 'fiscalValue'>>
}

export type AllResidualValueTablesQueryVariables = Exact<{ [key: string]: never }>

export type AllResidualValueTablesQuery = { __typename?: 'Query' } & {
    allResidualValueTables?: Maybe<
        Array<
            Maybe<
                | ({ __typename?: 'ResidualValueTable' } & Pick<ResidualValueTable, 'id' | 'name'>)
                | { __typename?: 'EntityNotFoundError' }
            >
        >
    >
}

export type CreateEditionMutationVariables = Exact<{
    input: ConfigurationEditionInput
}>

export type CreateEditionMutation = { __typename?: 'Mutation' } & {
    createEdition?: Maybe<{ __typename?: 'ConfigurationEdition' } & Pick<ConfigurationEdition, 'id' | 'name'>>
}

export type CreateModelMutationVariables = Exact<{
    input: ConfigurationModelInput
}>

export type CreateModelMutation = { __typename?: 'Mutation' } & {
    createModel?: Maybe<{ __typename?: 'ConfigurationModel' } & Pick<ConfigurationModel, 'id'>>
}

export type CreateOptionMutationVariables = Exact<{
    input: ConfigurationOptionInput
}>

export type CreateOptionMutation = { __typename?: 'Mutation' } & {
    createOption?: Maybe<{ __typename?: 'Option' } & Pick<Option, 'id' | 'name' | 'manufacturerCode' | 'displayOrder'>>
}

export type CreateResidualValueTableMutationVariables = Exact<{
    input: ResidualValueTableInput
}>

export type CreateResidualValueTableMutation = { __typename?: 'Mutation' } & {
    createResidualValueTable?: Maybe<
        | ({ __typename?: 'ResidualValueTable' } & Pick<ResidualValueTable, 'id' | 'name'> & {
                  residualValues?: Maybe<
                      Array<
                          Maybe<
                              { __typename?: 'ResidualValue' } & Pick<
                                  ResidualValue,
                                  'id' | 'mileage' | 'ageInMonths' | 'value'
                              >
                          >
                      >
                  >
              })
        | ({ __typename?: 'EntityNotFoundError' } & Pick<EntityNotFoundError, 'errorCode'>)
    >
}

export type RemoveEditionMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type RemoveEditionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeEdition'>

export type RemoveEditionOptionMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type RemoveEditionOptionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeEditionOption'>

export type RemoveOptionMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type RemoveOptionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeOption'>

export type RemoveResidualValueTableMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type RemoveResidualValueTableMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeResidualValueTable'>

export type TenantSettingsQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type TenantSettingsQuery = { __typename?: 'Query' } & {
    TenantSettings?: Maybe<
        { __typename?: 'TenantSettings' } & Pick<
            TenantSettings,
            | 'id'
            | 'tenant'
            | 'currencyCode'
            | 'enabledSigningMethods'
            | 'usedCarConfigurationCutOffMileage'
            | 'downpaymentAmounts'
            | 'downpaymentInterestPercentage'
            | 'dealLocales'
        >
    >
}

export type UpdateEditionMutationVariables = Exact<{
    id: Scalars['ID']
    options: ConfigurationEditionInput
}>

export type UpdateEditionMutation = { __typename?: 'Mutation' } & {
    updateEdition?: Maybe<
        { __typename?: 'ConfigurationEdition' } & Pick<ConfigurationEdition, 'id' | 'fiscalValue' | 'displayOrder'>
    >
}

export type UpdateEditionOptionMutationVariables = Exact<{
    id: Scalars['ID']
    input: ConfigurationEditionOptionInput
}>

export type UpdateEditionOptionMutation = { __typename?: 'Mutation' } & {
    updateEditionOption?: Maybe<
        { __typename?: 'EditionOption' } & Pick<EditionOption, 'id' | 'fiscalValue' | 'default'>
    >
}

export type UpdateModelMutationVariables = Exact<{
    id: Scalars['ID']
    options: ConfigurationModelInput
}>

export type UpdateModelMutation = { __typename?: 'Mutation' } & {
    updateModel?: Maybe<{ __typename?: 'ConfigurationModel' } & Pick<ConfigurationModel, 'id' | 'name'>>
}

export type UpdateOptionMutationVariables = Exact<{
    id: Scalars['ID']
    input: ConfigurationOptionInput
}>

export type UpdateOptionMutation = { __typename?: 'Mutation' } & {
    updateOption?: Maybe<{ __typename?: 'Option' } & Pick<Option, 'id' | 'name' | 'manufacturerCode' | 'displayOrder'>>
}

export type UpdateResidualValueTableMutationVariables = Exact<{
    id: Scalars['ID']
    input: ResidualValueTableInput
}>

export type UpdateResidualValueTableMutation = { __typename?: 'Mutation' } & {
    updateResidualValueTable?: Maybe<
        | ({ __typename?: 'ResidualValueTable' } & Pick<ResidualValueTable, 'id' | 'name'> & {
                  residualValues?: Maybe<
                      Array<
                          Maybe<
                              { __typename?: 'ResidualValue' } & Pick<
                                  ResidualValue,
                                  'id' | 'mileage' | 'ageInMonths' | 'value'
                              >
                          >
                      >
                  >
              })
        | ({ __typename?: 'EntityNotFoundError' } & Pick<EntityNotFoundError, 'errorCode'>)
    >
}

export type UpdateTenantSettingsMutationVariables = Exact<{
    id: Scalars['ID']
    input: TenantSettingsInput
}>

export type UpdateTenantSettingsMutation = { __typename?: 'Mutation' } & {
    updateTenantSettings?: Maybe<
        { __typename?: 'TenantSettings' } & Pick<
            TenantSettings,
            | 'id'
            | 'tenant'
            | 'currencyCode'
            | 'enabledSigningMethods'
            | 'usedCarConfigurationCutOffMileage'
            | 'downpaymentAmounts'
            | 'downpaymentInterestPercentage'
        >
    >
}

export type AccessoriesQueryVariables = Exact<{ [key: string]: never }>

export type AccessoriesQuery = { __typename?: 'Query' } & {
    Accessories?: Maybe<
        Array<Maybe<{ __typename?: 'Accessory' } & Pick<Accessory, 'id' | 'name' | 'priceIncVAT' | 'priceExVAT'>>>
    >
}

export type AccessoryPropositionQueryVariables = Exact<{
    id: Scalars['ID']
    accessory: Scalars['String']
}>

export type AccessoryPropositionQuery = { __typename?: 'Query' } & {
    AccessoryProposition?: Maybe<
        { __typename?: 'AccessoryProposition' } & Pick<
            AccessoryProposition,
            | 'id'
            | 'oldPricePerMonthExVAT'
            | 'newPricePerMonthExVAT'
            | 'priceIncreaseExVAT'
            | 'oldPricePerMonthIncVAT'
            | 'newPricePerMonthIncVAT'
            | 'priceIncreaseIncVAT'
        >
    >
}

export type ContractAttachmentsQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type ContractAttachmentsQuery = { __typename?: 'Query' } & {
    Contract?: Maybe<
        { __typename?: 'Contract' } & {
            attachments: Array<
                { __typename?: 'ContractAttachment' } & Pick<ContractAttachment, 'id' | 'name' | 'type' | 'url'>
            >
        }
    >
}

export type ContractCollectionQueryVariables = Exact<{
    filter?: Maybe<ContractFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type ContractCollectionQuery = { __typename?: 'Query' } & {
    ContractCollection?: Maybe<
        { __typename?: 'ContractCollection' } & Pick<ContractCollection, 'totalResults'> & {
                contracts?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Contract' } & Pick<Contract, 'id' | 'startDate' | 'endDate'> & {
                                    lessor?: Maybe<{ __typename?: 'Lessor' } & Pick<Lessor, 'id' | 'name'>>
                                    lessee?: Maybe<
                                        | ({ __typename: 'ContractCompany' } & Pick<ContractCompany, 'name'>)
                                        | ({ __typename: 'ContractPerson' } & { fullName: ContractPerson['name'] })
                                    >
                                    driver?: Maybe<{ __typename?: 'ContractDriver' } & Pick<ContractDriver, 'fullName'>>
                                    car?: Maybe<{ __typename?: 'Car' } & Pick<Car, 'VIN' | 'licensePlate'>>
                                    attachments: Array<
                                        { __typename?: 'ContractAttachment' } & Pick<ContractAttachment, 'id' | 'type'>
                                    >
                                }
                        >
                    >
                >
            }
    >
}

export type ContractRecalculationQueryVariables = Exact<{
    id: Scalars['ID']
    newTotalMileage: Scalars['Int']
}>

export type ContractRecalculationQuery = { __typename?: 'Query' } & {
    ContractRecalculation?: Maybe<
        { __typename?: 'ContractRecalculation' } & Pick<
            ContractRecalculation,
            | 'id'
            | 'oldPricePerMonthExVAT'
            | 'oldPricePerMonthIncVAT'
            | 'newPricePerMonthExVAT'
            | 'newPricePerMonthIncVAT'
            | 'priceIncreaseExVAT'
            | 'priceIncreaseIncVAT'
            | 'oneTimeFeeExVAT'
            | 'oneTimeFeeIncVAT'
        >
    >
}

export type ContractQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type ContractQuery = { __typename?: 'Query' } & {
    Contract?: Maybe<
        { __typename?: 'Contract' } & Pick<
            Contract,
            | 'id'
            | 'contractNumber'
            | 'leasingType'
            | 'startDate'
            | 'endDate'
            | 'mileage'
            | 'pricePerMonthExVAT'
            | 'moreKmPrice'
            | 'lessKmPrice'
            | 'createdAt'
            | 'updatedAt'
            | 'remarks'
            | 'fsd'
            | 'fsdGrowthModel'
            | 'fsdGrowthFee'
            | 'duration'
            | 'deductible'
            | 'windowBreakDeductible'
            | 'winterTires'
            | 'billedUntil'
            | 'status'
            | 'downpayment'
            | 'premiumConnectivity'
        > & {
                paymentSummary?: Maybe<
                    { __typename?: 'PaymentSummary' } & Pick<
                        PaymentSummary,
                        'expiredAmount' | 'invoicesPaidTooLate' | 'paymentScore'
                    >
                >
                accountManager?: Maybe<
                    { __typename?: 'UserProfile' } & Pick<
                        UserProfile,
                        'id' | 'firstName' | 'lastName' | 'emailAddress' | 'telephoneNumber'
                    >
                >
                lessor?: Maybe<{ __typename?: 'Lessor' } & Pick<Lessor, 'id' | 'name'>>
                lessee?: Maybe<
                    | ({ __typename?: 'ContractCompany' } & Pick<
                          ContractCompany,
                          'id' | 'emailAddress' | 'phonenumber' | 'chamberOfCommerceNr'
                      > & { companyName: ContractCompany['name'] } & {
                              visitingAddress?: Maybe<
                                  { __typename?: 'Address' } & Pick<
                                      Address,
                                      'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
                                  >
                              >
                          })
                    | ({ __typename?: 'ContractPerson' } & Pick<
                          ContractPerson,
                          'id' | 'name' | 'emailAddress' | 'phonenumber'
                      > & {
                              address?: Maybe<
                                  { __typename?: 'Address' } & Pick<
                                      Address,
                                      'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
                                  >
                              >
                          })
                >
                car?: Maybe<
                    { __typename?: 'Car' } & Pick<
                        Car,
                        | 'id'
                        | 'VIN'
                        | 'licensePlate'
                        | 'brandName'
                        | 'modelName'
                        | 'editionName'
                        | 'currentTire'
                        | 'tireSizeFront'
                        | 'tireSizeRear'
                        | 'lastTireChangeDate'
                        | 'lastTireChangeBy'
                        | 'lastTireChangePlace'
                        | 'nextTireChangeDate'
                        | 'ministryOfTransportDate'
                    >
                >
                driver?: Maybe<
                    { __typename?: 'ContractDriver' } & Pick<
                        ContractDriver,
                        'firstName' | 'fullName' | 'mobilePhonenumber' | 'workPhonenumber' | 'emailAddress'
                    > & {
                            address?: Maybe<
                                { __typename?: 'Address' } & Pick<
                                    Address,
                                    'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
                                >
                            >
                        }
                >
                propositions: Array<
                    { __typename?: 'Proposition' } & Pick<Proposition, 'id' | 'createdAt' | 'type' | 'pdfUrl' | 'state'>
                >
                attachments: Array<
                    { __typename?: 'ContractAttachment' } & Pick<ContractAttachment, 'id' | 'name' | 'type' | 'url'>
                >
            }
    >
}

export type DeleteContractAttachmentMutationVariables = Exact<{
    attachmentId: Scalars['ID']
}>

export type DeleteContractAttachmentMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteContractAttachment'>

export type EndContractPropositionQueryVariables = Exact<{
    id: Scalars['ID']
    propositionDate: Scalars['Date']
}>

export type EndContractPropositionQuery = { __typename?: 'Query' } & {
    EndContractProposition?: Maybe<
        { __typename?: 'EndContractProposition' } & Pick<
            EndContractProposition,
            'id' | 'oneTimeFeeExVAT' | 'oneTimeFeeIncVAT'
        >
    >
}

export type GenerateAccessoryPropositionMutationVariables = Exact<{
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    accessory: Scalars['String']
}>

export type GenerateAccessoryPropositionMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'generateAccessoryProposition'
>

export type GenerateContractRecalculationPropositionMutationVariables = Exact<{
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    newTotalMileage: Scalars['Int']
}>

export type GenerateContractRecalculationPropositionMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'generateContractRecalculationProposition'
>

export type GenerateEndContractPropositionMutationVariables = Exact<{
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    propositionDate: Scalars['Date']
    language: Scalars['String']
}>

export type GenerateEndContractPropositionMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'generateEndContractProposition'
>

export type GenerateFsdPropositionMutationVariables = Exact<{
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    type: FsdPropositionType
    amount: Scalars['Float']
}>

export type GenerateFsdPropositionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'generateFSDProposition'>

export type GeneratePremiumConnectivityPropositionMutationVariables = Exact<{
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
}>

export type GeneratePremiumConnectivityPropositionMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'generatePremiumConnectivityProposition'
>

export type GenerateUpgradePropositionMutationVariables = Exact<{
    id: Scalars['ID']
    signingPersonEmailAddress: Scalars['String']
    propositionDate: Scalars['Date']
}>

export type GenerateUpgradePropositionMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'generateUpgradeProposition'
>

export type UpgradePropositionQueryVariables = Exact<{
    id: Scalars['ID']
    propositionDate: Scalars['Date']
}>

export type UpgradePropositionQuery = { __typename?: 'Query' } & {
    UpgradeProposition?: Maybe<
        { __typename?: 'UpgradeProposition' } & Pick<UpgradeProposition, 'id' | 'oneTimeFeeExVAT' | 'oneTimeFeeIncVAT'>
    >
}

export type UploadContractAttachmentMutationVariables = Exact<{
    contractId: Scalars['ID']
    type: ContractAttachmentType
    encodedFile: Scalars['Base64']
    fileName: Scalars['String']
}>

export type UploadContractAttachmentMutation = { __typename?: 'Mutation' } & {
    uploadContractAttachment: Array<
        { __typename?: 'ContractAttachment' } & Pick<ContractAttachment, 'id' | 'name' | 'type' | 'url'>
    >
}

export type DealContractPdfQueryVariables = Exact<{
    dealId: Scalars['ID']
}>

export type DealContractPdfQuery = { __typename?: 'Query' } & {
    DealContractPdf: { __typename?: 'DealContractPdf' } & Pick<DealContractPdf, 'dealId' | 'url'>
}

export type LeasewiseOfferStatusQueryVariables = Exact<{
    dealId: Scalars['ID']
}>

export type LeasewiseOfferStatusQuery = { __typename?: 'Query' } & {
    LeasewiseOfferStatus?: Maybe<
        { __typename?: 'LeasewiseOfferStatus' } & Pick<LeasewiseOfferStatus, 'id' | 'status' | 'error'>
    >
}

export type AddSigningpersonToDealMutationVariables = Exact<{
    id: Scalars['ID']
    emailAddress: Scalars['EmailAddress']
}>

export type AddSigningpersonToDealMutation = { __typename?: 'Mutation' } & {
    addSigningpersonToDeal: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            signingPersons: Array<{ __typename?: 'SigningPerson' } & ContractDetailSigningPersonFragmentFragment>
        }
}

export type CancelDealMutationVariables = Exact<{
    id: Scalars['ID']
    comment?: Maybe<Scalars['String']>
}>

export type CancelDealMutation = { __typename?: 'Mutation' } & {
    cancelDeal: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'status'>
}

export type ContractDetailQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type ContractDetailQuery = { __typename?: 'Query' } & {
    Deal: { __typename?: 'Deal' } & ContractDetailFragmentFragment
}

export type ContractDetailFragmentFragment = { __typename?: 'Deal' } & Pick<
    Deal,
    | 'id'
    | 'status'
    | 'optionsDescription'
    | 'internalOwnerId'
    | 'locale'
    | 'editionName'
    | 'modelName'
    | 'dealType'
    | 'referralCode'
    | 'manufacturerReferralCode'
    | 'manufacturerAccountEmailAddress'
    | 'remarks'
    | 'assetOwnerComment'
> & {
        options: Array<
            | ({ __typename: 'BooleanDealOption' } & BooleanDealOptionFragmentFragment)
            | ({ __typename: 'SelectableDealOption' } & SelectableDealOptionFragmentFragment)
        >
        lessee?: Maybe<
            | ({ __typename: 'LesseeCompany' } & Pick<
                  LesseeCompany,
                  'id' | 'name' | 'chamberOfCommerceNr' | 'kcStatus' | 'kvkStatus'
              > & {
                      postalAddress?: Maybe<
                          { __typename?: 'Address' } & Pick<
                              Address,
                              'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
                          >
                      >
                  })
            | ({ __typename: 'LesseePerson' } & Pick<
                  LesseePerson,
                  'id' | 'emailAddress' | 'firstName' | 'lastNamePrefix' | 'lastName' | 'kcComment' | 'kcStatus'
              > & {
                      address?: Maybe<
                          { __typename?: 'Address' } & Pick<
                              Address,
                              'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
                          >
                      >
                  })
        >
        lessor: { __typename?: 'Lessor' } & Pick<Lessor, 'id' | 'name'>
        driver?: Maybe<
            { __typename?: 'DealDriver' } & Pick<
                DealDriver,
                | 'id'
                | 'firstName'
                | 'lastNamePrefix'
                | 'lastName'
                | 'licenseVerified'
                | 'emailAddress'
                | 'userId'
                | 'phonenumber'
            > & {
                    address?: Maybe<
                        { __typename?: 'Address' } & Pick<
                            Address,
                            'id' | 'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
                        >
                    >
                }
        >
        contact?: Maybe<
            { __typename?: 'DealContact' } & Pick<
                DealContact,
                'id' | 'firstName' | 'lastNamePrefix' | 'lastName' | 'emailAddress' | 'phonenumber'
            >
        >
        signingPersons: Array<{ __typename?: 'SigningPerson' } & ContractDetailSigningPersonFragmentFragment>
        pricing: { __typename?: 'Pricing' } & Pick<
            Pricing,
            | 'id'
            | 'pricePerMonthExVAT'
            | 'pricePerMonthIncVAT'
            | 'fiscalValue'
            | 'incVAT'
            | 'margin'
            | 'lessKmPriceExVAT'
            | 'moreKmPriceExVAT'
        >
        billingAccount?: Maybe<
            { __typename?: 'BillingAccount' } & Pick<BillingAccount, 'id' | 'iban' | 'emailAddress' | 'accountHolder'>
        >
        referralProgram?: Maybe<{ __typename?: 'ReferralProgram' } & Pick<ReferralProgram, 'id'>>
        requirements: { __typename?: 'DealRequirements' } & Pick<
            DealRequirements,
            'isCompanyRequired' | 'isDriverRequired'
        >
    }

export type ContractDetailSigningPersonFragmentFragment = { __typename?: 'SigningPerson' } & Pick<
    SigningPerson,
    'id' | 'userId' | 'emailAddress' | 'signed' | 'signingStatus'
> & {
        signature?: Maybe<
            | ({ __typename?: 'DocumentSignature' } & Pick<
                  DocumentSignature,
                  'id' | 'verifiedByUserId' | 'verificationTimestamp'
              > & { document: { __typename?: 'Document' } & Pick<Document, 'id' | 'retrievalUrl'> })
            | ({ __typename?: 'IdealSignature' } & Pick<
                  IdealSignature,
                  'id' | 'accountName' | 'iban' | 'paymentId' | 'status'
              >)
        >
    }

export type ContractOverviewQueryVariables = Exact<{
    filter?: Maybe<DealFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type ContractOverviewQuery = { __typename?: 'Query' } & {
    DealCollection?: Maybe<
        { __typename?: 'DealCollection' } & Pick<DealCollection, 'totalResults'> & {
                deals?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Deal' } & Pick<
                                Deal,
                                | 'id'
                                | 'createdAt'
                                | 'status'
                                | 'locale'
                                | 'internalOwnerId'
                                | 'referralCode'
                                | 'modelName'
                            > & {
                                    lessor: { __typename?: 'Lessor' } & Pick<Lessor, 'id'>
                                    lessee?: Maybe<
                                        | ({ __typename?: 'LesseeCompany' } & Pick<
                                              LesseeCompany,
                                              'id' | 'name' | 'kcStatus' | 'kvkStatus'
                                          >)
                                        | ({ __typename?: 'LesseePerson' } & Pick<
                                              LesseePerson,
                                              | 'id'
                                              | 'emailAddress'
                                              | 'firstName'
                                              | 'lastNamePrefix'
                                              | 'lastName'
                                              | 'kcStatus'
                                          >)
                                    >
                                    driver?: Maybe<
                                        { __typename?: 'DealDriver' } & Pick<
                                            DealDriver,
                                            | 'id'
                                            | 'firstName'
                                            | 'lastNamePrefix'
                                            | 'lastName'
                                            | 'licenseVerified'
                                            | 'emailAddress'
                                        >
                                    >
                                    signingPersons: Array<
                                        { __typename?: 'SigningPerson' } & Pick<
                                            SigningPerson,
                                            'id' | 'signed' | 'signingStatus'
                                        >
                                    >
                                }
                        >
                    >
                >
            }
    >
}

export type CreateDealMutationVariables = Exact<{
    input: DealInput
}>

export type CreateDealMutation = { __typename?: 'Mutation' } & {
    createDeal: { __typename?: 'Deal' } & Pick<Deal, 'id'>
}

export type CreateDealDocumentUploadTokenMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type CreateDealDocumentUploadTokenMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'createDealDocumentUploadToken'
>

export type DealToLeasewiseMutationVariables = Exact<{
    dealId: Scalars['ID']
}>

export type DealToLeasewiseMutation = { __typename?: 'Mutation' } & {
    dealToLeasewise?: Maybe<
        { __typename?: 'LeasewiseOfferStatus' } & Pick<LeasewiseOfferStatus, 'id' | 'status' | 'error'>
    >
}

export type DocumentQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type DocumentQuery = { __typename?: 'Query' } & {
    Document?: Maybe<{ __typename?: 'Document' } & DocumentFragmentFragment>
}

export type DocumentFragmentFragment = { __typename?: 'Document' } & Pick<
    Document,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'name'
    | 'description'
    | 'category'
    | 'mimeType'
    | 'readonly'
    | 'bucketType'
    | 'bucketId'
    | 'retrievalUrl'
> & {
        metadata?: Maybe<
            | ({ __typename?: 'PdfMetadata' } & Pick<PdfMetadata, 'sizeInBytes' | 'pages'>)
            | ({ __typename?: 'ImageMetadata' } & Pick<ImageMetadata, 'sizeInBytes' | 'height' | 'width'>)
            | ({ __typename?: 'MiscellaneousMetadata' } & Pick<MiscellaneousMetadata, 'sizeInBytes'>)
        >
    }

export type DuplicateDealMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type DuplicateDealMutation = { __typename?: 'Mutation' } & {
    duplicateDeal?: Maybe<{ __typename?: 'Deal' } & Pick<Deal, 'id'>>
}

export type EnabledSigningMethodsQueryVariables = Exact<{
    tenant: Scalars['ID']
}>

export type EnabledSigningMethodsQuery = { __typename?: 'Query' } & {
    TenantSettings?: Maybe<{ __typename?: 'TenantSettings' } & Pick<TenantSettings, 'enabledSigningMethods'>>
}

export type FinalizeDealMutationVariables = Exact<{
    id: Scalars['ID']
    comment?: Maybe<Scalars['String']>
}>

export type FinalizeDealMutation = { __typename?: 'Mutation' } & {
    finalizeDeal: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'status'>
}

export type GenerateCoverPageQueryVariables = Exact<{
    dealId: Scalars['ID']
}>

export type GenerateCoverPageQuery = { __typename?: 'Query' } & Pick<Query, 'generateCoverPage'>

export type MakeDealOfferMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type MakeDealOfferMutation = { __typename?: 'Mutation' } & {
    makeDealOffer: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'status'>
}

export type OfferEditQueryVariables = Exact<{
    id: Scalars['ID']
    showAllEditions?: Maybe<Scalars['Boolean']>
}>

export type OfferEditQuery = { __typename?: 'Query' } & {
    Deal: { __typename?: 'Deal' } & Pick<
        Deal,
        'userId' | 'status' | 'internalOwnerId' | 'optionsDescription' | 'remarks'
    > & {
            requirements: { __typename?: 'DealRequirements' } & DealRequirementsFragmentFragment
        } & BaseDealFieldsFragment
}

export type BooleanDealOptionFragmentFragment = { __typename?: 'BooleanDealOption' } & Pick<
    BooleanDealOption,
    'id' | 'key' | 'value'
>

export type DealRequirementsFragmentFragment = { __typename?: 'DealRequirements' } & Pick<
    DealRequirements,
    'id' | 'isCompanyRequired' | 'isDriverRequired'
>

export type SelectableDealOptionFragmentFragment = { __typename?: 'SelectableDealOption' } & Pick<
    SelectableDealOption,
    'id'
> & { name: SelectableDealOption['key'] } & {
        selectedValue: { __typename?: 'Value' } & Pick<Value, 'id' | 'label' | 'editionLabel'>
        selectableValues: Array<{ __typename?: 'Value' } & Pick<Value, 'id' | 'label' | 'editionLabel'>>
    }

export type LesseeCompanyFragmentFragment = { __typename?: 'LesseeCompany' } & Pick<
    LesseeCompany,
    'id' | 'chamberOfCommerceNr' | 'name' | 'kvkStatus' | 'kcStatus'
> & {
        postalAddress?: Maybe<
            { __typename?: 'Address' } & Pick<
                Address,
                'id' | 'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
            >
        >
    }

export type LesseePersonFragmentFragment = { __typename?: 'LesseePerson' } & Pick<
    LesseePerson,
    'id' | 'userId' | 'emailAddress' | 'phonenumber' | 'firstName' | 'lastName' | 'lastNamePrefix'
> & { personKcStatus: LesseePerson['kcStatus'] } & {
        address?: Maybe<
            { __typename?: 'Address' } & Pick<
                Address,
                'id' | 'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
            >
        >
    }

export type DriverFragmentFragment = { __typename?: 'DealDriver' } & Pick<
    DealDriver,
    'id' | 'userId' | 'emailAddress' | 'phonenumber' | 'licenseVerified' | 'firstName' | 'lastName' | 'lastNamePrefix'
> & {
        address?: Maybe<
            { __typename?: 'Address' } & Pick<
                Address,
                'id' | 'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
            >
        >
    }

export type PricingFragmentFragment = { __typename?: 'Pricing' } & Pick<
    Pricing,
    | 'id'
    | 'pricePerMonthIncVAT'
    | 'pricePerMonthExVAT'
    | 'incVAT'
    | 'fiscalValue'
    | 'margin'
    | 'lessKmPriceExVAT'
    | 'moreKmPriceExVAT'
    | 'privateLeaseLessKmPriceIncVAT'
    | 'privateLeaseMoreKmPriceIncVAT'
>

export type SigningPersonFragmentFragment = { __typename?: 'SigningPerson' } & Pick<
    SigningPerson,
    'id' | 'userId' | 'emailAddress' | 'signed' | 'signingStatus'
> & {
        signature?: Maybe<
            | ({ __typename?: 'DocumentSignature' } & Pick<DocumentSignature, 'id' | 'verified'>)
            | ({ __typename?: 'IdealSignature' } & Pick<
                  IdealSignature,
                  'id' | 'accountName' | 'iban' | 'paymentId' | 'status'
              >)
        >
    }

export type ContactFragmentFragment = { __typename?: 'DealContact' } & Pick<
    DealContact,
    'id' | 'firstName' | 'lastNamePrefix' | 'lastName' | 'emailAddress' | 'phonenumber'
>

export type BillingAccountFragmentFragment = { __typename?: 'BillingAccount' } & Pick<
    BillingAccount,
    'id' | 'accountHolder' | 'iban' | 'emailAddress'
>

export type BaseDealFieldsFragment = { __typename?: 'Deal' } & Pick<
    Deal,
    'id' | 'locale' | 'referralCode' | 'carId' | 'dealType' | 'modelName' | 'editionName'
> & {
        options: Array<
            | ({ __typename?: 'BooleanDealOption' } & BooleanDealOptionFragmentFragment)
            | ({ __typename?: 'SelectableDealOption' } & SelectableDealOptionFragmentFragment)
        >
        lessee?: Maybe<
            | ({ __typename?: 'LesseeCompany' } & LesseeCompanyFragmentFragment)
            | ({ __typename?: 'LesseePerson' } & LesseePersonFragmentFragment)
        >
        driver?: Maybe<{ __typename?: 'DealDriver' } & DriverFragmentFragment>
        pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        referralProgram?: Maybe<{ __typename?: 'ReferralProgram' } & Pick<ReferralProgram, 'id' | 'name'>>
    }

export type OfferOverviewQueryVariables = Exact<{
    filter?: Maybe<DealFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type OfferOverviewQuery = { __typename?: 'Query' } & {
    DealCollection?: Maybe<
        { __typename?: 'DealCollection' } & Pick<DealCollection, 'totalResults'> & {
                deals?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Deal' } & Pick<
                                Deal,
                                | 'id'
                                | 'createdAt'
                                | 'internalOwnerId'
                                | 'userId'
                                | 'locale'
                                | 'modelName'
                                | 'licencePlate'
                            > & {
                                    pricing: { __typename?: 'Pricing' } & Pick<
                                        Pricing,
                                        'id' | 'pricePerMonthExVAT' | 'pricePerMonthIncVAT' | 'incVAT' | 'margin'
                                    >
                                    lessee?: Maybe<
                                        | ({ __typename?: 'LesseeCompany' } & Pick<
                                              LesseeCompany,
                                              'id' | 'name' | 'kcStatus' | 'kvkStatus'
                                          >)
                                        | ({ __typename?: 'LesseePerson' } & Pick<
                                              LesseePerson,
                                              | 'id'
                                              | 'emailAddress'
                                              | 'firstName'
                                              | 'lastNamePrefix'
                                              | 'lastName'
                                              | 'kcStatus'
                                          >)
                                    >
                                    driver?: Maybe<
                                        { __typename?: 'DealDriver' } & Pick<
                                            DealDriver,
                                            | 'id'
                                            | 'firstName'
                                            | 'lastNamePrefix'
                                            | 'lastName'
                                            | 'licenseVerified'
                                            | 'emailAddress'
                                        >
                                    >
                                }
                        >
                    >
                >
            }
    >
}

export type RemoveSigningpersonFromDealMutationVariables = Exact<{
    id: Scalars['ID']
    emailAddress: Scalars['EmailAddress']
}>

export type RemoveSigningpersonFromDealMutation = { __typename?: 'Mutation' } & {
    removeSigningpersonFromDeal: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            signingPersons: Array<{ __typename?: 'SigningPerson' } & ContractDetailSigningPersonFragmentFragment>
        }
}

export type SearchCompanyQueryVariables = Exact<{
    name: Scalars['String']
}>

export type SearchCompanyQuery = { __typename?: 'Query' } & {
    searchCompany?: Maybe<
        Array<
            Maybe<
                { __typename?: 'CompanySearchResult' } & Pick<
                    CompanySearchResult,
                    'id' | 'name' | 'branchNr' | 'chamberOfCommerceNr' | 'isMainBranch'
                > & {
                        address?: Maybe<
                            { __typename?: 'CompanySearchAddress' } & Pick<
                                CompanySearchAddress,
                                'id' | 'street' | 'houseNr' | 'houseNrSuffix' | 'zipCode' | 'city' | 'country'
                            >
                        >
                    }
            >
        >
    >
}

export type SigningPersonsQueryVariables = Exact<{
    dealId: Scalars['ID']
}>

export type SigningPersonsQuery = { __typename?: 'Query' } & {
    Deal: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            signingPersons: Array<{ __typename?: 'SigningPerson' } & ContractDetailSigningPersonFragmentFragment>
        }
}

export type UpdateBooleanDealOptionMutationVariables = Exact<{
    id: Scalars['ID']
    dealOptionId: Scalars['ID']
    value: Scalars['Boolean']
}>

export type UpdateBooleanDealOptionMutation = { __typename?: 'Mutation' } & {
    updateBooleanDealOption: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
            options: Array<
                | ({ __typename?: 'BooleanDealOption' } & Pick<BooleanDealOption, 'id' | 'key' | 'value'>)
                | ({ __typename?: 'SelectableDealOption' } & Pick<SelectableDealOption, 'id'> & {
                          name: SelectableDealOption['key']
                      } & {
                          selectedValue: { __typename?: 'Value' } & Pick<Value, 'id' | 'label'>
                          selectableValues: Array<{ __typename?: 'Value' } & Pick<Value, 'id' | 'label'>>
                      })
            >
        }
}

export type UpdateDealDriverMutationVariables = Exact<{
    id: Scalars['ID']
    email?: Maybe<Scalars['String']>
}>

export type UpdateDealDriverMutation = { __typename?: 'Mutation' } & {
    updateDealDriver: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            driver?: Maybe<{ __typename?: 'DealDriver' } & DriverFragmentFragment>
        }
}

export type UpdateDealDurationMutationVariables = Exact<{
    id: Scalars['ID']
    duration: Scalars['Int']
}>

export type UpdateDealDurationMutation = { __typename?: 'Mutation' } & {
    updateDealDuration: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
            options: Array<
                | ({ __typename?: 'BooleanDealOption' } & BooleanDealOptionFragmentFragment)
                | ({ __typename?: 'SelectableDealOption' } & SelectableDealOptionFragmentFragment)
            >
        }
}

export type UpdateDealInternalOwnerMutationVariables = Exact<{
    id: Scalars['ID']
    userId?: Maybe<Scalars['String']>
}>

export type UpdateDealInternalOwnerMutation = { __typename?: 'Mutation' } & {
    updateDealInternalOwner: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'internalOwnerId'>
}

export type UpdateDealKcStatusMutationVariables = Exact<{
    id: Scalars['ID']
    status: InternalStatus
    message?: Maybe<Scalars['String']>
}>

export type UpdateDealKcStatusMutation = { __typename?: 'Mutation' } & {
    updateDealKcStatus: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'status'> & {
            lessee?: Maybe<
                | ({ __typename?: 'LesseeCompany' } & Pick<LesseeCompany, 'id' | 'kcComment' | 'kcStatus'>)
                | ({ __typename?: 'LesseePerson' } & Pick<LesseePerson, 'id' | 'kcComment' | 'kcStatus'>)
            >
        }
}

export type UpdateDealKvkStatusMutationVariables = Exact<{
    id: Scalars['ID']
    status: InternalStatus
    message?: Maybe<Scalars['String']>
}>

export type UpdateDealKvkStatusMutation = { __typename?: 'Mutation' } & {
    updateDealKvkStatus: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'status'> & {
            lessee?: Maybe<
                | ({ __typename?: 'LesseeCompany' } & Pick<LesseeCompany, 'id' | 'kvkStatus' | 'kvkComment'>)
                | { __typename?: 'LesseePerson' }
            >
        }
}

export type UpdateDealLessKmMutationVariables = Exact<{
    id: Scalars['ID']
    lessKmPriceExVAT?: Maybe<Scalars['Float']>
}>

export type UpdateDealLessKmMutation = { __typename?: 'Mutation' } & {
    updateDealLessKm: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        }
}

export type UpdateDealLesseeMutationVariables = Exact<{
    id: Scalars['ID']
    input?: Maybe<CompanyInput>
}>

export type UpdateDealLesseeMutation = { __typename?: 'Mutation' } & {
    updateDealLessee: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            lessee?: Maybe<
                | ({ __typename?: 'LesseeCompany' } & LesseeCompanyFragmentFragment)
                | ({ __typename?: 'LesseePerson' } & LesseePersonFragmentFragment)
            >
        }
}

export type UpdateDealLocaleMutationVariables = Exact<{
    id: Scalars['ID']
    locale: DealLocale
}>

export type UpdateDealLocaleMutation = { __typename?: 'Mutation' } & {
    updateDealLocale: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'locale'>
}

export type UpdateDealMarginMutationVariables = Exact<{
    id: Scalars['ID']
    margin: Scalars['Float']
}>

export type UpdateDealMarginMutation = { __typename?: 'Mutation' } & {
    updateDealMargin: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        }
}

export type UpdateDealMileageMutationVariables = Exact<{
    id: Scalars['ID']
    mileage: Scalars['Int']
}>

export type UpdateDealMileageMutation = { __typename?: 'Mutation' } & {
    updateDealMileage: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
            options: Array<
                | ({ __typename?: 'BooleanDealOption' } & BooleanDealOptionFragmentFragment)
                | ({ __typename?: 'SelectableDealOption' } & SelectableDealOptionFragmentFragment)
            >
        }
}

export type UpdateDealMoreKmMutationVariables = Exact<{
    id: Scalars['ID']
    moreKmPriceExVAT?: Maybe<Scalars['Float']>
}>

export type UpdateDealMoreKmMutation = { __typename?: 'Mutation' } & {
    updateDealMoreKm: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        }
}

export type UpdateDealOptionsDescriptionMutationVariables = Exact<{
    id: Scalars['ID']
    optionsDescription: Scalars['String']
}>

export type UpdateDealOptionsDescriptionMutation = { __typename?: 'Mutation' } & {
    updateDealOptionsDescription: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'optionsDescription'>
}

export type UpdateDealOwnerMutationVariables = Exact<{
    id: Scalars['ID']
    userId: Scalars['String']
}>

export type UpdateDealOwnerMutation = { __typename?: 'Mutation' } & {
    updateDealOwner: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'userId'>
}

export type UpdateDealPriceMutationVariables = Exact<{
    id: Scalars['ID']
    priceExVAT: Scalars['Float']
}>

export type UpdateDealPriceMutation = { __typename?: 'Mutation' } & {
    updateDealPrice: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        }
}

export type UpdateDealPrivateLeaseLessKmMutationVariables = Exact<{
    id: Scalars['ID']
    privateLeaseLessKmIncVAT?: Maybe<Scalars['Float']>
}>

export type UpdateDealPrivateLeaseLessKmMutation = { __typename?: 'Mutation' } & {
    updateDealPrivateLeaseLessKm: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        }
}

export type UpdateDealPrivateLeaseMoreKmMutationVariables = Exact<{
    id: Scalars['ID']
    privateLeaseMoreKmIncVAT?: Maybe<Scalars['Float']>
}>

export type UpdateDealPrivateLeaseMoreKmMutation = { __typename?: 'Mutation' } & {
    updateDealPrivateLeaseMoreKm: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        }
}

export type UpdateDealReferralCodeMutationVariables = Exact<{
    id: Scalars['ID']
    code: Scalars['String']
}>

export type UpdateDealReferralCodeMutation = { __typename?: 'Mutation' } & {
    updateDealReferralCode: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'referralCode'> & {
            referralProgram?: Maybe<{ __typename?: 'ReferralProgram' } & Pick<ReferralProgram, 'id' | 'name'>>
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
        }
}

export type UpdateDealRemarksMutationVariables = Exact<{
    id: Scalars['ID']
    remarks: Scalars['String']
}>

export type UpdateDealRemarksMutation = { __typename?: 'Mutation' } & {
    updateDealRemarks: { __typename?: 'Deal' } & Pick<Deal, 'id' | 'remarks'>
}

export type UpdateSelectableDealOptionMutationVariables = Exact<{
    id: Scalars['ID']
    dealOptionId: Scalars['ID']
    valueId: Scalars['ID']
    showAllEditions?: Maybe<Scalars['Boolean']>
}>

export type UpdateSelectableDealOptionMutation = { __typename?: 'Mutation' } & {
    updateSelectableDealOption: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            pricing: { __typename?: 'Pricing' } & PricingFragmentFragment
            options: Array<
                | ({ __typename?: 'BooleanDealOption' } & Pick<BooleanDealOption, 'id' | 'key' | 'value'>)
                | ({ __typename?: 'SelectableDealOption' } & Pick<SelectableDealOption, 'id'> & {
                          name: SelectableDealOption['key']
                      } & {
                          selectedValue: { __typename?: 'Value' } & Pick<Value, 'id' | 'label' | 'editionLabel'>
                          selectableValues: Array<
                              { __typename?: 'Value' } & Pick<Value, 'id' | 'label' | 'editionLabel'>
                          >
                      })
            >
            requirements: { __typename?: 'DealRequirements' } & DealRequirementsFragmentFragment
        }
}

export type UploadDocumentMutationVariables = Exact<{
    input: DocumentInput
}>

export type UploadDocumentMutation = { __typename?: 'Mutation' } & {
    uploadDocument?: Maybe<{ __typename?: 'Document' } & DocumentFragmentFragment>
}

export type VerifyDealDocumentMutationVariables = Exact<{
    documentSignatureId: Scalars['ID']
    isVerified: Scalars['Boolean']
}>

export type VerifyDealDocumentMutation = { __typename?: 'Mutation' } & {
    verifyDealDocument: { __typename?: 'Deal' } & ContractDetailFragmentFragment
}

export type AllExposedEntitiesQueryVariables = Exact<{ [key: string]: never }>

export type AllExposedEntitiesQuery = { __typename?: 'Query' } & {
    allExposedEntities?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Entity' } & Pick<Entity, 'id' | 'name' | 'type'> & {
                        properties: Array<
                            { __typename?: 'EntityProperty' } & Pick<EntityProperty, 'id' | 'name' | 'description'>
                        >
                    }
            >
        >
    >
}

export type EmailTemplateQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type EmailTemplateQuery = { __typename?: 'Query' } & {
    EmailTemplate?: Maybe<
        { __typename?: 'EmailTemplate' } & Pick<EmailTemplate, 'id' | 'name'> & {
                emailTemplateLocales: Array<
                    { __typename?: 'EmailTemplateLocale' } & Pick<
                        EmailTemplateLocale,
                        'id' | 'locale' | 'subject' | 'previewText' | 'htmlContent' | 'rawTemplateContent'
                    >
                >
            }
    >
}

export type EmailTemplateCollectionQueryVariables = Exact<{
    filter?: Maybe<EmailTemplateFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type EmailTemplateCollectionQuery = { __typename?: 'Query' } & {
    EmailTemplateCollection?: Maybe<
        { __typename?: 'EmailTemplateCollection' } & Pick<EmailTemplateCollection, 'totalResults'> & {
                emailTemplates?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'EmailTemplate' } & Pick<
                                EmailTemplate,
                                'id' | 'createdAt' | 'updatedAt' | 'name'
                            > & {
                                    emailTemplateLocales: Array<
                                        { __typename?: 'EmailTemplateLocale' } & Pick<
                                            EmailTemplateLocale,
                                            'id' | 'locale'
                                        >
                                    >
                                }
                        >
                    >
                >
            }
    >
}

export type RemoveEmailTemplateMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type RemoveEmailTemplateMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeEmailTemplate'>

export type SendTestEmailMutationVariables = Exact<{
    id: Scalars['ID']
    recipientEmailAddress: Scalars['String']
}>

export type SendTestEmailMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'sendTestEmail'>

export type UpsertEmailTemplateMutationVariables = Exact<{
    id?: Maybe<Scalars['ID']>
    input: EmailTemplateInput
}>

export type UpsertEmailTemplateMutation = { __typename?: 'Mutation' } & {
    upsertEmailTemplate?: Maybe<
        { __typename?: 'EmailTemplate' } & Pick<EmailTemplate, 'id' | 'name'> & {
                emailTemplateLocales: Array<
                    { __typename?: 'EmailTemplateLocale' } & Pick<
                        EmailTemplateLocale,
                        'id' | 'locale' | 'rawTemplateContent'
                    >
                >
            }
    >
}

export type EventCollectionQueryVariables = Exact<{
    filter?: Maybe<EventFilter>
}>

export type EventCollectionQuery = { __typename?: 'Query' } & {
    EventCollection?: Maybe<
        { __typename?: 'EventCollection' } & Pick<EventCollection, 'totalResults'> & {
                events?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Event' } & Pick<
                                Event,
                                'id' | 'timestamp' | 'type' | 'entityType' | 'entityId'
                            > & {
                                    data?: Maybe<
                                        Array<
                                            { __typename?: 'EventData' } & Pick<EventData, 'property' | 'from' | 'to'>
                                        >
                                    >
                                    user?: Maybe<
                                        { __typename?: 'User' } & Pick<User, 'id'> & {
                                                profile?: Maybe<
                                                    { __typename?: 'UserProfile' } & Pick<
                                                        UserProfile,
                                                        | 'id'
                                                        | 'firstName'
                                                        | 'lastNamePrefix'
                                                        | 'lastName'
                                                        | 'emailAddress'
                                                    >
                                                >
                                            }
                                    >
                                }
                        >
                    >
                >
            }
    >
}

export type AllResidualValueTablesWithDataQueryVariables = Exact<{ [key: string]: never }>

export type AllResidualValueTablesWithDataQuery = { __typename?: 'Query' } & {
    allResidualValueTables?: Maybe<
        Array<
            Maybe<
                | ({ __typename?: 'ResidualValueTable' } & Pick<ResidualValueTable, 'id' | 'name'> & {
                          residualValues?: Maybe<
                              Array<
                                  Maybe<
                                      { __typename?: 'ResidualValue' } & Pick<
                                          ResidualValue,
                                          'id' | 'ageInMonths' | 'mileage' | 'value'
                                      >
                                  >
                              >
                          >
                      })
                | { __typename?: 'EntityNotFoundError' }
            >
        >
    >
}

export type DuplicatePricingConfigurationCalculationMutationVariables = Exact<{
    id: Scalars['ID']
    name: Scalars['String']
    tenant?: Maybe<Scalars['String']>
}>

export type DuplicatePricingConfigurationCalculationMutation = { __typename?: 'Mutation' } & {
    duplicatePricingConfigurationCalculation?: Maybe<
        { __typename?: 'ConfigurationCalculation' } & PricingConfigurationCalculationFragmentFragment
    >
}

export type PricingConfigurationCalculationQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type PricingConfigurationCalculationQuery = { __typename?: 'Query' } & {
    PricingConfigurationCalculation?: Maybe<
        | ({ __typename?: 'ConfigurationCalculation' } & PricingConfigurationCalculationFragmentFragment)
        | { __typename?: 'EntityNotFoundError' }
    >
}

export type PricingConfigurationCalculationFragmentFragment = { __typename?: 'ConfigurationCalculation' } & Pick<
    ConfigurationCalculation,
    'id' | 'updatedAt' | 'name' | 'description'
> & {
        components?: Maybe<
            Array<
                Maybe<
                    | ({
                          __typename?: 'PricingConfigurationComponentMargin'
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentMargin_Fragment)
                    | ({
                          __typename?: 'PricingConfigurationComponentInterest'
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentInterest_Fragment)
                    | ({
                          __typename?: 'PricingConfigurationComponentInsurance'
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentInsurance_Fragment)
                    | ({
                          __typename?: 'PricingConfigurationComponentDepreciation'
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentDepreciation_Fragment)
                    | ({
                          __typename?: 'PricingConfigurationComponentMaintenance'
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentMaintenance_Fragment)
                    | ({
                          __typename?: 'PricingConfigurationComponentReplacementTransport'
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentReplacementTransport_Fragment)
                    | ({
                          __typename?: 'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn'
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomMonthlyLeasePriceAddOn_Fragment)
                    | ({ __typename?: 'PricingConfigurationComponentCustomResidualValue' } & Pick<
                          PricingConfigurationComponentCustomResidualValue,
                          'name' | 'description' | 'fiscalValue' | 'residualValuePercentage'
                      > &
                          PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomResidualValue_Fragment)
                    | ({ __typename?: 'PricingConfigurationComponentConditional' } & {
                          conditionBlocks: Array<
                              { __typename?: 'PricingConditionBlock' } & {
                                  conditions?: Maybe<
                                      { __typename?: 'PricingCondition' } & {
                                          AND?: Maybe<
                                              Array<
                                                  Maybe<
                                                      { __typename?: 'PricingCondition' } & {
                                                          condition?: Maybe<
                                                              | ({ __typename: 'PricingConditionLeasingType' } & Pick<
                                                                    PricingConditionLeasingType,
                                                                    'property' | 'operator' | 'leasingType'
                                                                >)
                                                              | ({ __typename: 'PricingConditionFiscalValue' } & Pick<
                                                                    PricingConditionFiscalValue,
                                                                    'property' | 'operator' | 'fiscalValue'
                                                                >)
                                                              | ({ __typename: 'PricingConditionCarOption' } & Pick<
                                                                    PricingConditionCarOption,
                                                                    'property' | 'operator' | 'optionId'
                                                                >)
                                                          >
                                                      }
                                                  >
                                              >
                                          >
                                      }
                                  >
                                  components: Array<
                                      | ({
                                            __typename?: 'PricingConfigurationComponentMargin'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentMargin_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentInterest'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentInterest_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentInsurance'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentInsurance_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentDepreciation'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentDepreciation_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentMaintenance'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentMaintenance_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentReplacementTransport'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentReplacementTransport_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomMonthlyLeasePriceAddOn_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentCustomResidualValue'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomResidualValue_Fragment)
                                      | ({
                                            __typename?: 'PricingConfigurationComponentConditional'
                                        } & PricingConfigurationCalculationComponents_PricingConfigurationComponentConditional_Fragment)
                                  >
                              }
                          >
                          componentsDefault: Array<
                              | ({
                                    __typename?: 'PricingConfigurationComponentMargin'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentMargin_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentInterest'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentInterest_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentInsurance'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentInsurance_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentDepreciation'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentDepreciation_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentMaintenance'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentMaintenance_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentReplacementTransport'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentReplacementTransport_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomMonthlyLeasePriceAddOn_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentCustomResidualValue'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomResidualValue_Fragment)
                              | ({
                                    __typename?: 'PricingConfigurationComponentConditional'
                                } & PricingConfigurationCalculationComponents_PricingConfigurationComponentConditional_Fragment)
                          >
                      } & PricingConfigurationCalculationComponents_PricingConfigurationComponentConditional_Fragment)
                >
            >
        >
    }

type PricingConfigurationCalculationComponents_PricingConfigurationComponentMargin_Fragment = {
    __typename: 'PricingConfigurationComponentMargin'
} & Pick<PricingConfigurationComponentMargin, 'percentage'>

type PricingConfigurationCalculationComponents_PricingConfigurationComponentInterest_Fragment = {
    __typename: 'PricingConfigurationComponentInterest'
} & Pick<PricingConfigurationComponentInterest, 'percentage'>

type PricingConfigurationCalculationComponents_PricingConfigurationComponentInsurance_Fragment = {
    __typename: 'PricingConfigurationComponentInsurance'
} & Pick<
    PricingConfigurationComponentInsurance,
    'liability' | 'insurancePremiumTaxPercentage' | 'insuranceMarkupPercentage'
> & {
        hull: { __typename?: 'PricingConfigurationComponentHull' } & Pick<
            PricingConfigurationComponentHull,
            'startingAtFiscalValue' | 'endingAtFiscalValue' | 'amountExVATPerStep' | 'perStepInFiscalValue'
        >
        deductible?: Maybe<
            { __typename?: 'PricingConfigurationComponentInsuranceDeductible' } & Pick<
                PricingConfigurationComponentInsuranceDeductible,
                'higher' | 'regular' | 'lower'
            >
        >
    }

type PricingConfigurationCalculationComponents_PricingConfigurationComponentDepreciation_Fragment = {
    __typename: 'PricingConfigurationComponentDepreciation'
} & { residualValueTable?: Maybe<{ __typename?: 'ResidualValueTable' } & Pick<ResidualValueTable, 'id'>> }

type PricingConfigurationCalculationComponents_PricingConfigurationComponentMaintenance_Fragment = {
    __typename: 'PricingConfigurationComponentMaintenance'
} & Pick<PricingConfigurationComponentMaintenance, 'amountExVATPerDistanceUnit'>

type PricingConfigurationCalculationComponents_PricingConfigurationComponentReplacementTransport_Fragment = {
    __typename: 'PricingConfigurationComponentReplacementTransport'
} & Pick<
    PricingConfigurationComponentReplacementTransport,
    'amountExVATUntilCutOff' | 'cutOffMileage' | 'perMileageStepAboveCutOff' | 'amountExVATPerStepAboveCutOff'
>

type PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomMonthlyLeasePriceAddOn_Fragment = {
    __typename: 'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn'
} & Pick<PricingConfigurationComponentCustomMonthlyLeasePriceAddOn, 'name' | 'description' | 'monthlyAddOnAmountExVAT'>

type PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomResidualValue_Fragment = {
    __typename: 'PricingConfigurationComponentCustomResidualValue'
}

type PricingConfigurationCalculationComponents_PricingConfigurationComponentConditional_Fragment = {
    __typename: 'PricingConfigurationComponentConditional'
}

export type PricingConfigurationCalculationComponentsFragment =
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentMargin_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentInterest_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentInsurance_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentDepreciation_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentMaintenance_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentReplacementTransport_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomMonthlyLeasePriceAddOn_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentCustomResidualValue_Fragment
    | PricingConfigurationCalculationComponents_PricingConfigurationComponentConditional_Fragment

export type PricingConfigurationCalculationCollectionQueryVariables = Exact<{
    filter?: Maybe<PricingConfigurationCalculationFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type PricingConfigurationCalculationCollectionQuery = { __typename?: 'Query' } & {
    PricingConfigurationCalculationCollection?: Maybe<
        { __typename?: 'ConfigurationCalculationCollection' } & Pick<
            ConfigurationCalculationCollection,
            'totalResults'
        > & {
                configurationCalculations?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'ConfigurationCalculation' } & Pick<
                                ConfigurationCalculation,
                                'id' | 'createdAt' | 'updatedAt' | 'name' | 'description'
                            > & {
                                    components?: Maybe<
                                        Array<
                                            Maybe<
                                                | { __typename: 'PricingConfigurationComponentMargin' }
                                                | { __typename: 'PricingConfigurationComponentInterest' }
                                                | { __typename: 'PricingConfigurationComponentInsurance' }
                                                | { __typename: 'PricingConfigurationComponentDepreciation' }
                                                | { __typename: 'PricingConfigurationComponentMaintenance' }
                                                | { __typename: 'PricingConfigurationComponentReplacementTransport' }
                                                | {
                                                      __typename: 'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn'
                                                  }
                                                | { __typename: 'PricingConfigurationComponentCustomResidualValue' }
                                                | { __typename: 'PricingConfigurationComponentConditional' }
                                            >
                                        >
                                    >
                                }
                        >
                    >
                >
            }
    >
}

export type PricingPreviewQueryVariables = Exact<{
    input: PricingPreviewInput
}>

export type PricingPreviewQuery = { __typename?: 'Query' } & {
    PricingPreview?: Maybe<
        { __typename?: 'PricingPreview' } & Pick<
            PricingPreview,
            'leasingPriceExVAT' | 'leasingPriceIncVAT' | 'fiscalValue'
        > & {
                components?: Maybe<
                    Array<
                        Maybe<
                            | ({ __typename: 'PricingCalculationComponentMargin' } & Pick<
                                  PricingCalculationComponentMargin,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          { __typename?: 'PricingConfigurationComponentMargin' } & Pick<
                                              PricingConfigurationComponentMargin,
                                              'percentage'
                                          >
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentInterest' } & Pick<
                                  PricingCalculationComponentInterest,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          { __typename?: 'PricingConfigurationComponentInterest' } & Pick<
                                              PricingConfigurationComponentInterest,
                                              'percentage'
                                          >
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentInsurance' } & Pick<
                                  PricingCalculationComponentInsurance,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          { __typename?: 'PricingConfigurationComponentInsurance' } & Pick<
                                              PricingConfigurationComponentInsurance,
                                              | 'liability'
                                              | 'insuranceMarkupPercentage'
                                              | 'insurancePremiumTaxPercentage'
                                          > & {
                                                  hull: { __typename?: 'PricingConfigurationComponentHull' } & Pick<
                                                      PricingConfigurationComponentHull,
                                                      | 'startingAtFiscalValue'
                                                      | 'endingAtFiscalValue'
                                                      | 'perStepInFiscalValue'
                                                      | 'amountExVATPerStep'
                                                  >
                                                  deductible?: Maybe<
                                                      {
                                                          __typename?: 'PricingConfigurationComponentInsuranceDeductible'
                                                      } & Pick<
                                                          PricingConfigurationComponentInsuranceDeductible,
                                                          'higher' | 'regular' | 'lower'
                                                      >
                                                  >
                                              }
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentDepreciation' } & Pick<
                                  PricingCalculationComponentDepreciation,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          { __typename?: 'PricingConfigurationComponentDepreciationPercentage' } & Pick<
                                              PricingConfigurationComponentDepreciationPercentage,
                                              'residualValuePercentage'
                                          >
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentMaintenance' } & Pick<
                                  PricingCalculationComponentMaintenance,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          { __typename?: 'PricingConfigurationComponentMaintenance' } & Pick<
                                              PricingConfigurationComponentMaintenance,
                                              'amountExVATPerDistanceUnit'
                                          >
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentReplacementTransport' } & Pick<
                                  PricingCalculationComponentReplacementTransport,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          { __typename?: 'PricingConfigurationComponentReplacementTransport' } & Pick<
                                              PricingConfigurationComponentReplacementTransport,
                                              | 'amountExVATUntilCutOff'
                                              | 'cutOffMileage'
                                              | 'perMileageStepAboveCutOff'
                                              | 'amountExVATPerStepAboveCutOff'
                                          >
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentCustomMonthlyLeasePriceAddOn' } & Pick<
                                  PricingCalculationComponentCustomMonthlyLeasePriceAddOn,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          {
                                              __typename?: 'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn'
                                          } & Pick<PricingConfigurationComponentCustomMonthlyLeasePriceAddOn, 'name'>
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentCustomResidualValue' } & Pick<
                                  PricingCalculationComponentCustomResidualValue,
                                  'amountExVAT'
                              > & {
                                      component?: Maybe<
                                          { __typename?: 'PricingConfigurationComponentCustomResidualValue' } & Pick<
                                              PricingConfigurationComponentCustomResidualValue,
                                              'name'
                                          >
                                      >
                                  })
                            | ({ __typename: 'PricingCalculationComponentTire' } & Pick<
                                  PricingCalculationComponentTire,
                                  'amountExVAT'
                              >)
                            | ({ __typename: 'PricingCalculationComponentAfterDelivery' } & Pick<
                                  PricingCalculationComponentAfterDelivery,
                                  'amountExVAT'
                              >)
                            | ({ __typename: 'PricingCalculationComponentLeaseEditionOption' } & Pick<
                                  PricingCalculationComponentLeaseEditionOption,
                                  'amountExVAT'
                              >)
                            | { __typename: 'PricingCalculationComponentDownpayment' }
                            | { __typename: 'PricingCalculationReferralDiscount' }
                        >
                    >
                >
            }
    >
}

export type RemoveConfigurationCalculationMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type RemoveConfigurationCalculationMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeConfigurationCalculation'
>

export type UpsertPricingConfigurationCalculationMutationVariables = Exact<{
    id?: Maybe<Scalars['ID']>
    input: PricingConfigurationCalculationInput
}>

export type UpsertPricingConfigurationCalculationMutation = { __typename?: 'Mutation' } & {
    upsertPricingConfigurationCalculation?: Maybe<
        { __typename?: 'ConfigurationCalculation' } & PricingConfigurationCalculationFragmentFragment
    >
}

export type AllUserProfilesQueryVariables = Exact<{
    filter?: Maybe<UserProfileFilter>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type AllUserProfilesQuery = { __typename?: 'Query' } & {
    allUserProfiles?: Maybe<
        { __typename?: 'AllUserProfilesResult' } & Pick<AllUserProfilesResult, 'totalResults'> & {
                userProfiles?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'UserProfile' } & Pick<
                                UserProfile,
                                | 'id'
                                | 'userId'
                                | 'firstName'
                                | 'lastNamePrefix'
                                | 'lastName'
                                | 'emailAddress'
                                | 'telephoneNumber'
                            >
                        >
                    >
                >
            }
    >
}

export type ContractsByDriverQueryVariables = Exact<{
    driverId?: Maybe<Scalars['String']>
}>

export type ContractsByDriverQuery = { __typename?: 'Query' } & {
    ContractCollection?: Maybe<
        { __typename?: 'ContractCollection' } & Pick<ContractCollection, 'totalResults'> & {
                contracts?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Contract' } & Pick<Contract, 'id' | 'startDate' | 'endDate'> & {
                                    car?: Maybe<{ __typename?: 'Car' } & Pick<Car, 'licensePlate'>>
                                }
                        >
                    >
                >
            }
    >
}

export type CreateLoginUrlMutationVariables = Exact<{
    userId: Scalars['ID']
    path: Scalars['String']
}>

export type CreateLoginUrlMutation = { __typename?: 'Mutation' } & {
    createLoginUrl?: Maybe<{ __typename?: 'LoginUrlPayload' } & Pick<LoginUrlPayload, 'url'>>
}

export type CreateUserMutationVariables = Exact<{
    emailAddress: Scalars['String']
}>

export type CreateUserMutation = { __typename?: 'Mutation' } & {
    createUser?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                profile?: Maybe<{ __typename?: 'UserProfile' } & Pick<UserProfile, 'id'>>
            }
    >
}

export type EditCheckResultMutationVariables = Exact<{
    userId: Scalars['ID']
}>

export type EditCheckResultMutation = { __typename?: 'Mutation' } & {
    editCheckResult?: Maybe<{ __typename?: 'Result' } & Pick<Result, 'result'>>
}

export type IdCheckStatusQueryVariables = Exact<{
    userId?: Maybe<Scalars['ID']>
    emailAddress?: Maybe<Scalars['String']>
}>

export type IdCheckStatusQuery = { __typename?: 'Query' } & {
    User?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                idCheckStatus?: Maybe<
                    { __typename?: 'IdCheckStatus' } & Pick<IdCheckStatus, 'result'> & {
                            check?: Maybe<
                                Array<
                                    Maybe<
                                        { __typename?: 'Check' } & Pick<
                                            Check,
                                            'id' | 'result' | 'status' | 'checkPartsNotClear'
                                        > & {
                                                reports?: Maybe<
                                                    Array<
                                                        Maybe<
                                                            { __typename?: 'IdReportStatus' } & Pick<
                                                                IdReportStatus,
                                                                'id' | 'name' | 'result' | 'status'
                                                            >
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            >
                        }
                >
            }
    >
}

export type RecentDealsByOwnerQueryVariables = Exact<{
    ownerId: Scalars['String']
}>

export type RecentDealsByOwnerQuery = { __typename?: 'Query' } & {
    DealCollection?: Maybe<
        { __typename?: 'DealCollection' } & {
            deals?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'Deal' } & Pick<Deal, 'id' | 'editionName' | 'modelName' | 'status'> & {
                                pricing: { __typename?: 'Pricing' } & Pick<
                                    Pricing,
                                    'pricePerMonthExVAT' | 'pricePerMonthIncVAT' | 'incVAT'
                                >
                            }
                    >
                >
            >
        }
    >
}

export type SetUserRolesMutationVariables = Exact<{
    userId: Scalars['ID']
    role?: Maybe<Array<Maybe<Role>> | Maybe<Role>>
}>

export type SetUserRolesMutation = { __typename?: 'Mutation' } & {
    setUserRoles?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'roles'>>
}

export type SignupUserMutationVariables = Exact<{
    emailAddress: Scalars['String']
}>

export type SignupUserMutation = { __typename?: 'Mutation' } & {
    signupUser?: Maybe<{ __typename?: 'JsonWebToken' } & Pick<JsonWebToken, 'userId'>>
}

export type UpdateUserProfileMutationVariables = Exact<{
    id: Scalars['ID']
    emailAddress?: Maybe<Scalars['String']>
    firstName?: Maybe<Scalars['String']>
    lastNamePrefix?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    telephoneNumber?: Maybe<Scalars['String']>
    address?: Maybe<UserAddressInput>
}>

export type UpdateUserProfileMutation = { __typename?: 'Mutation' } & {
    updateUserProfile?: Maybe<
        { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            'id' | 'userId' | 'emailAddress' | 'firstName' | 'lastNamePrefix' | 'lastName' | 'telephoneNumber'
        > & {
                address?: Maybe<
                    { __typename?: 'UserAddress' } & Pick<
                        UserAddress,
                        'street' | 'houseNumber' | 'houseNumberSuffix' | 'zipcode' | 'city'
                    >
                >
            }
    >
}

export type UserQueryVariables = Exact<{
    userId?: Maybe<Scalars['ID']>
    emailAddress?: Maybe<Scalars['String']>
}>

export type UserQuery = { __typename?: 'Query' } & {
    User?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'emailAddress' | 'roles'> & {
                profile?: Maybe<
                    { __typename?: 'UserProfile' } & Pick<
                        UserProfile,
                        | 'id'
                        | 'referralCode'
                        | 'firstName'
                        | 'lastName'
                        | 'lastNamePrefix'
                        | 'telephoneNumber'
                        | 'emailAddress'
                    > & {
                            address?: Maybe<
                                { __typename?: 'UserAddress' } & Pick<
                                    UserAddress,
                                    'id' | 'city' | 'houseNumber' | 'houseNumberSuffix' | 'street' | 'zipcode'
                                >
                            >
                        }
                >
            }
    >
}

export type UserEmailQueryVariables = Exact<{
    userId?: Maybe<Scalars['ID']>
}>

export type UserEmailQuery = { __typename?: 'Query' } & {
    User?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'emailAddress'>>
}

export type UserAvatarQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type UserAvatarQuery = { __typename?: 'Query' } & {
    User?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'emailAddress'>>
}

export type GetAccountManagersQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountManagersQuery = { __typename?: 'Query' } & {
    getAccountManagers?: Maybe<
        Array<
            Maybe<
                { __typename?: 'UserProfile' } & Pick<
                    UserProfile,
                    'id' | 'userId' | 'emailAddress' | 'firstName' | 'lastNamePrefix' | 'lastName'
                >
            >
        >
    >
}

export type AllReceiverEmailOptionsQueryVariables = Exact<{ [key: string]: never }>

export type AllReceiverEmailOptionsQuery = { __typename?: 'Query' } & Pick<Query, 'allReceiverEmailOptions'>

export type RemoveWorkflowMutationVariables = Exact<{
    id: Scalars['ID']
}>

export type RemoveWorkflowMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeWorkflow'>

export type UpsertWorkflowMutationVariables = Exact<{
    id?: Maybe<Scalars['ID']>
    input: WorkflowInput
}>

export type UpsertWorkflowMutation = { __typename?: 'Mutation' } & {
    upsertWorkflow?: Maybe<{ __typename?: 'Workflow' } & WorkflowFragmentFragment>
}

export type WorkflowQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type WorkflowQuery = { __typename?: 'Query' } & {
    Workflow?: Maybe<{ __typename?: 'Workflow' } & WorkflowFragmentFragment>
}

export type WorkflowFragmentFragment = { __typename?: 'Workflow' } & Pick<Workflow, 'id' | 'name'> & {
        conditions: Array<{ __typename?: 'WorkflowCondition' } & WorkflowConditionFragmentFragment>
        actions: Array<
            | ({ __typename?: 'WorkflowActionBranch' } & {
                  conditions: Array<{ __typename?: 'WorkflowCondition' } & WorkflowConditionFragmentFragment>
                  acceptActions: Array<
                      | ({ __typename?: 'WorkflowActionBranch' } & {
                            conditions: Array<{ __typename?: 'WorkflowCondition' } & WorkflowConditionFragmentFragment>
                            acceptActions: Array<
                                | { __typename?: 'WorkflowActionBranch' }
                                | ({ __typename?: 'WorkflowActionEmail' } & WorkflowActionEmailFragmentFragment)
                                | ({ __typename?: 'WorkflowActionSlack' } & WorkflowActionSlackFragmentFragment)
                            >
                            rejectActions: Array<
                                | { __typename?: 'WorkflowActionBranch' }
                                | ({ __typename?: 'WorkflowActionEmail' } & WorkflowActionEmailFragmentFragment)
                                | ({ __typename?: 'WorkflowActionSlack' } & WorkflowActionSlackFragmentFragment)
                            >
                        })
                      | ({ __typename?: 'WorkflowActionEmail' } & WorkflowActionEmailFragmentFragment)
                      | ({ __typename?: 'WorkflowActionSlack' } & WorkflowActionSlackFragmentFragment)
                  >
                  rejectActions: Array<
                      | ({ __typename?: 'WorkflowActionBranch' } & {
                            conditions: Array<{ __typename?: 'WorkflowCondition' } & WorkflowConditionFragmentFragment>
                            acceptActions: Array<
                                | { __typename?: 'WorkflowActionBranch' }
                                | ({ __typename?: 'WorkflowActionEmail' } & WorkflowActionEmailFragmentFragment)
                                | ({ __typename?: 'WorkflowActionSlack' } & WorkflowActionSlackFragmentFragment)
                            >
                            rejectActions: Array<
                                | { __typename?: 'WorkflowActionBranch' }
                                | ({ __typename?: 'WorkflowActionEmail' } & WorkflowActionEmailFragmentFragment)
                                | ({ __typename?: 'WorkflowActionSlack' } & WorkflowActionSlackFragmentFragment)
                            >
                        })
                      | ({ __typename?: 'WorkflowActionEmail' } & WorkflowActionEmailFragmentFragment)
                      | ({ __typename?: 'WorkflowActionSlack' } & WorkflowActionSlackFragmentFragment)
                  >
              })
            | ({ __typename?: 'WorkflowActionEmail' } & WorkflowActionEmailFragmentFragment)
            | ({ __typename?: 'WorkflowActionSlack' } & WorkflowActionSlackFragmentFragment)
        >
    }

export type WorkflowConditionFragmentFragment = { __typename?: 'WorkflowCondition' } & Pick<
    WorkflowCondition,
    'entity'
> & {
        requirements: Array<
            { __typename?: 'WorkflowRequirement' } & Pick<WorkflowRequirement, 'property'> & {
                    filter: Array<{ __typename?: 'WorkflowFilter' } & Pick<WorkflowFilter, 'type' | 'field'>>
                }
        >
    }

export type WorkflowActionEmailFragmentFragment = { __typename?: 'WorkflowActionEmail' } & Pick<
    WorkflowActionEmail,
    'senderName' | 'senderEmail' | 'receiverEmail'
> & {
        emailTemplate: { __typename?: 'EmailTemplate' } & Pick<EmailTemplate, 'id' | 'name'> & {
                emailTemplateLocales: Array<
                    { __typename?: 'EmailTemplateLocale' } & Pick<EmailTemplateLocale, 'id' | 'locale'>
                >
            }
    }

export type WorkflowActionSlackFragmentFragment = { __typename?: 'WorkflowActionSlack' } & Pick<
    WorkflowActionSlack,
    'channel'
> & { message: { __typename?: 'SlackMessage' } & Pick<SlackMessage, 'title' | 'content'> }

export type WorkflowCollectionQueryVariables = Exact<{
    filter?: Maybe<WorkflowFilterInput>
    pagination?: Maybe<Pagination>
    orderBy?: Maybe<OrderBy>
}>

export type WorkflowCollectionQuery = { __typename?: 'Query' } & {
    WorkflowCollection?: Maybe<
        { __typename?: 'WorkflowCollection' } & Pick<WorkflowCollection, 'totalResults'> & {
                workflows?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Workflow' } & Pick<Workflow, 'id' | 'createdAt' | 'name'> & {
                                    actions: Array<
                                        | ({ __typename: 'WorkflowActionBranch' } & {
                                              acceptActions: Array<
                                                  | ({ __typename: 'WorkflowActionBranch' } & {
                                                        acceptActions: Array<
                                                            | { __typename: 'WorkflowActionBranch' }
                                                            | { __typename: 'WorkflowActionEmail' }
                                                            | { __typename: 'WorkflowActionSlack' }
                                                        >
                                                        rejectActions: Array<
                                                            | { __typename: 'WorkflowActionBranch' }
                                                            | { __typename: 'WorkflowActionEmail' }
                                                            | { __typename: 'WorkflowActionSlack' }
                                                        >
                                                    })
                                                  | { __typename: 'WorkflowActionEmail' }
                                                  | { __typename: 'WorkflowActionSlack' }
                                              >
                                              rejectActions: Array<
                                                  | ({ __typename: 'WorkflowActionBranch' } & {
                                                        acceptActions: Array<
                                                            | { __typename: 'WorkflowActionBranch' }
                                                            | { __typename: 'WorkflowActionEmail' }
                                                            | { __typename: 'WorkflowActionSlack' }
                                                        >
                                                        rejectActions: Array<
                                                            | { __typename: 'WorkflowActionBranch' }
                                                            | { __typename: 'WorkflowActionEmail' }
                                                            | { __typename: 'WorkflowActionSlack' }
                                                        >
                                                    })
                                                  | { __typename: 'WorkflowActionEmail' }
                                                  | { __typename: 'WorkflowActionSlack' }
                                              >
                                          })
                                        | { __typename: 'WorkflowActionEmail' }
                                        | { __typename: 'WorkflowActionSlack' }
                                    >
                                    conditions: Array<
                                        { __typename?: 'WorkflowCondition' } & {
                                            requirements: Array<
                                                { __typename?: 'WorkflowRequirement' } & {
                                                    filter: Array<
                                                        { __typename?: 'WorkflowFilter' } & Pick<
                                                            WorkflowFilter,
                                                            'field'
                                                        >
                                                    >
                                                }
                                            >
                                        }
                                    >
                                }
                        >
                    >
                >
            }
    >
}

export const CarFragmentFragmentDoc = gql`
    fragment CarFragment on Car {
        id
        createdAt
        updatedAt
        VIN
        entity {
            id
            name
        }
        licensePlate
        firstRegistrationDate
        currentMileage
        currentMileageDate
        fiscalValueIncVAT
        brandName
        modelName
        editionName
        bookValueExVAT
        marketValueExVAT
        options {
            id
            label
            manufacturerCode
            category
        }
        images {
            mainImageUrl
            interiorImageUrl
            exteriorImageUrl
            urlsAdditionalImages
        }
        contracts {
            id
            contractNumber
            leasingType
            lessee {
                ... on ContractPerson {
                    id
                    name
                }
                ... on ContractCompany {
                    id
                    companyName: name
                }
            }
            driver {
                fullName
            }
            startDate
            endDate
        }
    }
`
export const BooleanDealOptionFragmentFragmentDoc = gql`
    fragment BooleanDealOptionFragment on BooleanDealOption {
        id
        key
        value
    }
`
export const SelectableDealOptionFragmentFragmentDoc = gql`
    fragment SelectableDealOptionFragment on SelectableDealOption {
        id
        name: key
        selectedValue: value {
            id
            label
            editionLabel
        }
        selectableValues {
            id
            label
            editionLabel
        }
    }
`
export const ContractDetailSigningPersonFragmentFragmentDoc = gql`
    fragment ContractDetailSigningPersonFragment on SigningPerson {
        id
        userId
        emailAddress
        signed
        signingStatus
        signature {
            ... on IdealSignature {
                id
                accountName
                iban
                paymentId
                status
            }
            ... on DocumentSignature {
                id
                verifiedByUserId
                verificationTimestamp
                document {
                    id
                    retrievalUrl
                }
            }
        }
    }
`
export const ContractDetailFragmentFragmentDoc = gql`
    fragment ContractDetailFragment on Deal {
        id
        status
        optionsDescription
        internalOwnerId
        locale
        options {
            __typename
            ... on BooleanDealOption {
                ...BooleanDealOptionFragment
            }
            ... on SelectableDealOption {
                ...SelectableDealOptionFragment
            }
        }
        lessee {
            __typename
            ... on LesseePerson {
                id
                emailAddress
                firstName
                lastNamePrefix
                lastName
                kcComment
                kcStatus
                address {
                    street
                    houseNumber
                    houseNumberSuffix
                    zipcode
                    city
                }
            }
            ... on LesseeCompany {
                id
                name
                chamberOfCommerceNr
                kcStatus
                kvkStatus
                postalAddress {
                    street
                    houseNumber
                    houseNumberSuffix
                    zipcode
                    city
                }
            }
        }
        lessor {
            id
            name
        }
        driver {
            ... on DealDriver {
                id
                firstName
                lastNamePrefix
                lastName
                licenseVerified
                emailAddress
                userId
                phonenumber
                address {
                    id
                    street
                    houseNumber
                    houseNumberSuffix
                    zipcode
                    city
                }
            }
        }
        contact {
            id
            firstName
            lastNamePrefix
            lastName
            emailAddress
            phonenumber
        }
        signingPersons {
            ...ContractDetailSigningPersonFragment
        }
        pricing {
            id
            pricePerMonthExVAT
            pricePerMonthIncVAT
            fiscalValue
            incVAT
            margin
            lessKmPriceExVAT
            moreKmPriceExVAT
        }
        editionName
        modelName
        dealType
        billingAccount {
            id
            iban
            emailAddress
            accountHolder
        }
        referralCode
        referralProgram {
            id
        }
        manufacturerReferralCode
        manufacturerAccountEmailAddress
        requirements {
            isCompanyRequired
            isDriverRequired
        }
        remarks
        assetOwnerComment
    }
    ${BooleanDealOptionFragmentFragmentDoc}
    ${SelectableDealOptionFragmentFragmentDoc}
    ${ContractDetailSigningPersonFragmentFragmentDoc}
`
export const DocumentFragmentFragmentDoc = gql`
    fragment DocumentFragment on Document {
        id
        createdAt
        updatedAt
        name
        description
        category
        mimeType
        readonly
        metadata {
            ... on ImageMetadata {
                sizeInBytes
                height
                width
            }
            ... on PdfMetadata {
                sizeInBytes
                pages
            }
            ... on MiscellaneousMetadata {
                sizeInBytes
            }
        }
        bucketType
        bucketId
        retrievalUrl
    }
`
export const DealRequirementsFragmentFragmentDoc = gql`
    fragment DealRequirementsFragment on DealRequirements {
        id
        isCompanyRequired
        isDriverRequired
    }
`
export const SigningPersonFragmentFragmentDoc = gql`
    fragment SigningPersonFragment on SigningPerson {
        id
        userId
        emailAddress
        signed
        signingStatus
        signature {
            ... on IdealSignature {
                id
                accountName
                iban
                paymentId
                status
            }
            ... on DocumentSignature {
                id
                verified
            }
        }
    }
`
export const ContactFragmentFragmentDoc = gql`
    fragment ContactFragment on DealContact {
        id
        firstName
        lastNamePrefix
        lastName
        emailAddress
        phonenumber
    }
`
export const BillingAccountFragmentFragmentDoc = gql`
    fragment BillingAccountFragment on BillingAccount {
        id
        accountHolder
        iban
        emailAddress
    }
`
export const LesseeCompanyFragmentFragmentDoc = gql`
    fragment LesseeCompanyFragment on LesseeCompany {
        id
        chamberOfCommerceNr
        name
        postalAddress {
            id
            street
            houseNumber
            houseNumberSuffix
            zipcode
            city
        }
        kvkStatus
        kcStatus
    }
`
export const LesseePersonFragmentFragmentDoc = gql`
    fragment LesseePersonFragment on LesseePerson {
        id
        userId
        emailAddress
        phonenumber
        address {
            id
            street
            houseNumber
            houseNumberSuffix
            zipcode
            city
        }
        firstName
        lastName
        lastNamePrefix
        personKcStatus: kcStatus
    }
`
export const DriverFragmentFragmentDoc = gql`
    fragment DriverFragment on DealDriver {
        id
        userId
        emailAddress
        phonenumber
        address {
            id
            street
            houseNumber
            houseNumberSuffix
            zipcode
            city
        }
        licenseVerified
        firstName
        lastName
        lastNamePrefix
    }
`
export const PricingFragmentFragmentDoc = gql`
    fragment PricingFragment on Pricing {
        id
        pricePerMonthIncVAT
        pricePerMonthExVAT
        incVAT
        fiscalValue
        margin
        lessKmPriceExVAT
        moreKmPriceExVAT
        privateLeaseLessKmPriceIncVAT
        privateLeaseMoreKmPriceIncVAT
    }
`
export const BaseDealFieldsFragmentDoc = gql`
    fragment baseDealFields on Deal {
        id
        locale
        options {
            ... on BooleanDealOption {
                ...BooleanDealOptionFragment
            }
            ... on SelectableDealOption {
                ...SelectableDealOptionFragment
            }
        }
        lessee {
            ... on LesseeCompany {
                ...LesseeCompanyFragment
            }
            ... on LesseePerson {
                ...LesseePersonFragment
            }
        }
        driver {
            ... on DealDriver {
                ...DriverFragment
            }
        }
        pricing {
            ...PricingFragment
        }
        referralCode
        referralProgram {
            id
            name
        }
        carId
        dealType
        modelName
        editionName
    }
    ${BooleanDealOptionFragmentFragmentDoc}
    ${SelectableDealOptionFragmentFragmentDoc}
    ${LesseeCompanyFragmentFragmentDoc}
    ${LesseePersonFragmentFragmentDoc}
    ${DriverFragmentFragmentDoc}
    ${PricingFragmentFragmentDoc}
`
export const PricingConfigurationCalculationComponentsFragmentDoc = gql`
    fragment PricingConfigurationCalculationComponents on PricingConfigurationComponent {
        __typename
        ... on PricingConfigurationComponentDepreciation {
            residualValueTable {
                id
            }
        }
        ... on PricingConfigurationComponentInsurance {
            liability
            hull {
                startingAtFiscalValue
                endingAtFiscalValue
                amountExVATPerStep
                perStepInFiscalValue
            }
            insurancePremiumTaxPercentage
            insuranceMarkupPercentage
            deductible {
                higher
                regular
                lower
            }
        }
        ... on PricingConfigurationComponentReplacementTransport {
            amountExVATUntilCutOff
            cutOffMileage
            perMileageStepAboveCutOff
            amountExVATPerStepAboveCutOff
        }
        ... on PricingConfigurationComponentMargin {
            percentage
        }
        ... on PricingConfigurationComponentInterest {
            percentage
        }
        ... on PricingConfigurationComponentMaintenance {
            amountExVATPerDistanceUnit
        }
        ... on PricingConfigurationComponentCustomMonthlyLeasePriceAddOn {
            name
            description
            monthlyAddOnAmountExVAT
        }
    }
`
export const PricingConfigurationCalculationFragmentFragmentDoc = gql`
    fragment PricingConfigurationCalculationFragment on ConfigurationCalculation {
        id
        updatedAt
        name
        description
        components {
            ...PricingConfigurationCalculationComponents
            ... on PricingConfigurationComponentCustomResidualValue {
                name
                description
                fiscalValue
                residualValuePercentage
            }
            ... on PricingConfigurationComponentConditional {
                conditionBlocks {
                    conditions {
                        AND {
                            condition {
                                __typename
                                ... on PricingConditionFiscalValue {
                                    property
                                    operator
                                    fiscalValue
                                }
                                ... on PricingConditionLeasingType {
                                    property
                                    operator
                                    leasingType
                                }
                                ... on PricingConditionCarOption {
                                    property
                                    operator
                                    optionId
                                }
                            }
                        }
                    }
                    components {
                        ...PricingConfigurationCalculationComponents
                    }
                }
                componentsDefault {
                    ...PricingConfigurationCalculationComponents
                }
            }
        }
    }
    ${PricingConfigurationCalculationComponentsFragmentDoc}
`
export const WorkflowConditionFragmentFragmentDoc = gql`
    fragment WorkflowConditionFragment on WorkflowCondition {
        entity
        requirements {
            property
            filter {
                type
                field
            }
        }
    }
`
export const WorkflowActionEmailFragmentFragmentDoc = gql`
    fragment WorkflowActionEmailFragment on WorkflowActionEmail {
        emailTemplate {
            id
            name
            emailTemplateLocales {
                id
                locale
            }
        }
        senderName
        senderEmail
        receiverEmail
    }
`
export const WorkflowActionSlackFragmentFragmentDoc = gql`
    fragment WorkflowActionSlackFragment on WorkflowActionSlack {
        channel
        message {
            title
            content
        }
    }
`
export const WorkflowFragmentFragmentDoc = gql`
    fragment WorkflowFragment on Workflow {
        id
        name
        conditions {
            ...WorkflowConditionFragment
        }
        actions {
            ... on WorkflowActionBranch {
                conditions {
                    ...WorkflowConditionFragment
                }
                acceptActions {
                    ... on WorkflowActionEmail {
                        ...WorkflowActionEmailFragment
                    }
                    ... on WorkflowActionSlack {
                        ...WorkflowActionSlackFragment
                    }
                    ... on WorkflowActionBranch {
                        conditions {
                            ...WorkflowConditionFragment
                        }
                        acceptActions {
                            ... on WorkflowActionEmail {
                                ...WorkflowActionEmailFragment
                            }
                            ... on WorkflowActionSlack {
                                ...WorkflowActionSlackFragment
                            }
                        }
                        rejectActions {
                            ... on WorkflowActionEmail {
                                ...WorkflowActionEmailFragment
                            }
                            ... on WorkflowActionSlack {
                                ...WorkflowActionSlackFragment
                            }
                        }
                    }
                }
                rejectActions {
                    ... on WorkflowActionEmail {
                        ...WorkflowActionEmailFragment
                    }
                    ... on WorkflowActionSlack {
                        ...WorkflowActionSlackFragment
                    }
                    ... on WorkflowActionBranch {
                        conditions {
                            ...WorkflowConditionFragment
                        }
                        acceptActions {
                            ... on WorkflowActionEmail {
                                ...WorkflowActionEmailFragment
                            }
                            ... on WorkflowActionSlack {
                                ...WorkflowActionSlackFragment
                            }
                        }
                        rejectActions {
                            ... on WorkflowActionEmail {
                                ...WorkflowActionEmailFragment
                            }
                            ... on WorkflowActionSlack {
                                ...WorkflowActionSlackFragment
                            }
                        }
                    }
                }
            }
            ... on WorkflowActionEmail {
                ...WorkflowActionEmailFragment
            }
            ... on WorkflowActionSlack {
                ...WorkflowActionSlackFragment
            }
        }
    }
    ${WorkflowConditionFragmentFragmentDoc}
    ${WorkflowActionEmailFragmentFragmentDoc}
    ${WorkflowActionSlackFragmentFragmentDoc}
`
export const TenantCurrencyDocument = gql`
    query TenantCurrency($id: ID!) {
        TenantSettings(id: $id) {
            id
            currencyCode
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class TenantCurrencyGQL extends Apollo.Query<TenantCurrencyQuery, TenantCurrencyQueryVariables> {
    document = TenantCurrencyDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GetTenantsDocument = gql`
    query getTenants {
        getTenants {
            id
            name
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class GetTenantsGQL extends Apollo.Query<GetTenantsQuery, GetTenantsQueryVariables> {
    document = GetTenantsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AllRemarketingChannelsDocument = gql`
    query allRemarketingChannels {
        allRemarketingChannels {
            id
            ... on CarShopChannel {
                locale
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AllRemarketingChannelsGQL extends Apollo.Query<
    AllRemarketingChannelsQuery,
    AllRemarketingChannelsQueryVariables
> {
    document = AllRemarketingChannelsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AllTeslaOptionsDocument = gql`
    query allTeslaOptions {
        allTeslaOptions {
            id
            label
            manufacturerCode
            category
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AllTeslaOptionsGQL extends Apollo.Query<AllTeslaOptionsQuery, AllTeslaOptionsQueryVariables> {
    document = AllTeslaOptionsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CarCollectionDocument = gql`
    query CarCollection($filter: CarFilter, $pagination: Pagination, $orderBy: OrderBy) {
        CarCollection(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            cars {
                id
                VIN
                licensePlate
                brandName
                modelName
                editionName
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CarCollectionGQL extends Apollo.Query<CarCollectionQuery, CarCollectionQueryVariables> {
    document = CarCollectionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CarRemarketingPublicationCollectionDocument = gql`
    query CarRemarketingPublicationCollection($carId: String) {
        RemarketingPublicationCollection(filter: { carId: $carId }) {
            remarketingPublications {
                id
                pricingConfigurationId
                ... on CarShopPublication {
                    locale
                    offerTypes
                    availability
                    availabilityDate
                    bannerLabel
                    isContractTakeover
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CarRemarketingPublicationCollectionGQL extends Apollo.Query<
    CarRemarketingPublicationCollectionQuery,
    CarRemarketingPublicationCollectionQueryVariables
> {
    document = CarRemarketingPublicationCollectionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CarDocument = gql`
    query Car($id: ID!) {
        Car(id: $id) {
            ... on EntityNotFoundError {
                errorCode
            }
            ... on Car {
                ...CarFragment
            }
        }
    }
    ${CarFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class CarGQL extends Apollo.Query<CarQuery, CarQueryVariables> {
    document = CarDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PricingConfigurationCalculationCollectionSummaryDocument = gql`
    query PricingConfigurationCalculationCollectionSummary {
        PricingConfigurationCalculationCollection {
            configurationCalculations {
                id
                name
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class PricingConfigurationCalculationCollectionSummaryGQL extends Apollo.Query<
    PricingConfigurationCalculationCollectionSummaryQuery,
    PricingConfigurationCalculationCollectionSummaryQueryVariables
> {
    document = PricingConfigurationCalculationCollectionSummaryDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PublishToCarShopDocument = gql`
    mutation publishToCarShop($carId: ID!, $input: CarShopPublicationInput!) {
        publishToCarShop(carId: $carId, input: $input) {
            ... on CarShopPublication {
                id
            }
            ... on RimsOptionNeededError {
                errorCode
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class PublishToCarShopGQL extends Apollo.Mutation<PublishToCarShopMutation, PublishToCarShopMutationVariables> {
    document = PublishToCarShopDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemarketingPricingPreviewDocument = gql`
    query RemarketingPricingPreview($carId: ID!, $pricingConfigurationId: String) {
        RemarketingPricingPreview(carId: $carId, pricingConfigurationId: $pricingConfigurationId) {
            pricingConfigurationId
            purchasePriceExVAT
            futureValues {
                residualValue
                duration
                mileage
                leasePriceIncVAT
                leasePriceExVAT
            }
            contractTakeoverValues {
                residualValue
                duration
                mileage
                leasePriceIncVAT
                leasePriceExVAT
            }
            ready2ShareValues {
                privateMileage
                businessMileage
                privatePrice
                businessPrice
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemarketingPricingPreviewGQL extends Apollo.Query<
    RemarketingPricingPreviewQuery,
    RemarketingPricingPreviewQueryVariables
> {
    document = RemarketingPricingPreviewDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UnpublishRemarketingPublicationDocument = gql`
    mutation unpublishRemarketingPublication($id: ID!) {
        unpublishRemarketingPublication(id: $id) {
            ... on PublicationSuccessfullyUnpublished {
                publicationId
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UnpublishRemarketingPublicationGQL extends Apollo.Mutation<
    UnpublishRemarketingPublicationMutation,
    UnpublishRemarketingPublicationMutationVariables
> {
    document = UnpublishRemarketingPublicationDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpsertCarDocument = gql`
    mutation upsertCar($id: ID, $input: CarInput!) {
        upsertCar(id: $id, input: $input) {
            ... on EntityNotFoundError {
                errorCode
            }
            ... on InvalidInputDataError {
                errorCode
            }
            ... on Car {
                ...CarFragment
            }
        }
    }
    ${CarFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpsertCarGQL extends Apollo.Mutation<UpsertCarMutation, UpsertCarMutationVariables> {
    document = UpsertCarDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ConfigurationEditionDocument = gql`
    query ConfigurationEdition($id: ID, $name: String, $modelName: String) {
        ConfigurationEdition(id: $id, name: $name, modelName: $modelName) {
            ... on EntityNotFoundError {
                errorCode
            }
            ... on ConfigurationEdition {
                id
                name
                fiscalValue
                calculation {
                    id
                }
                usedCarCalculation {
                    id
                }
                lessKm
                moreKm
                privateLeaseLessKm
                privateLeaseMoreKm
                showInConfigurator
                editionLabel
                options {
                    id
                    fiscalValue
                    default
                    ... on AfterDeliveryEditionOption {
                        price
                    }
                    ... on LeaseEditionOption {
                        price
                    }
                    ... on WheelEditionOption {
                        price
                    }
                    option {
                        __typename
                        id
                        type
                        name
                        displayOrder
                    }
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ConfigurationEditionGQL extends Apollo.Query<
    ConfigurationEditionQuery,
    ConfigurationEditionQueryVariables
> {
    document = ConfigurationEditionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ConfigurationModelsDocument = gql`
    query ConfigurationModels {
        ConfigurationModels {
            ... on EntityNotFoundError {
                errorCode
            }
            ... on ConfigurationModel {
                id
                name
                editions {
                    id
                    name
                    showInConfigurator
                    editionLabel
                    displayOrder
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ConfigurationModelsGQL extends Apollo.Query<ConfigurationModelsQuery, ConfigurationModelsQueryVariables> {
    document = ConfigurationModelsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OptionsDocument = gql`
    query Options {
        Options {
            ... on ConfigurationOption {
                id
                name
                type
                manufacturerCode
                displayOrder
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class OptionsGQL extends Apollo.Query<OptionsQuery, OptionsQueryVariables> {
    document = OptionsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ResidualValueTableDocument = gql`
    query ResidualValueTable($id: ID!) {
        ResidualValueTable(id: $id) {
            ... on EntityNotFoundError {
                errorCode
            }
            ... on ResidualValueTable {
                id
                name
                residualValues {
                    id
                    ageInMonths
                    mileage
                    value
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ResidualValueTableGQL extends Apollo.Query<ResidualValueTableQuery, ResidualValueTableQueryVariables> {
    document = ResidualValueTableDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AddOptionToEditionDocument = gql`
    mutation addOptionToEdition($input: NewConfigurationEditionOptionInput!) {
        addOptionToEdition(input: $input) {
            id
            fiscalValue
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AddOptionToEditionGQL extends Apollo.Mutation<
    AddOptionToEditionMutation,
    AddOptionToEditionMutationVariables
> {
    document = AddOptionToEditionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AllResidualValueTablesDocument = gql`
    query allResidualValueTables {
        allResidualValueTables {
            ... on ResidualValueTable {
                id
                name
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AllResidualValueTablesGQL extends Apollo.Query<
    AllResidualValueTablesQuery,
    AllResidualValueTablesQueryVariables
> {
    document = AllResidualValueTablesDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateEditionDocument = gql`
    mutation createEdition($input: ConfigurationEditionInput!) {
        createEdition(input: $input) {
            id
            name
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateEditionGQL extends Apollo.Mutation<CreateEditionMutation, CreateEditionMutationVariables> {
    document = CreateEditionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateModelDocument = gql`
    mutation createModel($input: ConfigurationModelInput!) {
        createModel(input: $input) {
            id
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateModelGQL extends Apollo.Mutation<CreateModelMutation, CreateModelMutationVariables> {
    document = CreateModelDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateOptionDocument = gql`
    mutation createOption($input: ConfigurationOptionInput!) {
        createOption(input: $input) {
            id
            name
            manufacturerCode
            displayOrder
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateOptionGQL extends Apollo.Mutation<CreateOptionMutation, CreateOptionMutationVariables> {
    document = CreateOptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateResidualValueTableDocument = gql`
    mutation createResidualValueTable($input: ResidualValueTableInput!) {
        createResidualValueTable(input: $input) {
            ... on EntityNotFoundError {
                errorCode
            }
            ... on ResidualValueTable {
                id
                name
                residualValues {
                    id
                    mileage
                    ageInMonths
                    value
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateResidualValueTableGQL extends Apollo.Mutation<
    CreateResidualValueTableMutation,
    CreateResidualValueTableMutationVariables
> {
    document = CreateResidualValueTableDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveEditionDocument = gql`
    mutation removeEdition($id: ID!) {
        removeEdition(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemoveEditionGQL extends Apollo.Mutation<RemoveEditionMutation, RemoveEditionMutationVariables> {
    document = RemoveEditionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveEditionOptionDocument = gql`
    mutation removeEditionOption($id: ID!) {
        removeEditionOption(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemoveEditionOptionGQL extends Apollo.Mutation<
    RemoveEditionOptionMutation,
    RemoveEditionOptionMutationVariables
> {
    document = RemoveEditionOptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveOptionDocument = gql`
    mutation removeOption($id: ID!) {
        removeOption(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemoveOptionGQL extends Apollo.Mutation<RemoveOptionMutation, RemoveOptionMutationVariables> {
    document = RemoveOptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveResidualValueTableDocument = gql`
    mutation removeResidualValueTable($id: ID!) {
        removeResidualValueTable(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemoveResidualValueTableGQL extends Apollo.Mutation<
    RemoveResidualValueTableMutation,
    RemoveResidualValueTableMutationVariables
> {
    document = RemoveResidualValueTableDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const TenantSettingsDocument = gql`
    query TenantSettings($id: ID!) {
        TenantSettings(id: $id) {
            id
            tenant
            currencyCode
            enabledSigningMethods
            usedCarConfigurationCutOffMileage
            downpaymentAmounts
            downpaymentInterestPercentage
            dealLocales
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class TenantSettingsGQL extends Apollo.Query<TenantSettingsQuery, TenantSettingsQueryVariables> {
    document = TenantSettingsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateEditionDocument = gql`
    mutation updateEdition($id: ID!, $options: ConfigurationEditionInput!) {
        updateEdition(id: $id, input: $options) {
            id
            fiscalValue
            displayOrder
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateEditionGQL extends Apollo.Mutation<UpdateEditionMutation, UpdateEditionMutationVariables> {
    document = UpdateEditionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateEditionOptionDocument = gql`
    mutation updateEditionOption($id: ID!, $input: ConfigurationEditionOptionInput!) {
        updateEditionOption(id: $id, input: $input) {
            id
            fiscalValue
            default
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateEditionOptionGQL extends Apollo.Mutation<
    UpdateEditionOptionMutation,
    UpdateEditionOptionMutationVariables
> {
    document = UpdateEditionOptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateModelDocument = gql`
    mutation updateModel($id: ID!, $options: ConfigurationModelInput!) {
        updateModel(id: $id, input: $options) {
            id
            name
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateModelGQL extends Apollo.Mutation<UpdateModelMutation, UpdateModelMutationVariables> {
    document = UpdateModelDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateOptionDocument = gql`
    mutation updateOption($id: ID!, $input: ConfigurationOptionInput!) {
        updateOption(id: $id, input: $input) {
            id
            name
            manufacturerCode
            displayOrder
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateOptionGQL extends Apollo.Mutation<UpdateOptionMutation, UpdateOptionMutationVariables> {
    document = UpdateOptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateResidualValueTableDocument = gql`
    mutation updateResidualValueTable($id: ID!, $input: ResidualValueTableInput!) {
        updateResidualValueTable(id: $id, input: $input) {
            ... on EntityNotFoundError {
                errorCode
            }
            ... on ResidualValueTable {
                id
                name
                residualValues {
                    id
                    mileage
                    ageInMonths
                    value
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateResidualValueTableGQL extends Apollo.Mutation<
    UpdateResidualValueTableMutation,
    UpdateResidualValueTableMutationVariables
> {
    document = UpdateResidualValueTableDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateTenantSettingsDocument = gql`
    mutation updateTenantSettings($id: ID!, $input: TenantSettingsInput!) {
        updateTenantSettings(id: $id, input: $input) {
            id
            tenant
            currencyCode
            enabledSigningMethods
            usedCarConfigurationCutOffMileage
            downpaymentAmounts
            downpaymentInterestPercentage
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateTenantSettingsGQL extends Apollo.Mutation<
    UpdateTenantSettingsMutation,
    UpdateTenantSettingsMutationVariables
> {
    document = UpdateTenantSettingsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AccessoriesDocument = gql`
    query accessories {
        Accessories {
            id
            name
            priceIncVAT
            priceExVAT
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AccessoriesGQL extends Apollo.Query<AccessoriesQuery, AccessoriesQueryVariables> {
    document = AccessoriesDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AccessoryPropositionDocument = gql`
    query accessoryProposition($id: ID!, $accessory: String!) {
        AccessoryProposition(id: $id, accessory: $accessory) {
            id
            oldPricePerMonthExVAT
            newPricePerMonthExVAT
            priceIncreaseExVAT
            oldPricePerMonthIncVAT
            newPricePerMonthIncVAT
            priceIncreaseIncVAT
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AccessoryPropositionGQL extends Apollo.Query<
    AccessoryPropositionQuery,
    AccessoryPropositionQueryVariables
> {
    document = AccessoryPropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ContractAttachmentsDocument = gql`
    query ContractAttachments($id: ID!) {
        Contract(id: $id) {
            attachments {
                id
                name
                type
                url
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ContractAttachmentsGQL extends Apollo.Query<ContractAttachmentsQuery, ContractAttachmentsQueryVariables> {
    document = ContractAttachmentsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ContractCollectionDocument = gql`
    query ContractCollection($filter: ContractFilter, $pagination: Pagination, $orderBy: OrderBy) {
        ContractCollection(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            contracts {
                id
                lessor {
                    id
                    name
                }
                lessee {
                    __typename
                    ... on ContractCompany {
                        name
                    }
                    ... on ContractPerson {
                        fullName: name
                    }
                }
                driver {
                    fullName
                }
                car {
                    VIN
                    licensePlate
                }
                startDate
                endDate
                attachments {
                    id
                    type
                }
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ContractCollectionGQL extends Apollo.Query<ContractCollectionQuery, ContractCollectionQueryVariables> {
    document = ContractCollectionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ContractRecalculationDocument = gql`
    query ContractRecalculation($id: ID!, $newTotalMileage: Int!) {
        ContractRecalculation(id: $id, newTotalMileage: $newTotalMileage) {
            id
            oldPricePerMonthExVAT
            oldPricePerMonthIncVAT
            newPricePerMonthExVAT
            newPricePerMonthIncVAT
            priceIncreaseExVAT
            priceIncreaseIncVAT
            oneTimeFeeExVAT
            oneTimeFeeIncVAT
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ContractRecalculationGQL extends Apollo.Query<
    ContractRecalculationQuery,
    ContractRecalculationQueryVariables
> {
    document = ContractRecalculationDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ContractDocument = gql`
    query Contract($id: ID!) {
        Contract(id: $id) {
            id
            contractNumber
            leasingType
            startDate
            endDate
            mileage
            pricePerMonthExVAT
            moreKmPrice
            lessKmPrice
            createdAt
            updatedAt
            remarks
            fsd
            fsdGrowthModel
            fsdGrowthFee
            duration
            deductible
            windowBreakDeductible
            winterTires
            billedUntil
            status
            downpayment
            premiumConnectivity
            paymentSummary {
                expiredAmount
                invoicesPaidTooLate
                paymentScore
            }
            accountManager {
                id
                firstName
                lastName
                emailAddress
                telephoneNumber
            }
            lessor {
                id
                name
            }
            lessee {
                ... on ContractPerson {
                    id
                    name
                    emailAddress
                    phonenumber
                    address {
                        street
                        houseNumber
                        houseNumberSuffix
                        zipcode
                        city
                    }
                }
                ... on ContractCompany {
                    id
                    companyName: name
                    emailAddress
                    phonenumber
                    chamberOfCommerceNr
                    visitingAddress {
                        street
                        houseNumber
                        houseNumberSuffix
                        zipcode
                        city
                    }
                }
            }
            car {
                id
                VIN
                licensePlate
                brandName
                modelName
                editionName
                currentTire
                tireSizeFront
                tireSizeRear
                lastTireChangeDate
                lastTireChangeBy
                lastTireChangePlace
                nextTireChangeDate
                ministryOfTransportDate
            }
            driver {
                firstName
                fullName
                mobilePhonenumber
                workPhonenumber
                emailAddress
                address {
                    street
                    houseNumber
                    houseNumberSuffix
                    zipcode
                    city
                }
            }
            propositions {
                id
                createdAt
                type
                pdfUrl
                state
            }
            attachments {
                id
                name
                type
                url
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ContractGQL extends Apollo.Query<ContractQuery, ContractQueryVariables> {
    document = ContractDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DeleteContractAttachmentDocument = gql`
    mutation deleteContractAttachment($attachmentId: ID!) {
        deleteContractAttachment(attachmentId: $attachmentId)
    }
`

@Injectable({
    providedIn: 'root',
})
export class DeleteContractAttachmentGQL extends Apollo.Mutation<
    DeleteContractAttachmentMutation,
    DeleteContractAttachmentMutationVariables
> {
    document = DeleteContractAttachmentDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EndContractPropositionDocument = gql`
    query EndContractProposition($id: ID!, $propositionDate: Date!) {
        EndContractProposition(id: $id, propositionDate: $propositionDate) {
            id
            oneTimeFeeExVAT
            oneTimeFeeIncVAT
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class EndContractPropositionGQL extends Apollo.Query<
    EndContractPropositionQuery,
    EndContractPropositionQueryVariables
> {
    document = EndContractPropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GenerateAccessoryPropositionDocument = gql`
    mutation generateAccessoryProposition($id: ID!, $signingPersonEmailAddress: String!, $accessory: String!) {
        generateAccessoryProposition(
            id: $id
            signingPersonEmailAddress: $signingPersonEmailAddress
            accessory: $accessory
        )
    }
`

@Injectable({
    providedIn: 'root',
})
export class GenerateAccessoryPropositionGQL extends Apollo.Mutation<
    GenerateAccessoryPropositionMutation,
    GenerateAccessoryPropositionMutationVariables
> {
    document = GenerateAccessoryPropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GenerateContractRecalculationPropositionDocument = gql`
    mutation generateContractRecalculationProposition(
        $id: ID!
        $signingPersonEmailAddress: String!
        $newTotalMileage: Int!
    ) {
        generateContractRecalculationProposition(
            id: $id
            signingPersonEmailAddress: $signingPersonEmailAddress
            newTotalMileage: $newTotalMileage
        )
    }
`

@Injectable({
    providedIn: 'root',
})
export class GenerateContractRecalculationPropositionGQL extends Apollo.Mutation<
    GenerateContractRecalculationPropositionMutation,
    GenerateContractRecalculationPropositionMutationVariables
> {
    document = GenerateContractRecalculationPropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GenerateEndContractPropositionDocument = gql`
    mutation generateEndContractProposition(
        $id: ID!
        $signingPersonEmailAddress: String!
        $propositionDate: Date!
        $language: String!
    ) {
        generateEndContractProposition(
            id: $id
            signingPersonEmailAddress: $signingPersonEmailAddress
            propositionDate: $propositionDate
            language: $language
        )
    }
`

@Injectable({
    providedIn: 'root',
})
export class GenerateEndContractPropositionGQL extends Apollo.Mutation<
    GenerateEndContractPropositionMutation,
    GenerateEndContractPropositionMutationVariables
> {
    document = GenerateEndContractPropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GenerateFsdPropositionDocument = gql`
    mutation generateFsdProposition(
        $id: ID!
        $signingPersonEmailAddress: String!
        $type: FSDPropositionType!
        $amount: Float!
    ) {
        generateFSDProposition(
            id: $id
            signingPersonEmailAddress: $signingPersonEmailAddress
            type: $type
            amount: $amount
        )
    }
`

@Injectable({
    providedIn: 'root',
})
export class GenerateFsdPropositionGQL extends Apollo.Mutation<
    GenerateFsdPropositionMutation,
    GenerateFsdPropositionMutationVariables
> {
    document = GenerateFsdPropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GeneratePremiumConnectivityPropositionDocument = gql`
    mutation generatePremiumConnectivityProposition($id: ID!, $signingPersonEmailAddress: String!) {
        generatePremiumConnectivityProposition(id: $id, signingPersonEmailAddress: $signingPersonEmailAddress)
    }
`

@Injectable({
    providedIn: 'root',
})
export class GeneratePremiumConnectivityPropositionGQL extends Apollo.Mutation<
    GeneratePremiumConnectivityPropositionMutation,
    GeneratePremiumConnectivityPropositionMutationVariables
> {
    document = GeneratePremiumConnectivityPropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GenerateUpgradePropositionDocument = gql`
    mutation generateUpgradeProposition($id: ID!, $signingPersonEmailAddress: String!, $propositionDate: Date!) {
        generateUpgradeProposition(
            id: $id
            signingPersonEmailAddress: $signingPersonEmailAddress
            propositionDate: $propositionDate
        )
    }
`

@Injectable({
    providedIn: 'root',
})
export class GenerateUpgradePropositionGQL extends Apollo.Mutation<
    GenerateUpgradePropositionMutation,
    GenerateUpgradePropositionMutationVariables
> {
    document = GenerateUpgradePropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpgradePropositionDocument = gql`
    query UpgradeProposition($id: ID!, $propositionDate: Date!) {
        UpgradeProposition(id: $id, propositionDate: $propositionDate) {
            id
            oneTimeFeeExVAT
            oneTimeFeeIncVAT
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpgradePropositionGQL extends Apollo.Query<UpgradePropositionQuery, UpgradePropositionQueryVariables> {
    document = UpgradePropositionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UploadContractAttachmentDocument = gql`
    mutation uploadContractAttachment(
        $contractId: ID!
        $type: ContractAttachmentType!
        $encodedFile: Base64!
        $fileName: String!
    ) {
        uploadContractAttachment(contractId: $contractId, type: $type, encodedFile: $encodedFile, fileName: $fileName) {
            id
            name
            type
            url
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UploadContractAttachmentGQL extends Apollo.Mutation<
    UploadContractAttachmentMutation,
    UploadContractAttachmentMutationVariables
> {
    document = UploadContractAttachmentDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DealContractPdfDocument = gql`
    query DealContractPdf($dealId: ID!) {
        DealContractPdf(dealId: $dealId) {
            dealId
            url
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class DealContractPdfGQL extends Apollo.Query<DealContractPdfQuery, DealContractPdfQueryVariables> {
    document = DealContractPdfDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LeasewiseOfferStatusDocument = gql`
    query LeasewiseOfferStatus($dealId: ID!) {
        LeasewiseOfferStatus(dealId: $dealId) {
            id
            status
            error
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class LeasewiseOfferStatusGQL extends Apollo.Query<
    LeasewiseOfferStatusQuery,
    LeasewiseOfferStatusQueryVariables
> {
    document = LeasewiseOfferStatusDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AddSigningpersonToDealDocument = gql`
    mutation addSigningpersonToDeal($id: ID!, $emailAddress: EmailAddress!) {
        addSigningpersonToDeal(id: $id, email: $emailAddress) {
            id
            signingPersons {
                ...ContractDetailSigningPersonFragment
            }
        }
    }
    ${ContractDetailSigningPersonFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class AddSigningpersonToDealGQL extends Apollo.Mutation<
    AddSigningpersonToDealMutation,
    AddSigningpersonToDealMutationVariables
> {
    document = AddSigningpersonToDealDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CancelDealDocument = gql`
    mutation cancelDeal($id: ID!, $comment: String) {
        cancelDeal(id: $id, comment: $comment) {
            id
            status
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CancelDealGQL extends Apollo.Mutation<CancelDealMutation, CancelDealMutationVariables> {
    document = CancelDealDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ContractDetailDocument = gql`
    query contractDetail($id: ID!) {
        Deal(id: $id) {
            ...ContractDetailFragment
        }
    }
    ${ContractDetailFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class ContractDetailGQL extends Apollo.Query<ContractDetailQuery, ContractDetailQueryVariables> {
    document = ContractDetailDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ContractOverviewDocument = gql`
    query contractOverview($filter: DealFilter, $pagination: Pagination, $orderBy: OrderBy) {
        DealCollection(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            deals {
                id
                createdAt
                status
                locale
                internalOwnerId
                referralCode
                modelName
                lessor {
                    id
                }
                lessee {
                    ... on LesseeCompany {
                        id
                        name
                        kcStatus
                        kvkStatus
                    }
                    ... on LesseePerson {
                        id
                        emailAddress
                        firstName
                        lastNamePrefix
                        lastName
                        kcStatus
                    }
                }
                driver {
                    ... on DealDriver {
                        id
                        firstName
                        lastNamePrefix
                        lastName
                        licenseVerified
                        emailAddress
                    }
                }
                signingPersons {
                    id
                    signed
                    signingStatus
                }
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ContractOverviewGQL extends Apollo.Query<ContractOverviewQuery, ContractOverviewQueryVariables> {
    document = ContractOverviewDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateDealDocument = gql`
    mutation createDeal($input: DealInput!) {
        createDeal(input: $input) {
            id
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateDealGQL extends Apollo.Mutation<CreateDealMutation, CreateDealMutationVariables> {
    document = CreateDealDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateDealDocumentUploadTokenDocument = gql`
    mutation createDealDocumentUploadToken($id: ID!) {
        createDealDocumentUploadToken(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateDealDocumentUploadTokenGQL extends Apollo.Mutation<
    CreateDealDocumentUploadTokenMutation,
    CreateDealDocumentUploadTokenMutationVariables
> {
    document = CreateDealDocumentUploadTokenDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DealToLeasewiseDocument = gql`
    mutation dealToLeasewise($dealId: ID!) {
        dealToLeasewise(dealId: $dealId) {
            id
            status
            error
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class DealToLeasewiseGQL extends Apollo.Mutation<DealToLeasewiseMutation, DealToLeasewiseMutationVariables> {
    document = DealToLeasewiseDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DocumentDocument = gql`
    query Document($id: ID!) {
        Document(id: $id) {
            ...DocumentFragment
        }
    }
    ${DocumentFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class DocumentGQL extends Apollo.Query<DocumentQuery, DocumentQueryVariables> {
    document = DocumentDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DuplicateDealDocument = gql`
    mutation duplicateDeal($id: ID!) {
        duplicateDeal(id: $id) {
            id
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class DuplicateDealGQL extends Apollo.Mutation<DuplicateDealMutation, DuplicateDealMutationVariables> {
    document = DuplicateDealDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EnabledSigningMethodsDocument = gql`
    query EnabledSigningMethods($tenant: ID!) {
        TenantSettings(id: $tenant) {
            enabledSigningMethods
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class EnabledSigningMethodsGQL extends Apollo.Query<
    EnabledSigningMethodsQuery,
    EnabledSigningMethodsQueryVariables
> {
    document = EnabledSigningMethodsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const FinalizeDealDocument = gql`
    mutation finalizeDeal($id: ID!, $comment: String) {
        finalizeDeal(id: $id, comment: $comment) {
            id
            status
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class FinalizeDealGQL extends Apollo.Mutation<FinalizeDealMutation, FinalizeDealMutationVariables> {
    document = FinalizeDealDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GenerateCoverPageDocument = gql`
    query generateCoverPage($dealId: ID!) {
        generateCoverPage(dealId: $dealId)
    }
`

@Injectable({
    providedIn: 'root',
})
export class GenerateCoverPageGQL extends Apollo.Query<GenerateCoverPageQuery, GenerateCoverPageQueryVariables> {
    document = GenerateCoverPageDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MakeDealOfferDocument = gql`
    mutation makeDealOffer($id: ID!) {
        makeDealOffer(id: $id) {
            id
            status
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class MakeDealOfferGQL extends Apollo.Mutation<MakeDealOfferMutation, MakeDealOfferMutationVariables> {
    document = MakeDealOfferDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OfferEditDocument = gql`
    query OfferEdit($id: ID!, $showAllEditions: Boolean) {
        Deal(id: $id, showAllEditions: $showAllEditions) {
            ...baseDealFields
            requirements {
                ...DealRequirementsFragment
            }
            userId
            status
            internalOwnerId
            optionsDescription
            remarks
        }
    }
    ${BaseDealFieldsFragmentDoc}
    ${DealRequirementsFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class OfferEditGQL extends Apollo.Query<OfferEditQuery, OfferEditQueryVariables> {
    document = OfferEditDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OfferOverviewDocument = gql`
    query offerOverview($filter: DealFilter, $pagination: Pagination, $orderBy: OrderBy) {
        DealCollection(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            deals {
                id
                createdAt
                internalOwnerId
                userId
                locale
                modelName
                licencePlate
                pricing {
                    id
                    pricePerMonthExVAT
                    pricePerMonthIncVAT
                    incVAT
                    margin
                }
                lessee {
                    ... on LesseeCompany {
                        id
                        name
                        kcStatus
                        kvkStatus
                    }
                    ... on LesseePerson {
                        id
                        emailAddress
                        firstName
                        lastNamePrefix
                        lastName
                        kcStatus
                    }
                }
                driver {
                    ... on DealDriver {
                        id
                        firstName
                        lastNamePrefix
                        lastName
                        licenseVerified
                        emailAddress
                    }
                }
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class OfferOverviewGQL extends Apollo.Query<OfferOverviewQuery, OfferOverviewQueryVariables> {
    document = OfferOverviewDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveSigningpersonFromDealDocument = gql`
    mutation removeSigningpersonFromDeal($id: ID!, $emailAddress: EmailAddress!) {
        removeSigningpersonFromDeal(id: $id, email: $emailAddress) {
            id
            signingPersons {
                ...ContractDetailSigningPersonFragment
            }
        }
    }
    ${ContractDetailSigningPersonFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class RemoveSigningpersonFromDealGQL extends Apollo.Mutation<
    RemoveSigningpersonFromDealMutation,
    RemoveSigningpersonFromDealMutationVariables
> {
    document = RemoveSigningpersonFromDealDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SearchCompanyDocument = gql`
    query searchCompany($name: String!) {
        searchCompany(nameOrChamberOfCommerceNr: $name) {
            id
            name
            branchNr
            chamberOfCommerceNr
            address {
                id
                street
                houseNr
                houseNrSuffix
                zipCode
                city
                country
            }
            isMainBranch
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class SearchCompanyGQL extends Apollo.Query<SearchCompanyQuery, SearchCompanyQueryVariables> {
    document = SearchCompanyDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SigningPersonsDocument = gql`
    query SigningPersons($dealId: ID!) {
        Deal(id: $dealId) {
            id
            signingPersons {
                ...ContractDetailSigningPersonFragment
            }
        }
    }
    ${ContractDetailSigningPersonFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class SigningPersonsGQL extends Apollo.Query<SigningPersonsQuery, SigningPersonsQueryVariables> {
    document = SigningPersonsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateBooleanDealOptionDocument = gql`
    mutation updateBooleanDealOption($id: ID!, $dealOptionId: ID!, $value: Boolean!) {
        updateBooleanDealOption(id: $id, dealOptionId: $dealOptionId, value: $value) {
            id
            pricing {
                ...PricingFragment
            }
            options {
                ... on BooleanDealOption {
                    id
                    key
                    value
                }
                ... on SelectableDealOption {
                    id
                    name: key
                    selectedValue: value {
                        id
                        label
                    }
                    selectableValues {
                        id
                        label
                    }
                }
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateBooleanDealOptionGQL extends Apollo.Mutation<
    UpdateBooleanDealOptionMutation,
    UpdateBooleanDealOptionMutationVariables
> {
    document = UpdateBooleanDealOptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealDriverDocument = gql`
    mutation updateDealDriver($id: ID!, $email: String) {
        updateDealDriver(id: $id, email: $email) {
            id
            driver {
                ... on DealDriver {
                    ...DriverFragment
                }
            }
        }
    }
    ${DriverFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealDriverGQL extends Apollo.Mutation<UpdateDealDriverMutation, UpdateDealDriverMutationVariables> {
    document = UpdateDealDriverDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealDurationDocument = gql`
    mutation updateDealDuration($id: ID!, $duration: Int!) {
        updateDealDuration(id: $id, duration: $duration) {
            id
            pricing {
                ...PricingFragment
            }
            options {
                ... on BooleanDealOption {
                    ...BooleanDealOptionFragment
                }
                ... on SelectableDealOption {
                    ...SelectableDealOptionFragment
                }
            }
        }
    }
    ${PricingFragmentFragmentDoc}
    ${BooleanDealOptionFragmentFragmentDoc}
    ${SelectableDealOptionFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealDurationGQL extends Apollo.Mutation<
    UpdateDealDurationMutation,
    UpdateDealDurationMutationVariables
> {
    document = UpdateDealDurationDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealInternalOwnerDocument = gql`
    mutation updateDealInternalOwner($id: ID!, $userId: String) {
        updateDealInternalOwner(id: $id, userId: $userId) {
            id
            internalOwnerId
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealInternalOwnerGQL extends Apollo.Mutation<
    UpdateDealInternalOwnerMutation,
    UpdateDealInternalOwnerMutationVariables
> {
    document = UpdateDealInternalOwnerDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealKcStatusDocument = gql`
    mutation updateDealKcStatus($id: ID!, $status: InternalStatus!, $message: String) {
        updateDealKcStatus(id: $id, status: $status, message: $message) {
            id
            status
            lessee {
                ... on LesseeCompany {
                    id
                    kcComment
                    kcStatus
                }
                ... on LesseePerson {
                    id
                    kcComment
                    kcStatus
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealKcStatusGQL extends Apollo.Mutation<
    UpdateDealKcStatusMutation,
    UpdateDealKcStatusMutationVariables
> {
    document = UpdateDealKcStatusDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealKvkStatusDocument = gql`
    mutation updateDealKvkStatus($id: ID!, $status: InternalStatus!, $message: String) {
        updateDealKvkStatus(id: $id, status: $status, message: $message) {
            id
            status
            lessee {
                ... on LesseeCompany {
                    id
                    kvkStatus
                    kvkComment
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealKvkStatusGQL extends Apollo.Mutation<
    UpdateDealKvkStatusMutation,
    UpdateDealKvkStatusMutationVariables
> {
    document = UpdateDealKvkStatusDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealLessKmDocument = gql`
    mutation updateDealLessKm($id: ID!, $lessKmPriceExVAT: Float) {
        updateDealLessKm(id: $id, lessKmPriceExVAT: $lessKmPriceExVAT) {
            id
            pricing {
                ...PricingFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealLessKmGQL extends Apollo.Mutation<UpdateDealLessKmMutation, UpdateDealLessKmMutationVariables> {
    document = UpdateDealLessKmDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealLesseeDocument = gql`
    mutation updateDealLessee($id: ID!, $input: CompanyInput) {
        updateDealLessee(id: $id, input: $input) {
            id
            lessee {
                ... on LesseeCompany {
                    ...LesseeCompanyFragment
                }
                ... on LesseePerson {
                    ...LesseePersonFragment
                }
            }
        }
    }
    ${LesseeCompanyFragmentFragmentDoc}
    ${LesseePersonFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealLesseeGQL extends Apollo.Mutation<UpdateDealLesseeMutation, UpdateDealLesseeMutationVariables> {
    document = UpdateDealLesseeDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealLocaleDocument = gql`
    mutation updateDealLocale($id: ID!, $locale: DealLocale!) {
        updateDealLocale(id: $id, locale: $locale) {
            id
            locale
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealLocaleGQL extends Apollo.Mutation<UpdateDealLocaleMutation, UpdateDealLocaleMutationVariables> {
    document = UpdateDealLocaleDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealMarginDocument = gql`
    mutation updateDealMargin($id: ID!, $margin: Float!) {
        updateDealMargin(id: $id, margin: $margin) {
            id
            pricing {
                ...PricingFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealMarginGQL extends Apollo.Mutation<UpdateDealMarginMutation, UpdateDealMarginMutationVariables> {
    document = UpdateDealMarginDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealMileageDocument = gql`
    mutation updateDealMileage($id: ID!, $mileage: Int!) {
        updateDealMileage(id: $id, mileage: $mileage) {
            id
            pricing {
                ...PricingFragment
            }
            options {
                ... on BooleanDealOption {
                    ...BooleanDealOptionFragment
                }
                ... on SelectableDealOption {
                    ...SelectableDealOptionFragment
                }
            }
        }
    }
    ${PricingFragmentFragmentDoc}
    ${BooleanDealOptionFragmentFragmentDoc}
    ${SelectableDealOptionFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealMileageGQL extends Apollo.Mutation<
    UpdateDealMileageMutation,
    UpdateDealMileageMutationVariables
> {
    document = UpdateDealMileageDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealMoreKmDocument = gql`
    mutation updateDealMoreKm($id: ID!, $moreKmPriceExVAT: Float) {
        updateDealMoreKm(id: $id, moreKmPriceExVAT: $moreKmPriceExVAT) {
            id
            pricing {
                ...PricingFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealMoreKmGQL extends Apollo.Mutation<UpdateDealMoreKmMutation, UpdateDealMoreKmMutationVariables> {
    document = UpdateDealMoreKmDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealOptionsDescriptionDocument = gql`
    mutation updateDealOptionsDescription($id: ID!, $optionsDescription: String!) {
        updateDealOptionsDescription(id: $id, optionsDescription: $optionsDescription) {
            id
            optionsDescription
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealOptionsDescriptionGQL extends Apollo.Mutation<
    UpdateDealOptionsDescriptionMutation,
    UpdateDealOptionsDescriptionMutationVariables
> {
    document = UpdateDealOptionsDescriptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealOwnerDocument = gql`
    mutation updateDealOwner($id: ID!, $userId: String!) {
        updateDealOwner(id: $id, userId: $userId) {
            id
            userId
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealOwnerGQL extends Apollo.Mutation<UpdateDealOwnerMutation, UpdateDealOwnerMutationVariables> {
    document = UpdateDealOwnerDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealPriceDocument = gql`
    mutation updateDealPrice($id: ID!, $priceExVAT: Float!) {
        updateDealPrice(id: $id, priceExVAT: $priceExVAT) {
            id
            pricing {
                ...PricingFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealPriceGQL extends Apollo.Mutation<UpdateDealPriceMutation, UpdateDealPriceMutationVariables> {
    document = UpdateDealPriceDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealPrivateLeaseLessKmDocument = gql`
    mutation updateDealPrivateLeaseLessKm($id: ID!, $privateLeaseLessKmIncVAT: Float) {
        updateDealPrivateLeaseLessKm(id: $id, privateLeaseLessKmIncVAT: $privateLeaseLessKmIncVAT) {
            id
            pricing {
                ...PricingFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealPrivateLeaseLessKmGQL extends Apollo.Mutation<
    UpdateDealPrivateLeaseLessKmMutation,
    UpdateDealPrivateLeaseLessKmMutationVariables
> {
    document = UpdateDealPrivateLeaseLessKmDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealPrivateLeaseMoreKmDocument = gql`
    mutation updateDealPrivateLeaseMoreKm($id: ID!, $privateLeaseMoreKmIncVAT: Float) {
        updateDealPrivateLeaseMoreKm(id: $id, privateLeaseMoreKmIncVAT: $privateLeaseMoreKmIncVAT) {
            id
            pricing {
                ...PricingFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealPrivateLeaseMoreKmGQL extends Apollo.Mutation<
    UpdateDealPrivateLeaseMoreKmMutation,
    UpdateDealPrivateLeaseMoreKmMutationVariables
> {
    document = UpdateDealPrivateLeaseMoreKmDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealReferralCodeDocument = gql`
    mutation updateDealReferralCode($id: ID!, $code: String!) {
        updateDealReferralCode(id: $id, code: $code) {
            id
            referralCode
            referralProgram {
                id
                name
            }
            pricing {
                ...PricingFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealReferralCodeGQL extends Apollo.Mutation<
    UpdateDealReferralCodeMutation,
    UpdateDealReferralCodeMutationVariables
> {
    document = UpdateDealReferralCodeDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateDealRemarksDocument = gql`
    mutation updateDealRemarks($id: ID!, $remarks: String!) {
        updateDealRemarks(id: $id, remarks: $remarks) {
            id
            remarks
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateDealRemarksGQL extends Apollo.Mutation<
    UpdateDealRemarksMutation,
    UpdateDealRemarksMutationVariables
> {
    document = UpdateDealRemarksDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateSelectableDealOptionDocument = gql`
    mutation updateSelectableDealOption($id: ID!, $dealOptionId: ID!, $valueId: ID!, $showAllEditions: Boolean) {
        updateSelectableDealOption(
            id: $id
            dealOptionId: $dealOptionId
            valueId: $valueId
            showAllEditions: $showAllEditions
        ) {
            id
            pricing {
                ...PricingFragment
            }
            options {
                ... on BooleanDealOption {
                    id
                    key
                    value
                }
                ... on SelectableDealOption {
                    id
                    name: key
                    selectedValue: value {
                        id
                        label
                        editionLabel
                    }
                    selectableValues {
                        id
                        label
                        editionLabel
                    }
                }
            }
            requirements {
                ...DealRequirementsFragment
            }
        }
    }
    ${PricingFragmentFragmentDoc}
    ${DealRequirementsFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpdateSelectableDealOptionGQL extends Apollo.Mutation<
    UpdateSelectableDealOptionMutation,
    UpdateSelectableDealOptionMutationVariables
> {
    document = UpdateSelectableDealOptionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UploadDocumentDocument = gql`
    mutation uploadDocument($input: DocumentInput!) {
        uploadDocument(input: $input) {
            ...DocumentFragment
        }
    }
    ${DocumentFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UploadDocumentGQL extends Apollo.Mutation<UploadDocumentMutation, UploadDocumentMutationVariables> {
    document = UploadDocumentDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const VerifyDealDocumentDocument = gql`
    mutation verifyDealDocument($documentSignatureId: ID!, $isVerified: Boolean!) {
        verifyDealDocument(documentSignatureId: $documentSignatureId, isVerified: $isVerified) {
            ...ContractDetailFragment
        }
    }
    ${ContractDetailFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class VerifyDealDocumentGQL extends Apollo.Mutation<
    VerifyDealDocumentMutation,
    VerifyDealDocumentMutationVariables
> {
    document = VerifyDealDocumentDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AllExposedEntitiesDocument = gql`
    query allExposedEntities {
        allExposedEntities {
            id
            name
            type
            properties {
                id
                name
                description
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AllExposedEntitiesGQL extends Apollo.Query<AllExposedEntitiesQuery, AllExposedEntitiesQueryVariables> {
    document = AllExposedEntitiesDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EmailTemplateDocument = gql`
    query EmailTemplate($id: ID!) {
        EmailTemplate(id: $id) {
            id
            name
            emailTemplateLocales {
                id
                locale
                subject
                previewText
                htmlContent
                rawTemplateContent
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class EmailTemplateGQL extends Apollo.Query<EmailTemplateQuery, EmailTemplateQueryVariables> {
    document = EmailTemplateDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EmailTemplateCollectionDocument = gql`
    query EmailTemplateCollection($filter: EmailTemplateFilter, $pagination: Pagination, $orderBy: OrderBy) {
        EmailTemplateCollection(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            emailTemplates {
                id
                createdAt
                updatedAt
                name
                emailTemplateLocales {
                    id
                    locale
                }
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class EmailTemplateCollectionGQL extends Apollo.Query<
    EmailTemplateCollectionQuery,
    EmailTemplateCollectionQueryVariables
> {
    document = EmailTemplateCollectionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveEmailTemplateDocument = gql`
    mutation removeEmailTemplate($id: ID!) {
        removeEmailTemplate(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemoveEmailTemplateGQL extends Apollo.Mutation<
    RemoveEmailTemplateMutation,
    RemoveEmailTemplateMutationVariables
> {
    document = RemoveEmailTemplateDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SendTestEmailDocument = gql`
    mutation sendTestEmail($id: ID!, $recipientEmailAddress: String!) {
        sendTestEmail(id: $id, recipientEmailAddress: $recipientEmailAddress)
    }
`

@Injectable({
    providedIn: 'root',
})
export class SendTestEmailGQL extends Apollo.Mutation<SendTestEmailMutation, SendTestEmailMutationVariables> {
    document = SendTestEmailDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpsertEmailTemplateDocument = gql`
    mutation upsertEmailTemplate($id: ID, $input: EmailTemplateInput!) {
        upsertEmailTemplate(id: $id, input: $input) {
            id
            name
            emailTemplateLocales {
                id
                locale
                rawTemplateContent
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpsertEmailTemplateGQL extends Apollo.Mutation<
    UpsertEmailTemplateMutation,
    UpsertEmailTemplateMutationVariables
> {
    document = UpsertEmailTemplateDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EventCollectionDocument = gql`
    query EventCollection($filter: EventFilter) {
        EventCollection(filter: $filter) {
            events {
                id
                timestamp
                type
                data {
                    property
                    from
                    to
                }
                user {
                    id
                    profile {
                        id
                        firstName
                        lastNamePrefix
                        lastName
                        emailAddress
                    }
                }
                entityType
                entityId
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class EventCollectionGQL extends Apollo.Query<EventCollectionQuery, EventCollectionQueryVariables> {
    document = EventCollectionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AllResidualValueTablesWithDataDocument = gql`
    query allResidualValueTablesWithData {
        allResidualValueTables {
            ... on ResidualValueTable {
                id
                name
                residualValues {
                    id
                    ageInMonths
                    mileage
                    value
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AllResidualValueTablesWithDataGQL extends Apollo.Query<
    AllResidualValueTablesWithDataQuery,
    AllResidualValueTablesWithDataQueryVariables
> {
    document = AllResidualValueTablesWithDataDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DuplicatePricingConfigurationCalculationDocument = gql`
    mutation duplicatePricingConfigurationCalculation($id: ID!, $name: String!, $tenant: String) {
        duplicatePricingConfigurationCalculation(id: $id, name: $name, tenant: $tenant) {
            ...PricingConfigurationCalculationFragment
        }
    }
    ${PricingConfigurationCalculationFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class DuplicatePricingConfigurationCalculationGQL extends Apollo.Mutation<
    DuplicatePricingConfigurationCalculationMutation,
    DuplicatePricingConfigurationCalculationMutationVariables
> {
    document = DuplicatePricingConfigurationCalculationDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PricingConfigurationCalculationDocument = gql`
    query PricingConfigurationCalculation($id: ID!) {
        PricingConfigurationCalculation(id: $id) {
            ...PricingConfigurationCalculationFragment
        }
    }
    ${PricingConfigurationCalculationFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class PricingConfigurationCalculationGQL extends Apollo.Query<
    PricingConfigurationCalculationQuery,
    PricingConfigurationCalculationQueryVariables
> {
    document = PricingConfigurationCalculationDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PricingConfigurationCalculationCollectionDocument = gql`
    query PricingConfigurationCalculationCollection(
        $filter: PricingConfigurationCalculationFilter
        $pagination: Pagination
        $orderBy: OrderBy
    ) {
        PricingConfigurationCalculationCollection(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            configurationCalculations {
                id
                createdAt
                updatedAt
                name
                description
                components {
                    __typename
                }
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class PricingConfigurationCalculationCollectionGQL extends Apollo.Query<
    PricingConfigurationCalculationCollectionQuery,
    PricingConfigurationCalculationCollectionQueryVariables
> {
    document = PricingConfigurationCalculationCollectionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PricingPreviewDocument = gql`
    query PricingPreview($input: PricingPreviewInput!) {
        PricingPreview(input: $input) {
            leasingPriceExVAT
            leasingPriceIncVAT
            fiscalValue
            components {
                __typename
                ... on PricingCalculationComponentMargin {
                    amountExVAT
                    component {
                        percentage
                    }
                }
                ... on PricingCalculationComponentInterest {
                    amountExVAT
                    component {
                        percentage
                    }
                }
                ... on PricingCalculationComponentInsurance {
                    amountExVAT
                    component {
                        liability
                        insuranceMarkupPercentage
                        insurancePremiumTaxPercentage
                        hull {
                            startingAtFiscalValue
                            endingAtFiscalValue
                            perStepInFiscalValue
                            amountExVATPerStep
                        }
                        deductible {
                            higher
                            regular
                            lower
                        }
                    }
                }
                ... on PricingCalculationComponentDepreciation {
                    amountExVAT
                    component {
                        residualValuePercentage
                    }
                }
                ... on PricingCalculationComponentReplacementTransport {
                    amountExVAT
                    component {
                        amountExVATUntilCutOff
                        cutOffMileage
                        perMileageStepAboveCutOff
                        amountExVATPerStepAboveCutOff
                    }
                }
                ... on PricingCalculationComponentMaintenance {
                    amountExVAT
                    component {
                        amountExVATPerDistanceUnit
                    }
                }
                ... on PricingCalculationComponentCustomResidualValue {
                    amountExVAT
                    component {
                        name
                    }
                }
                ... on PricingCalculationComponentCustomMonthlyLeasePriceAddOn {
                    amountExVAT
                    component {
                        name
                    }
                }
                ... on PricingCalculationComponentTire {
                    amountExVAT
                }
                ... on PricingCalculationComponentAfterDelivery {
                    amountExVAT
                }
                ... on PricingCalculationComponentLeaseEditionOption {
                    amountExVAT
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class PricingPreviewGQL extends Apollo.Query<PricingPreviewQuery, PricingPreviewQueryVariables> {
    document = PricingPreviewDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveConfigurationCalculationDocument = gql`
    mutation removeConfigurationCalculation($id: ID!) {
        removeConfigurationCalculation(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemoveConfigurationCalculationGQL extends Apollo.Mutation<
    RemoveConfigurationCalculationMutation,
    RemoveConfigurationCalculationMutationVariables
> {
    document = RemoveConfigurationCalculationDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpsertPricingConfigurationCalculationDocument = gql`
    mutation upsertPricingConfigurationCalculation($id: ID, $input: PricingConfigurationCalculationInput!) {
        upsertPricingConfigurationCalculation(id: $id, input: $input) {
            ...PricingConfigurationCalculationFragment
        }
    }
    ${PricingConfigurationCalculationFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpsertPricingConfigurationCalculationGQL extends Apollo.Mutation<
    UpsertPricingConfigurationCalculationMutation,
    UpsertPricingConfigurationCalculationMutationVariables
> {
    document = UpsertPricingConfigurationCalculationDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AllUserProfilesDocument = gql`
    query allUserProfiles($filter: UserProfileFilter, $pagination: Pagination, $orderBy: OrderBy) {
        allUserProfiles(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            totalResults
            userProfiles {
                id
                userId
                firstName
                lastNamePrefix
                lastName
                emailAddress
                telephoneNumber
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class AllUserProfilesGQL extends Apollo.Query<AllUserProfilesQuery, AllUserProfilesQueryVariables> {
    document = AllUserProfilesDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ContractsByDriverDocument = gql`
    query contractsByDriver($driverId: String) {
        ContractCollection(
            filter: { driver: { userId: $driverId } }
            orderBy: { field: "updatedAt", asc: false }
            pagination: { limit: 20 }
        ) {
            contracts {
                id
                car {
                    licensePlate
                }
                startDate
                endDate
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class ContractsByDriverGQL extends Apollo.Query<ContractsByDriverQuery, ContractsByDriverQueryVariables> {
    document = ContractsByDriverDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateLoginUrlDocument = gql`
    mutation createLoginUrl($userId: ID!, $path: String!) {
        createLoginUrl(userId: $userId, path: $path) {
            url
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateLoginUrlGQL extends Apollo.Mutation<CreateLoginUrlMutation, CreateLoginUrlMutationVariables> {
    document = CreateLoginUrlDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateUserDocument = gql`
    mutation createUser($emailAddress: String!) {
        createUser(emailAddress: $emailAddress) {
            id
            profile {
                id
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EditCheckResultDocument = gql`
    mutation editCheckResult($userId: ID!) {
        editCheckResult(userId: $userId) {
            result
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class EditCheckResultGQL extends Apollo.Mutation<EditCheckResultMutation, EditCheckResultMutationVariables> {
    document = EditCheckResultDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const IdCheckStatusDocument = gql`
    query IdCheckStatus($userId: ID, $emailAddress: String) {
        User(id: $userId, emailAddress: $emailAddress) {
            id
            idCheckStatus {
                result
                check {
                    id
                    result
                    status
                    checkPartsNotClear
                    reports {
                        id
                        name
                        result
                        status
                    }
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class IdCheckStatusGQL extends Apollo.Query<IdCheckStatusQuery, IdCheckStatusQueryVariables> {
    document = IdCheckStatusDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RecentDealsByOwnerDocument = gql`
    query RecentDealsByOwner($ownerId: String!) {
        DealCollection(
            filter: {
                AND: [
                    { ownerId: $ownerId }
                    {
                        OR: [
                            { status: OFFER }
                            { status: CONTRACT_INCOMPLETE }
                            { status: CONTRACT_COMPLETE }
                            { status: CONTRACT_CANCELED }
                            { status: CONTRACT_ORDERED }
                        ]
                    }
                ]
            }
            orderBy: { field: "updatedAt", asc: false }
            pagination: { limit: 10 }
        ) {
            deals {
                id
                editionName
                modelName
                pricing {
                    pricePerMonthExVAT
                    pricePerMonthIncVAT
                    incVAT
                }
                status
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class RecentDealsByOwnerGQL extends Apollo.Query<RecentDealsByOwnerQuery, RecentDealsByOwnerQueryVariables> {
    document = RecentDealsByOwnerDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SetUserRolesDocument = gql`
    mutation setUserRoles($userId: ID!, $role: [Role]) {
        setUserRoles(id: $userId, role: $role) {
            id
            roles
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class SetUserRolesGQL extends Apollo.Mutation<SetUserRolesMutation, SetUserRolesMutationVariables> {
    document = SetUserRolesDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SignupUserDocument = gql`
    mutation signupUser($emailAddress: String!) {
        signupUser(emailAddress: $emailAddress) {
            userId
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class SignupUserGQL extends Apollo.Mutation<SignupUserMutation, SignupUserMutationVariables> {
    document = SignupUserDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile(
        $id: ID!
        $emailAddress: String
        $firstName: String
        $lastNamePrefix: String
        $lastName: String
        $telephoneNumber: String
        $address: UserAddressInput
    ) {
        updateUserProfile(
            id: $id
            emailAddress: $emailAddress
            firstName: $firstName
            lastNamePrefix: $lastNamePrefix
            lastName: $lastName
            telephoneNumber: $telephoneNumber
            address: $address
        ) {
            id
            userId
            emailAddress
            firstName
            lastNamePrefix
            lastName
            telephoneNumber
            address {
                street
                houseNumber
                houseNumberSuffix
                zipcode
                city
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UpdateUserProfileGQL extends Apollo.Mutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
> {
    document = UpdateUserProfileDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UserDocument = gql`
    query User($userId: ID, $emailAddress: String) {
        User(id: $userId, emailAddress: $emailAddress) {
            id
            emailAddress
            roles
            profile {
                id
                referralCode
                firstName
                lastName
                lastNamePrefix
                telephoneNumber
                emailAddress
                address {
                    id
                    city
                    houseNumber
                    houseNumberSuffix
                    street
                    zipcode
                }
            }
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    document = UserDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UserEmailDocument = gql`
    query UserEmail($userId: ID) {
        User(id: $userId) {
            id
            emailAddress
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UserEmailGQL extends Apollo.Query<UserEmailQuery, UserEmailQueryVariables> {
    document = UserEmailDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UserAvatarDocument = gql`
    query userAvatar($id: ID!) {
        User(id: $id) {
            id
            emailAddress
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class UserAvatarGQL extends Apollo.Query<UserAvatarQuery, UserAvatarQueryVariables> {
    document = UserAvatarDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GetAccountManagersDocument = gql`
    query getAccountManagers {
        getAccountManagers {
            id
            userId
            emailAddress
            firstName
            lastNamePrefix
            lastName
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class GetAccountManagersGQL extends Apollo.Query<GetAccountManagersQuery, GetAccountManagersQueryVariables> {
    document = GetAccountManagersDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AllReceiverEmailOptionsDocument = gql`
    query allReceiverEmailOptions {
        allReceiverEmailOptions
    }
`

@Injectable({
    providedIn: 'root',
})
export class AllReceiverEmailOptionsGQL extends Apollo.Query<
    AllReceiverEmailOptionsQuery,
    AllReceiverEmailOptionsQueryVariables
> {
    document = AllReceiverEmailOptionsDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveWorkflowDocument = gql`
    mutation removeWorkflow($id: ID!) {
        removeWorkflow(id: $id)
    }
`

@Injectable({
    providedIn: 'root',
})
export class RemoveWorkflowGQL extends Apollo.Mutation<RemoveWorkflowMutation, RemoveWorkflowMutationVariables> {
    document = RemoveWorkflowDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpsertWorkflowDocument = gql`
    mutation upsertWorkflow($id: ID, $input: WorkflowInput!) {
        upsertWorkflow(id: $id, input: $input) {
            ...WorkflowFragment
        }
    }
    ${WorkflowFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class UpsertWorkflowGQL extends Apollo.Mutation<UpsertWorkflowMutation, UpsertWorkflowMutationVariables> {
    document = UpsertWorkflowDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const WorkflowDocument = gql`
    query Workflow($id: ID!) {
        Workflow(id: $id) {
            ...WorkflowFragment
        }
    }
    ${WorkflowFragmentFragmentDoc}
`

@Injectable({
    providedIn: 'root',
})
export class WorkflowGQL extends Apollo.Query<WorkflowQuery, WorkflowQueryVariables> {
    document = WorkflowDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const WorkflowCollectionDocument = gql`
    query WorkflowCollection($filter: WorkflowFilterInput, $pagination: Pagination, $orderBy: OrderBy) {
        WorkflowCollection(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            workflows {
                id
                createdAt
                name
                actions {
                    __typename
                    ... on WorkflowActionBranch {
                        acceptActions {
                            __typename
                            ... on WorkflowActionBranch {
                                acceptActions {
                                    __typename
                                }
                                rejectActions {
                                    __typename
                                }
                            }
                        }
                        rejectActions {
                            __typename
                            ... on WorkflowActionBranch {
                                acceptActions {
                                    __typename
                                }
                                rejectActions {
                                    __typename
                                }
                            }
                        }
                    }
                }
                conditions {
                    requirements {
                        filter {
                            field
                        }
                    }
                }
            }
            totalResults
        }
    }
`

@Injectable({
    providedIn: 'root',
})
export class WorkflowCollectionGQL extends Apollo.Query<WorkflowCollectionQuery, WorkflowCollectionQueryVariables> {
    document = WorkflowCollectionDocument

    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableProdMode } from '@angular/core'
import { environment } from './environments/environment'
import { AppModule } from './app/app.module'

if (environment.production) {
    enableProdMode()
} else {
    require('zone.js/dist/long-stack-trace-zone')
}

import { SupportedLanguages } from 'app/localization/supported-languages'
import { registerLocaleData } from '@angular/common'

registerLocaleData(SupportedLanguages.BE.localeConfig, SupportedLanguages.BE.locale)
registerLocaleData(SupportedLanguages.DE.localeConfig, SupportedLanguages.DE.locale)
registerLocaleData(SupportedLanguages.EN.localeConfig, SupportedLanguages.EN.locale)
registerLocaleData(SupportedLanguages.FR.localeConfig, SupportedLanguages.FR.locale)
registerLocaleData(SupportedLanguages.NL.localeConfig, SupportedLanguages.NL.locale)
registerLocaleData(SupportedLanguages.DK.localeConfig, SupportedLanguages.DK.locale)

const bootstrap = () => {
    return platformBrowserDynamic().bootstrapModule(AppModule)
}

document.addEventListener('DOMContentLoaded', bootstrap)

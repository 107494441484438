import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldBasicComponent } from './form-factory-field-basic.component'
import { FORM_FACTORY_FIELD_DEFAULT } from '../../core/component/form-field-factory/form-field-factory.tokens'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
    declarations: [FormFactoryFieldBasicComponent],
    imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD_DEFAULT,
            useValue: FormFactoryFieldBasicComponent,
        },
    ],
})
export class FormFactoryFieldBasicModule {}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldAccountmanagerUserIdComponent } from './form-factory-field-accountmanager-user-id.component'
import { AccountmanagerSelectorModule } from '../../ui/accountmanager-selector/accountmanager-selector.module'
import { FORM_FACTORY_FIELD } from '../../../../ui/form-factory/core/component/form-field-factory/form-field-factory.tokens'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'

@NgModule({
    declarations: [FormFactoryFieldAccountmanagerUserIdComponent],
    imports: [CommonModule, AccountmanagerSelectorModule, ReactiveFormsModule, MatFormFieldModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD,
            useValue: {
                name: 'accountmanagerUserId',
                component: FormFactoryFieldAccountmanagerUserIdComponent,
            },
            multi: true,
        },
    ],
})
export class FormFactoryFieldAccountmanagerUserIdModule {}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldAutocompleteComponent } from './form-factory-field-autocomplete.component'
import { FORM_FACTORY_FIELD } from '../../core/component/form-field-factory/form-field-factory.tokens'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'

@NgModule({
    declarations: [FormFactoryFieldAutocompleteComponent],
    imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatAutocompleteModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD,
            useValue: {
                name: 'autocomplete',
                component: FormFactoryFieldAutocompleteComponent,
            },
            multi: true,
        },
    ],
})
export class FormFactoryFieldAutocompleteModule {}

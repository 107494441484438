import { ErrorHandler, NgModule, LOCALE_ID } from '@angular/core'
import { UserModule } from './modules/user/user.module'
import { ConfigurationModule } from './modules/configuration/configuration.module'
import { LayoutComponent } from './framework/layout/containers/layout/layout.component'
import { LayoutModule } from './framework/layout/containers/layout/layout.module'
import { FrameworkModule } from './framework/framework.module'
import { Route, RouterModule } from '@angular/router'
import { DashboardModule } from './modules/dashboard/dashboard.module'
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './framework/rollbar/rollbar.service'
import { loadDefaulLocale } from './localization/supported-languages'

const routes: Route[] = [
    {
        path: 'login',
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: '',
        loadChildren: () => import('app/modules/dashboard/app/app.module').then(m => m.DashboardAppModule),
    },
    {
        path: 'car',
        loadChildren: () => import('app/modules/car/app/car-app.module').then(m => m.CarAppModule),
    },
    {
        path: 'configuration',
        loadChildren: () => import('app/modules/configuration/app/app.module').then(m => m.ConfigurationAppModule),
    },
    {
        path: 'contracts',
        loadChildren: () => import('app/modules/contract/app/contract.module').then(m => m.ContractModule),
    },
    {
        path: 'accepted-offers',
        loadChildren: () => import('app/modules/deal/app/contract/contract-app.module').then(m => m.ContractAppModule),
    },
    {
        path: 'offer',
        loadChildren: () => import('app/modules/deal/app/offer/offer-app.module').then(m => m.OfferAppModule),
    },
    {
        path: 'user',
        loadChildren: () => import('app/modules/user/app/user-app.module').then(m => m.UserAppModule),
    },
    {
        path: 'legal',
        loadChildren: () => import('app/modules/legal/legal.module').then(m => m.LegalModule),
    },
    {
        path: 'workflow',
        loadChildren: () => import('app/modules/workflows/app/workflows-app.module').then(m => m.WorkflowsAppModule),
    },
    {
        path: 'email-template',
        loadChildren: () =>
            import('app/modules/email-templates/app/email-templates-app.module').then(m => m.EmailTemplatesAppModule),
    },
    {
        path: 'pricing',
        loadChildren: () => import('app/modules/pricing/app/pricing-app.module').then(m => m.PricingAppModule),
    },
    {
        path: 'settings',
        children: [
            {
                path: 'configuration',
                loadChildren: () =>
                    import('app/modules/configuration/settings/settings.module').then(
                        m => m.ConfigurationSettingsModule
                    ),
            },
            {
                path: '**',
                redirectTo: 'configuration',
            },
        ],
    },
    {
        path: 'order',
        redirectTo: 'accepted-offers',
    },
]

@NgModule({
    imports: [
        FrameworkModule,

        // routes used in admin
        RouterModule.forRoot(routes),

        // modules used in admin
        ConfigurationModule,
        DashboardModule,
        UserModule,
        LayoutModule,
    ],
    bootstrap: [LayoutComponent],
    providers: [
        {
            provide: ErrorHandler,
            useClass: RollbarErrorHandler,
        },
        {
            provide: RollbarService,
            useFactory: rollbarFactory,
        },
        { provide: LOCALE_ID, useValue: loadDefaulLocale() },
    ],
})
export class AppModule {}

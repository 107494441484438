import { APP_INITIALIZER, NgModule } from '@angular/core'
import { ApiGraphqlService } from './services/api.graphql.service'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
    declarations: [],
    imports: [HttpClientModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (apiGraphQlService: ApiGraphqlService) => async () => {
                return await apiGraphQlService.init()
            },
            deps: [ApiGraphqlService],
            multi: true,
        },
    ],
})
export class GraphqlModule {}

import { EventEmitter, Injectable, Output } from '@angular/core'
import { Notification } from '../type/notification.type'

const defaultNotification: Notification = {
    message: '',
    closeButton: true,
    autoCloseTimeout: 3000,
    type: 'info',
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    @Output()
    notification: EventEmitter<Notification> = new EventEmitter()

    constructor() {}

    showNotification(notification: Partial<Notification>) {
        this.notification.emit({
            ...defaultNotification,
            ...notification,
        })
    }
}

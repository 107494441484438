import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldFileComponent } from './form-factory-field-file.component'
import { FORM_FACTORY_FIELD } from '../../core/component/form-field-factory/form-field-factory.tokens'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
    declarations: [FormFactoryFieldFileComponent],
    imports: [CommonModule, ReactiveFormsModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD,
            useValue: {
                name: 'file',
                component: FormFactoryFieldFileComponent,
            },
            multi: true,
        },
    ],
})
export class FormFactoryFieldFileModule {}

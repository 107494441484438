import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldNumberComponent } from './form-factory-field-number.component'
import { FORM_FACTORY_FIELD } from '../../core/component/form-field-factory/form-field-factory.tokens'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
    declarations: [FormFactoryFieldNumberComponent],
    imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD,
            useValue: {
                name: 'number',
                component: FormFactoryFieldNumberComponent,
            },
            multi: true,
        },
    ],
})
export class FormFactoryFieldNumberModule {}

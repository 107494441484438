export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[]
    }
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        CarResult: ['Car', 'EntityNotFoundError'],
        CarShopPublicationResult: ['CarShopPublication', 'EntityNotFoundError', 'RimsOptionNeededError'],
        ConfigurationBrandResult: ['ConfigurationBrand', 'EntityNotFoundError'],
        ConfigurationCalculationResult: ['ConfigurationCalculation', 'EntityNotFoundError'],
        ConfigurationEditionOptionResult: [
            'EditionOption',
            'AfterDeliveryEditionOption',
            'LeaseEditionOption',
            'WheelEditionOption',
            'EntityNotFoundError',
        ],
        ConfigurationEditionResult: ['ConfigurationEdition', 'EntityNotFoundError'],
        ConfigurationModelResult: ['ConfigurationModel', 'EntityNotFoundError'],
        ConfigurationOptionResult: ['ConfigurationOption', 'EntityNotFoundError'],
        ContractLessee: ['ContractCompany', 'ContractPerson'],
        DealOption: ['BooleanDealOption', 'SelectableDealOption'],
        DocumentMetadata: ['PdfMetadata', 'ImageMetadata', 'MiscellaneousMetadata'],
        Driver: ['ContractDriver', 'DealDriver'],
        EditionOptionInterface: [
            'AfterDeliveryEditionOption',
            'EditionOption',
            'LeaseEditionOption',
            'WheelEditionOption',
        ],
        EventEntity: ['Contract', 'Car', 'EntityNotFoundError'],
        ExpectedError: [
            'CarHasContractAttachedError',
            'EntityNotFoundError',
            'InvalidInputDataError',
            'RimsOptionNeededError',
        ],
        Lessee: ['LesseeCompany', 'LesseePerson'],
        PaginatedResult: [
            'AllUserProfilesResult',
            'CarCollection',
            'ConfigurationCalculationCollection',
            'ContractCollection',
            'DealCollection',
            'DocumentCollection',
            'EmailTemplateCollection',
            'EventCollection',
            'RemarketingPublicationCollection',
            'WorkflowCollection',
        ],
        PricingCalculationComponent: [
            'PricingCalculationComponentMargin',
            'PricingCalculationComponentInterest',
            'PricingCalculationComponentInsurance',
            'PricingCalculationComponentDepreciation',
            'PricingCalculationComponentMaintenance',
            'PricingCalculationComponentReplacementTransport',
            'PricingCalculationComponentCustomMonthlyLeasePriceAddOn',
            'PricingCalculationComponentCustomResidualValue',
            'PricingCalculationComponentTire',
            'PricingCalculationComponentAfterDelivery',
            'PricingCalculationComponentLeaseEditionOption',
            'PricingCalculationComponentDownpayment',
            'PricingCalculationReferralDiscount',
        ],
        PricingConditionType: [
            'PricingConditionLeasingType',
            'PricingConditionFiscalValue',
            'PricingConditionCarOption',
        ],
        PricingConfigurationComponent: [
            'PricingConfigurationComponentMargin',
            'PricingConfigurationComponentInterest',
            'PricingConfigurationComponentInsurance',
            'PricingConfigurationComponentDepreciation',
            'PricingConfigurationComponentMaintenance',
            'PricingConfigurationComponentReplacementTransport',
            'PricingConfigurationComponentCustomMonthlyLeasePriceAddOn',
            'PricingConfigurationComponentCustomResidualValue',
            'PricingConfigurationComponentConditional',
        ],
        RemarketingChannel: ['CarShopChannel'],
        RemarketingPublication: ['CarShopPublication'],
        RemoveCarResult: ['CarSuccessfullyRemoved', 'EntityNotFoundError', 'CarHasContractAttachedError'],
        ResidualValueTableResult: ['ResidualValueTable', 'EntityNotFoundError'],
        Signature: ['DocumentSignature', 'IdealSignature'],
        UnpublishRemarketingPublicationResult: ['PublicationSuccessfullyUnpublished', 'EntityNotFoundError'],
        UpsertCarResult: ['Car', 'EntityNotFoundError', 'InvalidInputDataError'],
        UpsertContractResult: ['Contract', 'EntityNotFoundError', 'InvalidInputDataError'],
        WorkflowAction: ['WorkflowActionBranch', 'WorkflowActionEmail', 'WorkflowActionSlack'],
    },
}
export default result

<ng-container [ngSwitch]="notification.type">
    <mga-error *ngSwitchCase="'error'">
        {{ notification.message }}
    </mga-error>
    <mga-info *ngSwitchCase="'info'">
        {{ notification.message }}
    </mga-info>
    <mga-message *ngSwitchCase="'message'">
        {{ notification.message }}
    </mga-message>
    <mga-success *ngSwitchCase="'success'">
        {{ notification.message }}
    </mga-success>
    <mga-warning *ngSwitchCase="'warning'">
        {{ notification.message }}
    </mga-warning>
</ng-container>

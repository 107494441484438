import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core'
import { Notification } from '../../type/notification.type'
import Timeout = NodeJS.Timeout

@Component({
    selector: 'mga-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    /* eslint-disable-next-line @angular-eslint/no-host-metadata-property */
    host: {
        '[class.--isError]': 'notification?.type === "error"',
    },
})
export class NotificationComponent implements OnInit, AfterContentInit {
    @Output()
    terminated = new EventEmitter()

    @HostBinding('class.--isTerminated')
    isTerminated: boolean = false

    @HostBinding('class.--isLoaded')
    isLoaded: boolean = false

    @Input()
    notification: Notification
    private terminateTimeout: Timeout

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        if (this.notification.autoCloseTimeout) {
            this.terminateTimeout = setTimeout(() => this.terminate(), this.notification.autoCloseTimeout)
        }
    }

    async terminate() {
        clearTimeout(this.terminateTimeout)

        this.isTerminated = true

        // emit terminated event after animation is done
        setTimeout(() => {
            this.terminated.emit()
        }, 550)
    }

    async ngAfterContentInit() {
        await this.fixHeight()
        setTimeout(() => {
            this.isLoaded = true
        }, 1)
    }

    @HostListener('window:resize')
    async fixHeight() {
        const navitiveElement = this.elementRef.nativeElement
        navitiveElement.style.height = 'auto'

        setTimeout(() => {
            navitiveElement.style.height = navitiveElement.getClientRects()[0].height + 'px'
        }, 1)
    }
}

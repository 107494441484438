import { Component } from '@angular/core'
import { SplashScreenService } from '../../../splash-screen/splash-screen.service'
import { NavigationEnd, Router } from '@angular/router'
import { debounceTime, filter, take } from 'rxjs/operators'

@Component({
    selector: 'mga-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
    constructor(splashScreenService: SplashScreenService, router: Router) {
        // Hide splashScrheen on the first NavigationEnd event
        router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1),
                debounceTime(1)
            )
            .subscribe(() => splashScreenService.hide())
    }
}

import { APP_INITIALIZER, NgModule } from '@angular/core'
import { AuthenticatedUserService } from './services/authenticated-user.service'
import { LocalStorageService } from '@mistergreen/shared/angular-injectables/local-storage/local-storage.service'

function getQueryStringValue(key) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                '^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
                'i'
            ),
            '$1'
        )
    )
}

const queryStringToken = getQueryStringValue('access_token')
if (queryStringToken) {
    window.localStorage.setItem('token', queryStringToken)
}

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory:
                (localStorageService: LocalStorageService, authenticatedUserService: AuthenticatedUserService) =>
                async () => {
                    const token = localStorageService.getItem('token')
                    const canActivate: boolean = !!token

                    if (!canActivate) {
                        authenticatedUserService.authenticate()
                        return false
                    }

                    await authenticatedUserService.init()

                    if (!authenticatedUserService.isLoggedIn) {
                        authenticatedUserService.logout()
                        return false
                    }

                    return canActivate
                },
            deps: [LocalStorageService, AuthenticatedUserService],
            multi: true,
        },
    ],
})
export class AuthenticationModule {}

import { NgModule } from '@angular/core'
import { FormFactoryFieldBasicModule } from './fields/form-factory-field-basic/form-factory-field-basic.module'
import { FormFactoryCoreModule } from './core/form-factory.module'
import { FormFactoryFieldNumberModule } from './fields/form-factory-field-number/form-factory-field-number.module'
import { FormFactoryFieldAutocompleteModule } from './fields/form-factory-field-autocomplete/form-factory-field-autocomplete.module'
import { FormFactoryFieldDatepickerModule } from './fields/form-factory-field-datepicker/form-factory-field-datepicker.module'
import { FormFactoryFieldCheckboxModule } from './fields/form-factory-field-checkbox/form-factory-field-checkbox.module'
import { FormFactoryFieldSelectModule } from './fields/form-factory-field-select/form-factory-field-select.module'
import { FormFactoryFieldFileModule } from './fields/form-factory-field-file/form-factory-field-file.module'
import { FormFactoryFieldAccountmanagerUserIdModule } from 'app/modules/user/form-fields/form-factory-field-accountmanager-user-id/form-factory-field-accountmanager-user-id.module'

@NgModule({
    imports: [
        FormFactoryCoreModule,
        FormFactoryFieldDatepickerModule,
        FormFactoryFieldAutocompleteModule,
        FormFactoryFieldNumberModule,
        FormFactoryFieldSelectModule,
        FormFactoryFieldBasicModule,
        FormFactoryFieldCheckboxModule,
        FormFactoryFieldFileModule,
        FormFactoryFieldAccountmanagerUserIdModule,
    ],
    exports: [FormFactoryCoreModule],
})
export class FormFactoryModule {}

import { Component } from '@angular/core'
import { NotificationService } from '../../service/notification.service'
import { Notification } from '../../type/notification.type'

@Component({
    selector: 'mga-notification-layout',
    templateUrl: './notification-layout.component.html',
    styleUrls: ['./notification-layout.component.scss'],
})
export class NotificationLayoutComponent {
    notifications: Notification[] = []

    constructor(notificationService: NotificationService) {
        notificationService.notification.subscribe(notification => this.notifications.unshift(notification))
    }

    onTerminate(notification: Notification) {
        const notificationIndex = this.notifications.indexOf(notification)

        if (notificationIndex !== -1) {
            this.notifications.splice(notificationIndex, 1)
        }
    }
}

<div *ngIf="!componentFormControl.value && authenticatedUserIsAccountManager$ | async" class="selectAuthenticatedUser">
    <button
        *ngIf="authenticatedUserId$ | async as authenticatedUserId"
        class="selectAuthenticatedUser__button"
        (click)="selectAuthenticatedUser(authenticatedUserId)"
    >
        {{ useAuthenticatedLabel }}
    </button>
    <span class="selectAuthenticatedUser__or"> or&nbsp;</span>
</div>

<mat-select [formControl]="componentFormControl" placeholder="select user">
    <mat-select-trigger>
        <div class="option" *ngIf="componentFormControl.value && userProfile$ | async as userProfile">
            <mga-user-avatar class="option__avatar" [emailAddress]="userProfile.emailAddress"></mga-user-avatar>
            <div class="option__fullName">
                {{ userProfile.firstName }} {{ userProfile.lastNamePrefix }} {{ userProfile.lastName }}
            </div>
            <div class="option__emailAddress">{{ userProfile.emailAddress }}</div>
        </div>
    </mat-select-trigger>
    <mat-option
        *ngIf="this.allowClear && componentFormControl.value && userProfile$ | async as userProfile"
        [value]="undefined"
    >
        <mat-icon>clear</mat-icon> clear
    </mat-option>
    <mat-option
        *ngFor="let userProfile of userProfiles$ | async"
        [value]="userProfile.userId"
        [class.isAuthenticatedUser]="userProfile.userId === (authenticatedUserId$ | async)"
    >
        <div class="option">
            <mga-user-avatar class="option__avatar" [emailAddress]="userProfile.emailAddress"></mga-user-avatar>
            <div class="option__fullName">
                {{ userProfile.firstName }} {{ userProfile.lastNamePrefix }} {{ userProfile.lastName }}
            </div>
            <div class="option__emailAddress">{{ userProfile.emailAddress }}</div>
        </div>
    </mat-option>
</mat-select>

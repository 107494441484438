import { Component, HostBinding, OnInit } from '@angular/core'
import { AuthenticatedUserService, User } from '../../../authentication/services/authenticated-user.service'
import { TenantService } from '../../../tenant/services/tenant.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { Tenant } from '../../../../../generated/graphql'
import { FormControl } from '@angular/forms'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'mga-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    user$: BehaviorSubject<User | null>
    tenants$: Observable<Tenant[]>
    tenantControl: FormControl

    @HostBinding('class._is_open')
    menuOpen: boolean = false

    constructor(private authenticatedUserService: AuthenticatedUserService, private tenantService: TenantService) {
        this.user$ = this.authenticatedUserService.currentUser
        this.tenants$ = this.tenantService.getTenants()
    }

    onLogout(event) {
        event.preventDefault()
        this.authenticatedUserService.logout()
    }

    ngOnInit(): void {
        this.tenantControl = new FormControl(this.tenantService.tenant)
        this.tenantControl.valueChanges
            .pipe(
                untilDestroyed(this),
                filter(tenant => this.tenantService.tenant !== tenant)
            )
            .subscribe(tenant => (this.tenantService.tenant = tenant))

        this.tenantService.tenant$
            .pipe(
                untilDestroyed(this),
                filter(tenant => this.tenantControl.value !== tenant)
            )
            .subscribe(tenant => this.tenantControl.setValue(tenant))
    }
}

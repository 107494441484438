import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core'
import { FormControlValueAccessor } from '@mistergreen/shared/angular-directives/abstract-value-accessor'
import { GetAccountManagersGQL, GetAccountManagersQuery, User, UserProfile } from '../../../../../generated/graphql'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { distinctUntilChanged, filter, map, pluck, startWith, tap } from 'rxjs/operators'
import { ApolloQueryResult } from '@apollo/client'
import { AuthenticatedUserService } from '../../../../framework/authentication/services/authenticated-user.service'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

@UntilDestroy()
@Component({
    selector: 'mga-accountmanager-selector',
    templateUrl: './accountmanager-selector.component.html',
    styleUrls: ['./accountmanager-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccountmanagerSelectorComponent),
            multi: true,
        },
    ],
})
export class AccountmanagerSelectorComponent extends FormControlValueAccessor implements OnInit {
    userProfiles$: Observable<UserProfile[]>
    userProfile$: Observable<UserProfile>

    value$: BehaviorSubject<string> = new BehaviorSubject<string>(this.value)

    @HostBinding('class.isLoaded')
    isLoaded: boolean = false

    @Input()
    useAuthenticatedLabel: string = 'use your account'

    @Input()
    allowClear: boolean = false
    authenticatedUserIsAccountManager$: Observable<boolean>
    authenticatedUserId$: Observable<string>

    constructor(
        private getAccountManagersGQL: GetAccountManagersGQL,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super()
    }

    get _value() {
        return this.componentFormControl ? this.componentFormControl.value : this.__value
    }

    set _value(value) {
        this.__value = value

        if (this.componentFormControl) {
            this.componentFormControl.setValue(value, { emitEvent: false })
        }

        if (this.value$ && this.value$.getValue() !== value) {
            this.value$.next(value)
        }
    }

    writeValue(value: any) {
        super.writeValue(value)
        if (this.value$ && this.value$.getValue() !== value) {
            this.value$.next(value)
        }
    }

    ngOnInit(): void {
        this.authenticatedUserId$ = this.authenticatedUserService.currentUser.pipe(
            filter(Boolean),
            pluck<User, string>('id')
        )

        this.userProfiles$ = combineLatest([
            this.authenticatedUserId$,
            this.getAccountManagersGQL
                .fetch()
                .pipe(
                    pluck<ApolloQueryResult<GetAccountManagersQuery>, UserProfile[]>('data', 'getAccountManagers'),
                    untilDestroyed(this)
                ),
        ]).pipe(
            map<[string, UserProfile[]], UserProfile[]>(([authenticatedUserId, userProfiles]) =>
                [...userProfiles].sort((userProfileA, userProfileB) => {
                    // make sure authenticated is always on top of list
                    if (userProfileA.userId === authenticatedUserId) {
                        return -1
                    }
                    if (userProfileB.userId === authenticatedUserId) {
                        return 1
                    }

                    // other users get sorted alphabetically by fullname
                    return `${userProfileA.firstName} ${userProfileA.lastNamePrefix} ${userProfileA.lastName}`.localeCompare(
                        `${userProfileB.firstName} ${userProfileB.lastNamePrefix} ${userProfileB.lastName}`
                    )
                })
            ),
            tap(() => (this.isLoaded = true))
        )

        this.authenticatedUserIsAccountManager$ = combineLatest([this.authenticatedUserId$, this.userProfiles$]).pipe(
            map(([authenticatedUserId, userProfiles]) => {
                return userProfiles.map(({ userId }) => userId).includes(authenticatedUserId)
            })
        )

        this.componentFormControl.valueChanges
            .pipe(
                startWith([this.componentFormControl.value]),
                filter(value => this.value$.getValue() !== value),
                untilDestroyed(this)
            )
            .subscribe(value => this.value$.next(value))

        this.userProfile$ = combineLatest([this.userProfiles$, this.value$.pipe(distinctUntilChanged())]).pipe(
            untilDestroyed(this),
            map(([userProfiles, selectedUserId]) => {
                if (!selectedUserId) {
                    return null
                }

                return userProfiles.find(({ userId }) => userId === selectedUserId)
            })
        )
    }

    async selectAuthenticatedUser(authenticatedUserId) {
        this.componentFormControl.setValue(authenticatedUserId)
    }
}

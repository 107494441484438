import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AccountmanagerSelectorComponent } from './accountmanager-selector.component'
import { MatSelectModule } from '@angular/material/select'
import { UserAvatarModule } from '../user-avatar/user-avatar.module'
import { ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
    declarations: [AccountmanagerSelectorComponent],
    imports: [CommonModule, MatSelectModule, UserAvatarModule, ReactiveFormsModule, MatIconModule],
    exports: [AccountmanagerSelectorComponent],
})
export class AccountmanagerSelectorModule {}

import localeDK from '@angular/common/locales/da'
import localeDE from '@angular/common/locales/de'
import localeEN from '@angular/common/locales/de'
import localeFR from '@angular/common/locales/fr'
import localeNL from '@angular/common/locales/nl'
import localeBE from '@angular/common/locales/be'

export const SupportedLanguages = {
    BE: {
        code: 'BE',
        locale: 'nl-BE',
        localeConfig: localeBE,
    },
    DK: {
        code: 'DK',
        locale: 'dk-DK',
        localeConfig: localeDK,
    },
    DE: {
        code: 'DE',
        locale: 'de-DE',
        localeConfig: localeDE,
    },
    EN: {
        code: 'EN',
        locale: 'en-EN',
        localeConfig: localeEN,
    },
    FR: {
        code: 'FR',
        locale: 'fr-FR',
        localeConfig: localeFR,
    },
    NL: {
        code: 'NL',
        locale: 'nl-NL',
        localeConfig: localeNL,
    },
}

export function loadLanguageCode(language: string): string {
    return SupportedLanguages[language]?.code
}

export function loadDefaulLocale() {
    if (localStorage.getItem('mg_tenant') === null) {
        return SupportedLanguages.NL.locale
    }

    const tenant = localStorage.getItem('mg_tenant').replace('mistergreen_', '')

    return SupportedLanguages[tenant.toUpperCase()].locale
}

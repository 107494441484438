import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryComponent } from './component/form-factory/form-factory.component'
import { FormFieldFactoryComponent } from './component/form-field-factory/form-field-factory.component'
import { FormGroupModule } from '../../form-group/form-group.module'
import { FormGroupFactoryComponent } from './component/form-group-factory/form-group-factory.component'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { FormFactoryFieldBasicModule } from '../fields/form-factory-field-basic/form-factory-field-basic.module'
import { PortalModule } from '@angular/cdk/portal'

@NgModule({
    declarations: [FormFactoryComponent, FormFieldFactoryComponent, FormGroupFactoryComponent],
    exports: [FormFactoryComponent],
    imports: [CommonModule, FormGroupModule, ReactiveFormsModule, MatButtonModule, PortalModule],
})
export class FormFactoryCoreModule {}

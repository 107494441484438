import { NgModule } from '@angular/core'
import { LayoutComponent } from './layout.component'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ToolbarModule } from '../../components/toolbar/toolbar.module'
import { NotificationModule } from '../../../notification/notification.module'

@NgModule({
    imports: [CommonModule, RouterModule, ToolbarModule, NotificationModule],
    declarations: [LayoutComponent],
    exports: [LayoutComponent],
})
export class LayoutModule {}

import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FormFieldFactoryComponentAbstract } from '../../core/component/form-field-factory/form-field-factory.component-abstract'

let idCounter = 0

@Component({
    selector: 'mga-form-factory-field-file',
    templateUrl: './form-factory-field-file.component.html',
    styleUrls: ['./form-factory-field-file.component.scss'],
})
export class FormFactoryFieldFileComponent extends FormFieldFactoryComponentAbstract implements OnInit {
    idCount: number

    ngOnInit() {
        this.idCount = idCounter
        idCounter++
    }

    onFileChange(event) {
        /*
      Once a file is inputted, we convert it to Base64 (the type expected by 
      our API) using the readAsDataURL method of the FileReader web API:
      https://developer.mozilla.org/en-US/docs/Web/API/FileReader
    */

        if (event.target.files.length) {
            const reader = new FileReader()
            const [file]: [File] = event.target.files
            reader.readAsDataURL(file)

            reader.onload = () => {
                ;(this.formFactoryField.formControl as FormControl).setValue(reader.result)
            }
        }
    }
}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToolbarComponent } from './toolbar.component'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatMenuModule } from '@angular/material/menu'
import { RouterModule } from '@angular/router'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { GravatarModule } from 'ngx-gravatar'
import { MatDividerModule } from '@angular/material/divider'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatMenuModule,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        GravatarModule,
        MatDividerModule,
        MatSelectModule,
        MatTooltipModule,
    ],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}

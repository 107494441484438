import { InjectionToken } from '@angular/core'
import { ComponentType } from '@angular/cdk/portal'
import { FormFieldFactoryComponentAbstract } from './form-field-factory.component-abstract'

export type FormFieldFactoryComponentType = ComponentType<FormFieldFactoryComponentAbstract>

export type FormFactoryFieldDefinition = {
    name: string
    component: FormFieldFactoryComponentType
}

export const FORM_FACTORY_FIELD: InjectionToken<FormFactoryFieldDefinition> =
    new InjectionToken<FormFactoryFieldDefinition>('FORM_FACTORY_FIELD')

export const FORM_FACTORY_FIELD_DEFAULT: InjectionToken<FormFieldFactoryComponentType> =
    new InjectionToken<FormFieldFactoryComponentType>('FORM_FACTORY_FIELD_DEFAULT')

import { Inject, Injectable } from '@angular/core'
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations'
import { DOCUMENT } from '@angular/common'

@Injectable({
    providedIn: 'root',
})
export class SplashScreenService {
    public splashScreenEl: any
    player: AnimationPlayer

    constructor(@Inject(DOCUMENT) private _document: any, private _animationBuilder: AnimationBuilder) {
        this.initialize()
    }

    show(): void {
        if (!this.splashScreenEl) {
            return
        }
        this.player = this._animationBuilder
            .build([
                style({
                    opacity: '0',
                    zIndex: '99999',
                }),
                animate('100ms ease', style({ opacity: '1' })),
            ])
            .create(this.splashScreenEl)

        setTimeout(() => {
            this.player.play()
        }, 0)
    }

    hide(): void {
        if (!this.splashScreenEl) {
            return
        }
        this.player = this._animationBuilder
            .build([
                style({ opacity: '1' }),
                animate(
                    '400ms ease',
                    style({
                        opacity: '0',
                        zIndex: '-10',
                    })
                ),
            ])
            .create(this.splashScreenEl)

        setTimeout(() => {
            this.player.play()
        }, 0)
    }

    private initialize(): void {
        // Get the splash screen element
        this.splashScreenEl = this._document.body.querySelector('#splashScreen')
    }
}

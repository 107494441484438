import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NotificationComponent } from './component/notification/notification.component'
import { NotificationLayoutComponent } from './component/notification-layout/notification-layout.component'
import { MessageModule } from '../../ui/message/message.module'

@NgModule({
    declarations: [NotificationComponent, NotificationLayoutComponent],
    exports: [NotificationLayoutComponent],
    imports: [CommonModule, MessageModule],
})
export class NotificationModule {}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserAvatarComponent } from './user-avatar.component'
import { GravatarModule } from 'ngx-gravatar'

@NgModule({
    declarations: [UserAvatarComponent],
    exports: [UserAvatarComponent],
    imports: [CommonModule, GravatarModule],
})
export class UserAvatarModule {}

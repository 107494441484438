import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldCheckboxComponent } from './form-factory-field-checkbox.component'
import { FORM_FACTORY_FIELD } from '../../core/component/form-field-factory/form-field-factory.tokens'
import { ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'

@NgModule({
    declarations: [FormFactoryFieldCheckboxComponent],
    imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD,
            useValue: {
                name: 'checkbox',
                component: FormFactoryFieldCheckboxComponent,
            },
            multi: true,
        },
    ],
})
export class FormFactoryFieldCheckboxModule {}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFactoryFieldSelectComponent } from './form-factory-field-select.component'
import { FORM_FACTORY_FIELD } from '../../core/component/form-field-factory/form-field-factory.tokens'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
    declarations: [FormFactoryFieldSelectComponent],
    imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule],
    providers: [
        {
            provide: FORM_FACTORY_FIELD,
            useValue: {
                name: 'select',
                component: FormFactoryFieldSelectComponent,
            },
            multi: true,
        },
    ],
})
export class FormFactoryFieldSelectModule {}

import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { Apollo } from 'apollo-angular'
import { LocalStorageService } from '@mistergreen/shared/angular-injectables/local-storage/local-storage.service'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { HttpBatchLink, HttpLink } from 'apollo-angular/http'
import { ApolloLink } from '@apollo/client/core'
import { HttpHeaders } from '@angular/common/http'
import introspectionQueryResultData from '../../../../generated/introspection'
import { TenantService } from '../../tenant/services/tenant.service'
import { filter } from 'rxjs/operators'

/*
  List of operation names of GraphQL queries which,
  for performance reasons, shouldn't be batched.
*/
const nonBatchableOperations = ['IdCheckStatus', 'generateCoverPage', 'CreditReport']

@Injectable({
    providedIn: 'root',
})
export class ApiGraphqlService {
    private tenant: string

    constructor(
        private apollo: Apollo,
        private httpLink: HttpLink,
        private httpBatchLink: HttpBatchLink,
        private localStorageService: LocalStorageService,
        private tenantService: TenantService
    ) {}

    private _token: string | null = null

    /**
     *
     * @returns {string|null}
     */
    get token(): string | null {
        return this._token
    }

    /**
     *
     * @param {string} value
     */
    set token(value: string | null) {
        this._token = value

        // remove cached entries
        this.apollo.client.cache.reset()
    }

    async init() {
        await this.createApolloClient()

        // get token and future changes to it (even in different window)
        this.localStorageService.getItemObservable('token').subscribe(token => (this.token = token))

        this.tenantService.tenant$.pipe(filter<string>(Boolean)).subscribe(tenant => (this.tenant = tenant))
    }

    /**
     *
     */
    async createApolloClient() {
        // provide authentication header
        const authLink = this.createAuthLink()

        // catch errors
        const errorLink = this.createErrorLink()

        // create http link which batches requests
        const uri = `${environment.api.url}${environment.api.path.graphql}`
        const httpLink = ApolloLink.split(
            operation => nonBatchableOperations.includes(operation.operationName),
            this.httpLink.create({ uri }),
            this.httpBatchLink.create({ uri })
        )

        // create link for the apollo client
        const link = ApolloLink.from([authLink, errorLink, httpLink])

        // create in memory cache from used schema
        const cache = new InMemoryCache({
            possibleTypes: introspectionQueryResultData.possibleTypes,
        })

        // create default apollo client
        this.apollo.create({
            link,
            cache,
            name: 'admin',
            version: '1.0',
        })
    }

    private createErrorLink() {
        return onError(({ graphQLErrors }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message }) => {
                    // get rid of token if user is not authenticated
                    if (message.indexOf('User is not authenticated') >= 0 && this.token) {
                        this.localStorageService.setItem('token', null)
                    }
                })
            }
        })
    }

    private createAuthLink() {
        return new ApolloLink((operation, forward) => {
            if (this.tenant || this.token) {
                // add the authorization & tenant to the headers
                let headers = new HttpHeaders()

                if (this.tenant) {
                    headers = headers.set('tenant', this.tenant)
                }

                if (this.token) {
                    headers = headers.set('Authorization', `Bearer ${this.token}`)
                }

                operation.setContext({ headers })
            }

            return forward(operation)
        })
    }
}
